import { Component } from "react";
import DashboardGrid from "../Components/Dashboard/Grid";
import { IGetSQLDataParams, simpleObject } from "../helpers/interfaces";
import {
  IColumnSetting,
  IColumnValue,
  IFilterSetting,
} from "../Components/Dashboard/interfaces";
import OpenCardLink from "../Components/Common/Buttons/OpenCardLink";
import { Chip } from "@progress/kendo-react-buttons";
import CardManagement from "../Components/Cards/CardManagement";
import { getSQLData, ReferenceRecordsDataSource } from "../helpers/queries";
import { ModalRef } from "../Components/Common/Modal/Modal";
import moment from "moment";

type action = "C" | "E" | "D";
const filters: Array<IFilterSetting> = [
  {
    id: "filterPeriod",
    type: "dateRange",
    serverFilter: {
      param: "",
    },
    dateRangeSettings: {
      dateFromParam: "dateFrom",
      dateToParam: "dateTo",
      defaultPeriod: "curr-month-to-date",
    },
    toolbarRow: 1,
  },
  {
    id: "filterEmployee",
    placeholder: "Filter by Employee",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "employeeId",
      getData: async () => {
        let result = await ReferenceRecordsDataSource("Employees");
        return result[0];
      },
    },
    toolbarRow: 1,
  },
  {
    id: "filterRefTypes",
    placeholder: "Filter by Type",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "refTypeId",
      getData: async () => {
        let result = await getSQLData({ spName: "GetRefTypes" });
        return result[0];
      },
    },
    toolbarRow: 1,
  },
];

const ActionToName: simpleObject = {
  C: "Create",
  D: "Delete",
  E: "Edit",
};

const ActionIdToChipType: simpleObject = {
  C: "success",
  D: "error",
  E: "warning",
};
const ActionsFieldsId = ["CreateAction", "EditAction", "DeleteAction"];
const availableActions: Array<IColumnValue> = [
  {
    Name: "Create",
    Id: "C",
    FieldId: "CreateAction",
    Selected: true,
  },
  {
    Name: "Edit",
    Id: "E",
    FieldId: "EditAction",
    Selected: true,
  },
  {
    Name: "Delete",
    Id: "D",
    FieldId: "DeleteAction",
    Selected: true,
  },
];
const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RecordName",
    title: "Record Name",
    type: "string",
    format: {
      type: "string",
      customFormat: true,
      getCustomFormat(dataItem: simpleObject) {
        if (dataItem.DeleteAction) return dataItem.RecordName;
        return (
          <OpenCardLink
            text={dataItem.RecordName}
            refName={dataItem.ReferenceCode}
            dataAttr={dataItem.RecordID}
          />
        );
      },
    },
  },
  {
    field: "ReferenceTitle",
    title: "Reference Title",
    type: "string",
    format: {
      type: "string",
      fieldId: "ReferenceTitle",
    },
  },
  {
    field: "UserName",
    title: "User Name",
    type: "string",
    format: {
      type: "string",
      fieldId: "UserName",
    },
  },
  {
    field: "ActionsNames",
    title: "Actions",
    type: "string",
    gridWidth: 200,
    values: availableActions,
    format: {
      type: "string",
      fieldId: "Actions",
      customFormat: true,
      getCustomFormat(dataItem) {
        return (
          <div style={{ textAlign: "center" }}>
            {ActionsFieldsId.map((field) => {
              let value = dataItem[field];
              if (!value) return null;
              return (
                <Chip
                  key={value}
                  fillMode="outline"
                  style={{ margin: "0 2px" }}
                  text={ActionToName[value]}
                  themeColor={ActionIdToChipType[value]}
                />
              );
            })}
          </div>
        );
      },
    },
  },
  {
    field: "Min Date",
    title: "Min Date",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "Max Date",
    title: "Max Date",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
];

interface props {
  isActive: boolean;
}

interface state {}

class ActionsHistory extends Component<props, state> {
  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={COLUMNS}
        filters={filters}
        getSqlParams={{ spName: "DB_ActionsHistory" }}
        excelFileName="ActionsHistory.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.RecordID}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        prepareData={this.PrepareData}
        onRowContextMenu={this.OnRowContextMenu}
        initRefresh={this.InitRefresh}
        isValidFilters={this.IsValidFilters}
      ></DashboardGrid>
    );
  }

  PrepareData = (result: [Array<simpleObject>]) => {
    let data = result[0] || [];
    for (let item of data) {
      let names = "";
      let createAction: action = item.CreateAction;
      let editAction: action = item.EditAction;
      let deleteAction: action = item.DeleteAction;
      if (createAction) names += ActionToName[createAction] + " ";
      if (editAction) names += ActionToName[editAction] + " ";
      if (deleteAction) names += ActionToName[deleteAction] + " ";
      item.ActionsNames = names;
    }
    return data;
  };

  IsValidFilters = (toolbarFilter: IGetSQLDataParams) => {
    let params = toolbarFilter.params!;
    let dateFrom = moment(params.dateFrom);
    let dateTo = moment(params.dateTo);
    let diff = dateTo.diff(dateFrom, "days");
    if (diff > 31 && !params.employeeId && !params.refTypeId) {
      ModalRef.showDialog({
        title: "Attention",
        text: "Apply filter by Employee/Type to see the data for more than 31 days period",
      });
      return false;
    }
    return true;
  };

  OnRowContextMenu = (dataItem: simpleObject) => {
    CardManagement.OpenRecordHistoryCard(
      dataItem.RecordID,
      dataItem.RecordName
    );
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default ActionsHistory;
