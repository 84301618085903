import moment from "moment";
import React, { Component } from "react";
import DashboardGrid from "../../Components/Dashboard/Grid";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import { simpleObject } from "../../helpers/interfaces";
import { COLUMNS, PrepareData } from "./helpers";
import { platform } from "../LiveMap/interfaces";

interface props {
  deviceId: number;
  isModalCard: boolean;
  date?: Date;
}

interface state {
  DeviceName: string;
  DevicePlatform: platform | null;
}

class DeviceLogs extends Component<props, state> {
  FILTERS: Array<IFilterSetting> = [
    {
      id: "dateFilter",
      type: "date",
      defValue: this.props.date || new Date(),
      serverFilter: {
        param: "date",
      },
      dateSettings: {
        clearable: false,
        dateFormat: (date: Date) => moment(date).format().slice(0, 10),
      },
      toolbarRow: 1,
    },
    {
      id: "timeFilter",
      type: "rangetime",
      idColumn: "DeviceTime",
      toolbarRow: 1,
    },
  ];
  COLUMNS: IColumnSetting[];

  constructor(props: any) {
    super(props);
    this.state = {
      DeviceName: "",
      DevicePlatform: null,
    };
    this.COLUMNS = [...COLUMNS];
    this.COLUMNS.splice(
      0,
      1,
      {
        field: "DeviceTime",
        title: "Device Time",
        type: "date",
        filterable: false,
        columnMenu: false,
        format: {
          type: "time",
        },
      },
      {
        field: "LocalTime",
        title: "My Time",
        type: "date",
        filterable: false,
        columnMenu: false,
        format: {
          type: "datetime",
        },
      }
    );
  }

  renderTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1 0 auto",
        }}
      >
        {this.state.DeviceName}
      </div>
    );
  };

  renderSwitcher = () => {
    return (
      <>
        {this.state.DevicePlatform === "ios" && (
          <span
            style={{ fontSize: 18, position: "relative", top: -1 }}
            className={"fi-social-apple"}
          ></span>
        )}
        {this.state.DevicePlatform === "android" && (
          <span style={{ fontSize: 18 }} className={"fi-social-android"}></span>
        )}
      </>
    );
  };

  render() {
    if (!this.props.deviceId) return null;
    return (
      <DashboardGrid
        isActive={true}
        isParentPage={true}
        columnsSettings={this.COLUMNS}
        getSqlParams={{
          spName: "DB_DeviceLog",
          params: {
            deviceId: this.props.deviceId,
          },
        }}
        prepareData={this.PrepareData}
        filters={this.FILTERS}
        initRefresh={this.InitRefresh}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
        toolbar={{
          switcher: this.renderSwitcher(),
          notModeTitle: !this.props.isModalCard
            ? this.renderTitle()
            : undefined,
        }}
      />
    );
  }

  PrepareData = (result: [{ DeviceName: string }, Array<simpleObject>]) => {
    this.setState({ ...(result[0] as state) });
    return PrepareData(result[1]);
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default DeviceLogs;
