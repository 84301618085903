import { ChartTitle } from "@progress/kendo-react-charts";

interface props {
  text: string;
}

export default function FXChartTitle(props: props) {
  return (
    <ChartTitle
      text={props.text}
      font="700 normal 16px sans-serif"
      color="#000"
    />
  );
}
