import React from "react";
import {Button, Toolbar, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {InputChangeEvent} from "@progress/kendo-react-inputs";
import {RunScriptLocalAsync} from "../../helpers/runscripts";
import BaseComponent from "../../Components/BaseComponent";
import {IGroupedVIMLIst, IUser, IUserGroup, IVIMInvoice} from "./interfaces";
import commonStyles from "../../assets/styles/common.module.scss";
import styles from "./vim.module.scss";
import Loader from "../../Components/Common/Loader";
import ClearableInput from "../../Components/Common/Form/ClearableInput";
import List from "./List";
import {simpleObject} from "../../helpers/interfaces";

import DesktopActions from "../../Components/Common/DesktopActions";

interface IRenderGroupItem {
    type: "user" | "status";
    id: string;
    name: string;
    UserId: number;
    children: Array<simpleObject>;
    Expanded: boolean;
}

interface props {
    isActive: boolean;
    selectedId: number | null;

    onSelect(id: number | null, name: string | null): any;

    onRefresh(): any;
}

interface state {
    loading: boolean;
    toolbarSettings: {
        CanAdd: boolean;
    } | null;
    expandAll: boolean;
    renderData: Array<simpleObject>;
    remountListKey: number;
}

const UNKNOWN_USER_ID = 0;
const UNKNOWN_USER_NAME = "Others";
const NO_STATUS = "No Status";

class VIMList extends BaseComponent<props, state> {
    groupedList: IGroupedVIMLIst = {};
    currentUserId: number | undefined = undefined;
    searchValue: string = "";
    autoRefreshTimeout: any;
    filterChangeTimeout: any;
    usersIds: Array<number> = [];
    statuses: Array<string> = [];
    expandAll: boolean = true;
    listRef: any = React.createRef();
    unicUserInvoicesIds: Array<number> = [];

    constructor(props: props) {
        super(props);
        this.state = {
            loading: false,
            toolbarSettings: null,
            expandAll: true,
            renderData: [],
            remountListKey: +new Date(),
        };
    }

    componentDidMount() {
        this.LoadVIMInvoices(false);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.autoRefreshTimeout) clearTimeout(this.autoRefreshTimeout);
        if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
    }

    render() {
        return (
            <>
                {this.props.isActive && (
                    <div
                        className={commonStyles.ScreenHeightContainerWithToolbar}>
                        {this.state.loading && <Loader/>}
                        <Toolbar className={styles.ListToolbar}>
                            <ClearableInput
                                defaultValue={this.searchValue}
                                style={{width: "50%"}}
                                name="searchVIM"
                                placeholder="Search..."
                                onChange={this.OnChangeSearch}
                                clear={this.ClearFilter}
                            />
                            <ToolbarSpacer/>
                            <DesktopActions
                                actions={
                                    <>
                                        {this.state.toolbarSettings?.CanAdd && (
                                            <Button
                                                icon="plus"
                                                fillMode="flat"
                                                title="New Invoice"
                                                onClick={this.AddNewInvoice}
                                            />
                                        )}
                                        <Button
                                            icon="hyperlink-open"
                                            fillMode="flat"
                                            title="Open Invoices List"
                                            onClick={this.OpenInvoicesList}
                                        />
                                    </>
                                }
                            />

                            <Button
                                icon="refresh"
                                fillMode="flat"
                                title="Refresh"
                                onClick={this.RefreshVIMInvoices}
                            ></Button>
                            <Button
                                iconClass={`${styles.ExpandIcon} mdi mdi-${
                                    this.expandAll ? "collapse-all-outline" : "expand-all-outline"
                                }`}
                                fillMode="flat"
                                title={this.expandAll ? "Collapse All" : "Expand All"}
                                onClick={this.ToggleExpandGroup}
                            />
                        </Toolbar>
                        {!!this.state.renderData.length && (
                            <List
                                remountListKey={this.state.remountListKey}
                                ref={this.listRef}
                                statuses={this.statuses}
                                usersIds={this.usersIds}
                                groupedList={this.groupedList}
                                renderTree={this.state.renderData}
                                currentUserId={this.currentUserId}
                                selectedId={this.props.selectedId}
                                onSelect={this.props.onSelect}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }

    LoadVIMInvoices = async (isAutoRefresh: boolean) => {
        if (this.props.isActive) {
            this.groupedList = [];
            this.currentUserId = undefined;
            this.usersIds = [];
            this.statuses = [];
        }
        try {
            if (this.autoRefreshTimeout) clearTimeout(this.autoRefreshTimeout);
            if (!isAutoRefresh) this.setState({loading: true});
            let result = await this.GetSQLData({ spName: "VIM_Review" });
            const VIMListArray: IVIMInvoice[] = result[0] || [];
            let users = result[1];
            let [
                currentUserId,
                groupedList,
                usersIds,
                statuses,
                unicInvoicesIds
            ] = this.GetGroupData(users, VIMListArray);
            this.unicUserInvoicesIds = unicInvoicesIds;
            this.UpdateVIMBadge();

            if (this.props.isActive) {
                this.groupedList = groupedList;
                this.currentUserId = currentUserId;
                this.usersIds = usersIds;
                this.statuses = statuses;
                this.setState({
                    toolbarSettings: result[2],
                    renderData: this.GetRenderData(),
                    remountListKey: +new Date(),
                });
            }
            this.autoRefreshTimeout = setTimeout(
                this.AutorefreshVIMInvoices,
                10 * 60 * 1000
            );
        } finally {
            if (!isAutoRefresh) this.setState({loading: false});
        }
    };

    UpdateVIMBadge = () => {
        let count = this.unicUserInvoicesIds.length || 0;
        let vimBadge = document.querySelector(".vim-badge");
        window.vimBadgeText = count ? count + "" : "";
        if (vimBadge) {
            vimBadge.innerHTML = window.vimBadgeText;
        }
    };

    RefreshVIMInvoices = async () => {
        await this.LoadVIMInvoices(false);
        this.props.onRefresh();
    };

    AutorefreshVIMInvoices = () => {
        this.LoadVIMInvoices(true);
    };

    ExternalRefreshInvoice = async (VIMId: number) => {
        // todo проверить
        try {
            this.setState({loading: true});
            let result = await this.GetSQLData({
                spName: "VIM_Review",
                params: {invoiceId: VIMId},
            });
            if (result[0].length) {
                let usersForRefresh = result[1];
                let invoiceRefreshed: IVIMInvoice = result[0][0];
                invoiceRefreshed.Status = this.GetItemStatusName(invoiceRefreshed);
                invoiceRefreshed.id = invoiceRefreshed.Id + "";

                if (!usersForRefresh.length) {
                    usersForRefresh.push({
                        IsCurrentUser: false,
                        UserId: UNKNOWN_USER_ID,
                        UserName: UNKNOWN_USER_NAME,
                    });
                    invoiceRefreshed.UserId = UNKNOWN_USER_ID;
                    this.unicUserInvoicesIds = this.unicUserInvoicesIds.filter(
                        (id) => id !== invoiceRefreshed.Id
                    );
                }

                let groupedList = this.groupedList;
                for (let userId of this.usersIds) {
                    let needUpdate = usersForRefresh.find(
                        (user: IUser) => userId === user.UserId
                    );
                    if (needUpdate) {
                        let userData = groupedList[userId];
                        for (let status of this.statuses) {
                            let statusData = userData.groupsByStatus[status];
                            if (statusData !== undefined) {
                                for (let i = 0; i < statusData.invoices.length; i++) {
                                    let item = statusData.invoices[i];
                                    if (item.Id === invoiceRefreshed.Id) {
                                        invoiceRefreshed.Visible = item.Visible;
                                        if (invoiceRefreshed.Status === status) {
                                            Object.assign(item, invoiceRefreshed);
                                        } else {
                                            statusData.invoices.splice(i, 1);
                                            if (statusData.invoices.length === 0)
                                                delete userData.groupsByStatus[status];
                                            if (!userData.groupsByStatus[invoiceRefreshed.Status]) {
                                                if (this.statuses.indexOf(status) === -1)
                                                    this.statuses.push(status);
                                                userData.groupsByStatus[invoiceRefreshed.Status] =
                                                    this.GetEmptyStatusGroup();
                                            }
                                            userData.groupsByStatus[
                                                invoiceRefreshed.Status
                                                ].invoices.push(invoiceRefreshed);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.SetRenderData();
                this.UpdateVIMBadge();
            } else {
                this.DeleteInvoice(VIMId);
            }
        } finally {
            this.setState({loading: false});
        }
    };

    DeleteInvoice = (VIMId: number) => {
        // todo проверить
        let invoiceData;
        if (this.listRef.current && this.listRef.current.GetInvoiceById) {
            invoiceData = this.listRef.current.GetInvoiceById(VIMId);
        }
        if (this.listRef.current && this.listRef.current.SelectNextOrUnselect) {
            this.listRef.current.SelectNextOrUnselect();
        }

        let data = this.groupedList;
        let userId = invoiceData.UserId;
        let status = invoiceData.Status;
        let userData = data[userId];
        let statusData = userData.groupsByStatus[status];

        let userInvIndex = userData.invoices.findIndex(
            (item) => +item.Id === +VIMId
        );
        let userInvIdIndex = userData.invoicesIds.findIndex((id) => +id === +VIMId);
        let statusDataInvIndex = statusData.invoices.findIndex(
            (item) => +item.Id === +VIMId
        );

        if (userInvIndex > -1) {
            let invoice = userData.invoices[userInvIndex];
            if (invoice.Visible) userData.visibleInvoices -= 1;
            userData.invoices.splice(userInvIndex, 1);
        }
        if (userInvIdIndex > -1) userData.invoicesIds.splice(userInvIdIndex, 1);
        if (statusDataInvIndex > -1) {
            let invoice = statusData.invoices[statusDataInvIndex];
            if (invoice.Visible) statusData.visibleInvoices -= 1;
            statusData.invoices.splice(statusDataInvIndex, 1);
        }

        if (!statusData.invoices.length) delete userData.groupsByStatus[status];
        if (!userData.invoices.length) delete data[userId];
        this.unicUserInvoicesIds = this.unicUserInvoicesIds.filter(
            (id) => id !== VIMId
        );
        this.UpdateVIMBadge();
        this.SetRenderData();
    };

    OnChangeSearch = (e: InputChangeEvent) => {
        this.searchValue = e.value.toLowerCase();
        if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
        this.filterChangeTimeout = setTimeout(this.FilterList, 1000);
    };

    ClearFilter = () => {
        this.searchValue = "";
        this.FilterList();
    };

    GetItemVisible = (value: string, item: IVIMInvoice) => {
        return (
            !value ||
            !!(item.Number && item.Number.toLowerCase().includes(value)) ||
            (item.Date && item.DateFormatted.includes(value)) ||
            (item.VendorName && item.VendorName.toLowerCase().includes(value)) ||
            (item.Amount !== null && ("$" + item.Amount).includes(value)) ||
            (item.CODRequesterName !== null &&
                item.CODRequesterName.toLowerCase().includes(value)) ||
            item.Status.toLowerCase().includes(value) ||
            (item.Classifier !== null &&
                item.Classifier.toLowerCase().includes(value))
        );
    };

    FilterList = () => {
        let value = this.searchValue;
        let groupedList = this.groupedList;
        for (let userId of this.usersIds) {
            let userData = groupedList[userId];
            userData.visibleInvoices = 0;
            for (let status of this.statuses) {
                let statusData = userData.groupsByStatus[status];
                if (statusData !== undefined) {
                    statusData.visibleInvoices = 0;
                    for (let invoice of statusData.invoices) {
                        invoice.Visible = this.GetItemVisible(value, invoice);
                        if (invoice.Visible) {
                            statusData.visibleInvoices += 1;
                            userData.visibleInvoices += 1;
                        }
                    }
                }
            }
        }
        this.SetRenderData();
    };

    GetRenderData = () => {
        let groupedList = this.groupedList;
        let renderData: Array<simpleObject> = [];
        for (let userId of this.usersIds) {
            let userData = groupedList[userId];
            if (userData && userData.visibleInvoices) {
                let userRow: IRenderGroupItem = {
                    type: "user",
                    id: userId + "_user",
                    name: userData.UserName,
                    UserId: userId,
                    children: [],
                    Expanded: userData.expanded,
                };
                if (this.usersIds.length > 1) renderData.push(userRow);

                let listGroups = userData.groupsByStatus;
                for (let status of this.statuses) {
                    let statusData = listGroups && listGroups[status];
                    if (statusData && statusData.visibleInvoices) {
                        let statusRow: IRenderGroupItem = {
                            type: "status",
                            name: status,
                            id: status + "_" + userId + "_status",
                            UserId: userId,
                            children: [],
                            Expanded: statusData.expanded,
                        };
                        if (this.usersIds.length > 1) userRow.children.push(statusRow);
                        else renderData.push(statusRow);
                        statusRow.children = statusData.invoices.filter(
                            (inv) => inv.Visible
                        );
                    }
                }
            }
        }
        return renderData;
    };

    GetItemStatusName = (VIMItem: IVIMInvoice) => {
        let status = VIMItem.Status || NO_STATUS;
        if (status === "Approved") {
            status = VIMItem.IsSyncedToQB
                ? "Approved - Ready to be Paid"
                : "Approved - Ready to Sync to QB";
        }
        return status;
    };

    GetFormattedDate = (datestring: string) => {
        let date = new Date(datestring);
        let month = date.getMonth() + 1 + "";
        let day = date.getDate() + "";
        if (month.length === 1) month = "0" + month;
        if (day.length === 1) day = "0" + day;
        return `${month}/${day}/${date.getFullYear()}`;
    };

    GetGroupData = (
        users: Array<IUser>,
        invoices: Array<IVIMInvoice>
    ): [
        currentUserId: number | undefined,
        groupedList: IGroupedVIMLIst,
        usersIds: Array<number>,
        statuses: Array<string>,
        ids: Array<number>
    ] => {
        const currentUserId: number | undefined = users.find(u => u.IsCurrentUser)?.UserId;
        let usersIds: Array<number> = [];
        let statuses: Array<string> = [];
        let unicUserInvoicesIds: Array<number> = [];
        const groupedList = users.reduce(
            (groups: IGroupedVIMLIst, user: IUser) => {
                if (this.props.isActive || user.IsCurrentUser) {
                    if (!groups[user.UserId]) {
                        groups[user.UserId] = {
                            UserId: user.UserId,
                            UserName: user.UserName,
                            IsCurrentUser: user.IsCurrentUser,
                            invoicesIds: [],
                            invoices: [],
                            groupsByStatus: {},
                            visibleInvoices: 0,
                            expanded: true,
                        };
                        usersIds.push(user.UserId);
                    }
                    groups[user.UserId].invoicesIds.push(user.Id);
                }
                if (unicUserInvoicesIds.indexOf(user.Id) === -1) {
                    unicUserInvoicesIds.push(user.Id);
                }
                return groups;
            },
            {}
        );
        usersIds.sort((id) => id === currentUserId ? -1 : 1)
        if (this.props.isActive) {
            for (let VIMItem of invoices) {
                let hasUser = false;
                let status = this.GetItemStatusName(VIMItem);
                VIMItem.Status = status;
                VIMItem.id = VIMItem.Id + ""; // for VTree
                VIMItem.Visible = this.GetItemVisible(this.searchValue, VIMItem);
                VIMItem.DateFormatted = VIMItem.Date
                    ? this.GetFormattedDate(VIMItem.Date)
                    : ""; // couse moment reduces performance
                let userData: IUserGroup | undefined;
                let userId: number | undefined;

                for (let id of usersIds) {
                    // если бы сервер в данных по инвойсам присылал UserId то можно было бы упростить эту проверку
                    userData = groupedList[id];
                    if (userData && id !== UNKNOWN_USER_ID) {
                        let index = userData.invoicesIds.findIndex(
                            (item) => +item === +VIMItem.Id
                        );
                        if (index > -1) {
                            hasUser = true;
                            userId = +id;
                            break;
                        }
                    }
                }

                if (!hasUser) {
                    if (!groupedList[UNKNOWN_USER_ID]) {
                        groupedList[UNKNOWN_USER_ID] = {
                            IsCurrentUser: false,
                            UserId: UNKNOWN_USER_ID,
                            UserName: UNKNOWN_USER_NAME,
                            invoicesIds: [], // for unknowngroup empty
                            invoices: [],
                            groupsByStatus: {},
                            visibleInvoices: 0,
                            expanded: true,
                        };
                        usersIds.push(UNKNOWN_USER_ID);
                    }
                    userId = UNKNOWN_USER_ID;
                    userData = groupedList[UNKNOWN_USER_ID];
                }

                if (userData !== undefined && userId !== undefined) {
                    if (!userData.groupsByStatus[status]) {
                        if (statuses.indexOf(status) === -1) statuses.push(status);
                        userData.groupsByStatus[status] = this.GetEmptyStatusGroup();
                    }
                    let userGroupStatus = userData.groupsByStatus[status];
                    VIMItem.UserId = userId;
                    userGroupStatus.invoices.push(VIMItem);
                    userData.invoices.push(VIMItem);
                    if (VIMItem.Visible) {
                        userData.visibleInvoices += 1;
                        userGroupStatus.visibleInvoices += 1;
                    }
                }
            }

            if (usersIds.length > 1) {
                usersIds.sort((x, y) => {
                    if (+x === UNKNOWN_USER_ID || +y === currentUserId) return -1;
                    if (+y === UNKNOWN_USER_ID || +x === currentUserId) return 1;
                    return 0;
                });
            }
        }

        return [
            currentUserId,
            groupedList,
            usersIds,
            statuses,
            unicUserInvoicesIds,
        ];
    };

    GetEmptyStatusGroup = () => ({
        invoices: [],
        visibleInvoices: 0,
        expanded: true,
    });

    OpenInvoicesList = () => {
        RunScriptLocalAsync("VIMInvoices_OpenReference");
    };

    AddNewInvoice = () => {
        RunScriptLocalAsync("VIMInvoices_Add");
    };

    ToggleExpandGroup = () => {
        this.expandAll = !this.expandAll;
        let groupedList = this.groupedList;
        for (let userId of this.usersIds) {
            let userData = groupedList[userId];
            userData.expanded = this.expandAll;
            for (let status of this.statuses) {
                let statusData = userData.groupsByStatus[status];
                if (statusData !== undefined) {
                    statusData.expanded = this.expandAll;
                }
            }
        }
        this.SetRenderData(true);
    };

    SetRenderData = (remountList?: boolean) => {
        this.setState((state) => ({
            renderData: this.GetRenderData(),
            remountListKey: remountList === true ? +new Date() : state.remountListKey,
        }));
    };
}

export default VIMList;
