import React, { useEffect, useRef } from "react";
import styles from "./map.module.scss";
import { IServerSiteInfo, ISiteBPItem } from "./interfaces";
import { getSQLData } from "../../helpers/queries";

interface IProps {
  map: any;
}

const loaderHtml =
  '<div class="' +
  styles.InProcess +
  '" style="width: 30px; height: 30px;"></div>';

const FullSiteTooltips = (props: IProps) => {
  const { map } = props;
  const sitesFullTooltipRef = useRef<{ [key: number]: string }>({});

  useEffect(() => {
    map?.on("tooltipopen", onTooltipOpen);
    return () => {
      map?.off("tooltipopen", onTooltipOpen);
    };
  }, [map]);

  const onTooltipOpen = async (e: any) => {
    const { siteId, marker } = e.tooltip.options;
    if (siteId === undefined) return;
    const doGetTooltipInfo = e.tooltip.options.doGetTooltipInfo;
    const cacheFullTooltip = sitesFullTooltipRef.current[siteId];
    if (!doGetTooltipInfo && cacheFullTooltip) {
      marker.setTooltipContent(cacheFullTooltip);
      return;
    }
    e.tooltip.options.doGetTooltipInfo = null;
    const { address, objectName } = marker.options;
    try {
      marker.setTooltipContent("<div>" + loaderHtml + "</div>");
      const result: any = await getSQLData({
        spName: "FSMSites_GetData",
        params: { siteId },
      });
      const siteInfo = result[0][0] as IServerSiteInfo;
      const bpSiteInfo = result[1] as ISiteBPItem[];
      const carrierHtml = siteInfo.CarrierName
        ? "<div><b>Carrier:</b> " + siteInfo.CarrierName + "</div>"
        : "";
      const buildPlansTitleHtml = bpSiteInfo.length
        ? "<div><b>Related Build Plans:</b></div>"
        : "";
      let buildPlansHtml = "";
      for (let bp of bpSiteInfo) {
        buildPlansHtml += "<div>" + bp.BuildPlanName + "</div>";
      }
      const tooltipContent =
        "<div style='max-width: 400px;'>" +
        "<div><b>" +
        objectName +
        "</b></div>" +
        carrierHtml +
        "<div>" +
        address +
        "</div>" +
        buildPlansTitleHtml +
        buildPlansHtml +
        "</div>";
      sitesFullTooltipRef.current[siteId] = tooltipContent;
      marker.setTooltipContent(tooltipContent);
    } catch (e: any) {
      const error = '<div style="color: red;">Error</div>';
      const tooltipContent =
        "<div style='max-width: 400px;'>" +
        "<div><b>" +
        objectName +
        "</b></div>" +
        "<div>" +
        address +
        "</div>" +
        error +
        "</div>";
      marker.setTooltipContent(tooltipContent);
    }
  };

  return <></>;
};

export default FullSiteTooltips