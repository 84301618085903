export default function AccessDenied() {
  return (
    <span
      style={{
        padding: "10px",
      }}
    >
      AccessDenied
    </span>
  );
}