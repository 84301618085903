import { IHistoryItem } from "./interfaces";
import styles from "./checklistResults.module.scss";
import { Component } from "react";
import { isMobile } from "../../../helpers/settings";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import { Card } from "@progress/kendo-react-layout";

interface state {}

interface props {
  loadingHistory: boolean;
  history: Array<IHistoryItem>;
}

class ChecklistResultHistory extends Component<props, state> {
  render() {
    if (this.props.loadingHistory) return "Loading History...";
    if (isMobile) {
      return (
        <ListView
          data={this.props.history}
          item={this.renderMobileEntry}
          style={{ width: "100%" }}
        />
      );
    }
    return (
      <div className={styles.HistoryList}>
        {this.props.loadingHistory && "Loading History..."}
        {this.props.history.map(this.renderEntry)}
      </div>
    );
  }

  renderEntry = (entry: IHistoryItem, i: number) => {
    return (
      <div key={i} className={styles.HistoryEntry}>
        <div className={styles.HistoryEntryRow}>
          <span>{entry.d}</span>
          <span className={styles.HistoryEntrySrc}>{entry.src}</span>
        </div>
        <div className={styles.HistoryEntryRow}>{entry.u}</div>
        <div className={styles.HistoryEntryRow}>{entry.m}</div>
      </div>
    );
  };

  renderMobileEntry = ({ dataItem, index }: ListViewItemProps) => {
    return (
      <Card key={index} style={{ margin: "4px 0", padding: 4 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{dataItem.d}</span>
          <span className={styles.HistoryEntrySrc}>{dataItem.src}</span>
        </div>
        <div style={{ fontSize: 12, margin: "4px 0" }}>{dataItem.u}</div>
        <div>{dataItem.m}</div>
      </Card>
    );
  };
}

export default ChecklistResultHistory;
