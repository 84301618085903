import { Component } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import { maxDate, minDate } from "../../Dashboard/helpers";
import ClearableDateInput from "./ClearableDateInput";

interface props {
  defaultValue: any;
  filter?: any;
  className?: string;
  required?: boolean;

  onChange(value: any, filter?: any): void;

  clear?(filter?: any): void;
}

interface state {
  remountKey: number;
}

class ClearableDateFilter extends Component<props, state> {
  defaultValue: Date | undefined = this.props.defaultValue;
  dateChangeTimeout: any;
  value: Date | undefined = this.props.defaultValue;

  constructor(props: props) {
    super(props);
    this.state = {
      remountKey: +new Date(),
    };
  }

  render() {
    let filter = this.props.filter;
    return (
      <DatePicker
        key={this.state.remountKey}
        data-param={filter.placeholder}
        id={filter.id}
        formatPlaceholder={{ month: "m", day: "d", year: "y" }}
        onChange={this.OnDatePickerChange}
        defaultValue={this.props.defaultValue}
        min={minDate}
        max={maxDate}
        required={this.props.required}
        className={this.props.className}
        dateInput={
          this.props.clear
            ? (props) => (
                <ClearableDateInput
                  defaultProps={props}
                  clear={this.ClearFilter}
                />
              )
            : undefined
        }
      ></DatePicker>
    );
  }

  ClearFilter = () => {
    this.defaultValue = undefined;
    this.setState({
      remountKey: +new Date(),
    });
    if (this.props.clear) this.props.clear(this.props.filter);
  };

  ChangeDatePicker = () => {
    this.props.onChange(this.value, this.props.filter);
  };

  OnDatePickerChange = (e: any) => {
    let value = e.value;
    if (
      !value ||
      moment(value).isBefore(minDate) ||
      moment(value).isAfter(maxDate)
    )
      return;
    this.value = e.value;
    if (this.dateChangeTimeout) clearTimeout(this.dateChangeTimeout);
    if (e.syntheticEvent.type === "click") this.ChangeDatePicker();
    else {
      this.dateChangeTimeout = setTimeout(this.ChangeDatePicker, 1000);
    }
  };
}

export default ClearableDateFilter;
