import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./sidebar.module.scss";
import SideBarToggleBtn from "./SideBarToggleBtn";

interface props {
  mode: "overlay" | "push";
  defaultOpened: boolean;
  content: any;
  children?: any;
  rtl?: boolean;
  btnText?: string;
  btnTextStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onToggle?: (openedState: boolean) => void;
}

const SideBar = (props: props) => {
  const { defaultOpened, btnText, rtl } = props;
  const [opened, setOpened] = useState<boolean>(!!defaultOpened);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevBtnText = useRef<string | undefined>(btnText);
  const [boxHeight, setBoxHeight] = useState(0);

  useLayoutEffect(() => {
    setBoxHeight(containerRef.current?.clientHeight || 100);
  });

  useEffect(() => {
    if (props.onToggle) props.onToggle(opened);
  }, [opened]);

  useEffect(() => {
    if (prevBtnText.current === btnText) return;
    setOpened(false);
    prevBtnText.current = btnText;
  }, [btnText]);

  const renderPanel = () => {
    return (
      <div
        className={`${styles.Sidebar} ${rtl ? styles.RTL : ""} ${
          opened ? styles.Opened : ""
        }`}
      >
        {props.content}
        <SideBarToggleBtn
          toggle={toggle}
          btnText={btnText || ""}
          boxHeight={boxHeight}
          opened={opened}
          btnTextStyle={props.btnTextStyle}
          rtl={rtl}
        />
      </div>
    );
  };

  const toggle = () => {
    setOpened((oldValue) => !oldValue);
  };

  return (
    <div
      ref={containerRef}
      className={`${styles.Box} ${
        props.mode === "overlay" ? styles.OverlaySidebar : styles.PushSidebar
      }`}
      style={props.style}
    >
      {rtl && (
        <>
          {props.children}
          {renderPanel()}
        </>
      )}
      {!rtl && (
        <>
          {renderPanel()}
          {props.children}
        </>
      )}
      {props.mode === "overlay" && opened && (
        <div className={styles.Overlay} onClick={toggle}></div>
      )}
    </div>
  );
};

export default SideBar;
