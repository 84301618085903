import {Component} from "react";
import {
    DropDownsPopupSettings,
    DropDownTree,
    DropDownTreeChangeEvent,
    DropDownTreeExpandEvent,
    DropDownTreeFilterChangeEvent,
    ListItemProps,
} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-react-data-tools";
import {IComboboxItem} from "../../../helpers/interfaces";

interface IProps {
    opened?: boolean;
    dataAttr?: any;
    data?: ITreeItem[];
    placeholder: string;
    className?: string;
    loading?: boolean;
    defaultValue?: IDataItem | null;
    value?: IDataItem | null;
    disabled?: boolean;
    width?: number;
    required?: boolean;
    popupSettings?: DropDownsPopupSettings;
    textField?: string;
    dataItemKey?: string;
    getData?: () => Promise<any>;
    size?: "small" | "medium" | "large" | null | undefined;

    onChange(value: IDataItem | null, dataAttr?: any): void;

    itemRender?(
        li: React.ReactElement<
            HTMLLIElement,
            string | React.JSXElementConstructor<any>
        >,
        itemProps: ListItemProps
    ): React.ReactNode | undefined;
}

interface IDataItem extends IComboboxItem {
    ParentId?: number | string | null
    Level?: number
}

interface ITreeItem extends IDataItem {
    items?: ITreeItem[]
    expanded?: boolean
    parentName?: string
}

interface IState {
    // isLoading: boolean;
    filter: any;
    treeFilteredData: ITreeItem[] | null;
}

class FilterDropdownTree extends Component<IProps, IState> {
    rareData: IDataItem[] = this.props.data || []
    treeData: ITreeItem[] = []

    constructor(props: IProps) {
        super(props);
        this.state = {
            // isLoading: false,
            filter: null,
            treeFilteredData: null,
        };
    }

    componentDidMount() {
        // if (this.props.getData) this.LoadData(this.props.getData);
        /*else */
        this.PrepareData()
    }

    /*  LoadData = async (func: () => Promise<any>) => {
        try {
          this.setState({ isLoading: true });
          this.rareData = await func()
          this.PrepareData()
        } finally {
          this.setState({ isLoading: false });
        }
      };*/

    PrepareData = () => {
        const levels: ITreeItem[][] = []
        levels.push(this.rareData.filter(item => item.Level === 1).map(item => ({
            ...item,
            expanded: true
        })))
        levels.push(this.rareData.filter(item => item.Level === 2))
        levels.push(this.rareData.filter(item => item.Level === 3))

        this.treeData = []
        for (let item of levels[0]) {
            this.treeData.push({
                ...item,
                items: levels[1].filter(subItem => subItem.ParentId === item.Id).map(subItem => ({
                    ...subItem,
                    expanded: true,
                    parentName: item.Name
                }))
            })
        }

        for (let item of levels[1]) {
            item.items = levels[2].filter(subItem => subItem.ParentId === item.Id).map(subItem => ({
                ...subItem,
                parentName: item.Name
            }))
        }

        this.setState({treeFilteredData: this.treeData})
    }

    render() {
        return (<>
                <DropDownTree
                    className={this.props.className}
                    style={{width: this.props.width}}
                    placeholder={this.props.placeholder}
                    data={this.state.treeFilteredData || []}
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    onChange={this.OnChange}
                    textField={this.props.textField || "Name"}
                    dataItemKey={this.props.dataItemKey || "Id"}
                    selectField={'selected'}
                    expandField={'expanded'}
                    onExpandChange={this.OnExpandChange}
                    filterable={true}
                    onFilterChange={this.Filter}
                    filter={this.state.filter}
                    disabled={!!this.props.disabled}
                    loading={/*this.state.isLoading || */this.props.loading}
                    opened={this.props.opened}
                    required={this.props.required}
                    popupSettings={{popupClass: 'dropdowntree-scroll-popup', ...(this.props.popupSettings || {})}}
                    size={this.props.size}
                />
            </>
        );
    }

    Filter = (e: DropDownTreeFilterChangeEvent) => {
        const filter = e.filter.value
        this.setState({
            filter,
            treeFilteredData: filter ? filterBy(this.treeData, [{
                logic: 'or',
                filters: [
                    {
                        value: filter,
                        field: 'Name',
                        operator: "contains",
                    },
                    {
                        value: filter,
                        field: 'parentName',
                        operator: "contains",
                    }
                ]
            }], 'items') : this.treeData
        });
    };

    /*OnOpen = () => {
        this.setState({filter: null});
    };*/

    OnChange = (e: DropDownTreeChangeEvent) => {
        const value = e.value;
        if (!value) {
            this.props.onChange(value, this.props.dataAttr);
            return
        }

        const {selected, items, expanded, ...selectedItemFields} = e.value
        this.props.onChange({...selectedItemFields}, this.props.dataAttr);
    };

    OnExpandChange = (event: DropDownTreeExpandEvent) => {
        event.item.expanded = !event.item.expanded
    }
}

export default FilterDropdownTree;
