import React, {useCallback, useEffect} from "react";
import {Popover} from "@progress/kendo-react-tooltip";
import styles from "./adjustmentsEdit.module.scss";
import {useBooleanState} from "../../../helpers/hooks";
import {IAdjustmentAllocation} from "../../../Pages/TKReview/interfaces";
import {Button} from "@progress/kendo-react-buttons";

export const MultipleWo = (props: {
    workOrders: IAdjustmentAllocation[],
    btnText?: string
}) => {
    const {workOrders, btnText} = props;
    const isShownPopover = useBooleanState(false);
    const anchor = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isShownPopover.value) {
            document.addEventListener("click", isShownPopover.setFalse);
        } else {
            document.removeEventListener("click", isShownPopover.setFalse);
        }
        return () => {
            document.removeEventListener("click", isShownPopover.setFalse);
        };
    }, [isShownPopover.value, isShownPopover]);

    const onClickChip = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            isShownPopover.toggle();
        },
        [isShownPopover.toggle]
    );

    const renderPopover = () => {
        return (
            <Popover
                show={isShownPopover.value}
                anchor={anchor.current}
                callout={false}
                style={{maxHeight: 400,width: 300}}
                position={"left"}
            >
                <div>
                    {workOrders.map(({
                                         WorkOrderName,
                                         WorkOrderId,
                                         Percentage
                                     }) => (
                        <div key={WorkOrderId} className={styles.StaticWORow}>
                            <div>
                                {WorkOrderName}
                            </div>
                            <div>{Percentage}%</div>
                        </div>
                    ))}
                </div>
            </Popover>
        );
    };

    return (
        <>
            <div ref={anchor} style={{marginLeft: 2}}>
                <Button
                    iconClass={"mdi mdi-eye-outline"}
                    title={"Multiple Static Allocation"}
                    fillMode={"flat"}
                    onClick={onClickChip}
                />
            </div>
            {renderPopover()}
        </>
    );
};
