import AuthData from "../../helpers/authData";
import { isConterra } from "../../helpers/settings";
import BaseComponent from "../../Components/BaseComponent";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Home.module.scss";

interface props {
  className?: string;
  user: string;
}

class User extends BaseComponent<props> {
  render() {
    return (
      <div className={`${styles.UserBox} ${this.props.className || ""}`}>
        <b
          className={styles.UserName}
          style={{ width: isConterra ? "187px" : "160px" }}
        >
          {this.props.user}
        </b>
        {!isConterra && (
          <Button
            iconClass="k-icon mdi mdi-logout"
            className={styles.LogoutBtn}
            fillMode="flat"
            title="logout"
            onClick={AuthData.logout}
          ></Button>
        )}
      </div>
    );
  }
}

export default User;
