import { Component } from "react";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { maxDate, minDate } from "../../Components/Dashboard/helpers";
import styles from "./milestones.module.scss";

interface props {
  bpId: number;
  msId: number;
  field: string;
  defaultValue: any;

  onChange(value: Date | null, field: string, bpId: number, msId: number): void;
}

class GridDatePicker extends Component<props> {
  value: Date | null = this.props.defaultValue;

  render() {
    return (
      <DatePicker
        data-bpid={this.props.bpId}
        data-milestoneid={this.props.msId}
        data-field={this.props.field}
        className={styles.GridDatepicker}
        onChange={this.OnChange}
        onBlur={this.ApplyChanges}
        defaultValue={this.props.defaultValue}
        formatPlaceholder={{ month: "m", day: "d", year: "y" }}
        min={minDate}
        max={maxDate}
        dateInput={(props) => (
          <>
            <DateInput {...props} ref={this.InitInputRef} />
            <span
              className="k-clear-value"
              role="button"
              title="clear"
              onClick={this.OnClear}
            >
              <span className="k-icon k-i-x"></span>
            </span>
          </>
        )}
      />
    );
  }

  InitInputRef = (ref: any) => {
    if (ref) {
      ref.element?.addEventListener("keydown", this.OnKeyDown);
    }
  };

  OnClear = () => {
    this.value = null;
    this.ApplyChanges();
  };

  OnChange = (e: any) => {
    this.value = e.value;
  };

  OnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      this.ApplyChanges();
    }
  };

  ApplyChanges = () => {
    const value = this.value;
    if (value === this.props.defaultValue) return;

    this.props.onChange(
      value,
      this.props.field,
      this.props.bpId,
      this.props.msId
    );
  };
}

export default GridDatePicker
