import React from "react";
import { SplitPane } from "react-multi-split-pane";
import { pageId } from "../Home/interfaces";
import WebIframe from "../../Components/WebIframe";
import { ModalTimeCardInIframe } from "../../Components/Common/PortalTimeCard/PortalTimeCard";

interface IProps {
  folder: pageId;
  id: "TKAuditCoverFrame";
  isActive: boolean;
}

class TKAuditCover extends React.Component<IProps, {}> {
  iframeRef: any;

  initRef = (ref: any) => {
    if (this.iframeRef !== ref) this.iframeRef = ref;
  };

  render() {
    return (
      <SplitPane split="vertical" defaultSizes={[1, 1]}>
        <div style={{ flex: 1 }}>
          <WebIframe {...this.props} initRef={this.initRef} />
        </div>
        <div style={{ height: "100%", width: "100%" }}>
          <ModalTimeCardInIframe />
        </div>
      </SplitPane>
    );
  }
}

export default TKAuditCover;
