import { IDocumentItem } from "./interfaces";
import BaseComponent from "../../../Components/BaseComponent";
import {
  OnChangeCompleted,
  OnChangeStatus,
  radioButtonsRoles,
} from "./helpers";
import { Button } from "@progress/kendo-react-buttons";
import MobilePopup from "../../../Components/Common/MobilePopup/Index";
import popupStyles from "../../../Components/Common/MobilePopup/index.module.scss";
import { ICLMSettings } from "../../../stores/interfaces";
import {
  mobilePopupBtnOuterHeight,
  mobilePopupPaddings,
} from "../../../Components/Common/MobilePopup/helpers";

interface props {
  settings: ICLMSettings;
  dataItem: IDocumentItem;
  currentFileId: number | null;

  refreshList(): void;

  onHide(): void;

  onProcessing(processing: boolean): void;

  cancelToken(): any;
}

interface state {}

class MobileCLIStatuses extends BaseComponent<props, state> {
  render() {
    let dataItem = this.props.dataItem;
    let isSubmitter = this.props.settings.IsSubmitter;
    let btns: Array<any> = [];

    if (!isSubmitter) {
      radioButtonsRoles[this.props.settings.UserRole].forEach((item) => {
        let id = item.value;
        let isDisabled = item.value === "Approved" && !dataItem.files.length;
        let isCurrentStatus = dataItem.status === item.value;
        if (!isDisabled && !isCurrentStatus) {
          btns.push(
            <Button
              key={id}
              id={id}
              className={popupStyles.MobilePopupBtn}
              size={"large"}
              onClick={this.OnChangeStatus}
            >
              {item.label}
            </Button>
          );
        }
      });
    }

    let currentStatusHeight = 32;
    return (
      <MobilePopup
        onHide={this.props.onHide}
        height={
          isSubmitter
            ? 135
            : btns.length * mobilePopupBtnOuterHeight +
              currentStatusHeight +
              mobilePopupPaddings * 2
        }
        width={290}
      >
        {this.renderCurrentStatus(dataItem)}
        {isSubmitter ? (
          <Button
            key={"changeCompleted"}
            id={"changeCompleted"}
            className={popupStyles.MobilePopupBtn}
            size={"large"}
            onClick={this.OnChangeCompleted}
          >
            {dataItem.isCompleted ? "In Progress" : "Complete"}
          </Button>
        ) : (
          btns
        )}
      </MobilePopup>
    );
  }

  renderCurrentStatus = (dataItem: IDocumentItem) => {
    return (
      <div
        style={{
          marginBottom: 8,
          fontSize: 16,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Current Status: </span>
        <b>{dataItem.statusName}</b>
      </div>
    );
  };

  OnChangeCompleted = async () => {
    try {
      this.props.onProcessing(true);
      let dataItem = this.props.dataItem;
      await OnChangeCompleted(
        !dataItem.isCompleted,
        dataItem,
        this.props.cancelToken()
      );
      this.props.refreshList();
      this.props.onHide();
    } finally {
      this.props.onProcessing(false);
    }
  };

  OnChangeStatus = async (e: any) => {
    try {
      this.props.onProcessing(true);
      let newStatus = e.currentTarget.id;
      let dataItem = this.props.dataItem;
      await OnChangeStatus(
        newStatus,
        dataItem,
        this.props.currentFileId,
        this.props.cancelToken()
      );
      this.props.refreshList();
      this.props.onHide();
    } finally {
      this.props.onProcessing(false);
    }
  };
}

export default MobileCLIStatuses;
