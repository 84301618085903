import React from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import BaseComponent from "../../Components/BaseComponent";
import { IComboboxItem } from "../../helpers/interfaces";
import LaborDashboard from "../LaborDashboard";
import { CrewLeadDashboard } from "../CrewLeadDashboard";
import styles from "./index.module.scss";
import { IView, viewId } from "./interfaces";
import { DetailsViewId, SummaryViewId } from "./helpers";

const VIEWS: Array<IView> = [
  {
    Id: SummaryViewId,
    Name: "Summary",
    Component: CrewLeadDashboard,
  },
  {
    Id: DetailsViewId,
    Name: "Details",
    Component: LaborDashboard,
  },
];

interface props {
  isActive: boolean;
}

interface state {
  activeViewId: viewId;
}

class CrewLeadSummary extends BaseComponent<props, state> {
  classesList: Array<IComboboxItem> = [];
  initedViews: { [key: string]: boolean } = {
    [SummaryViewId]: true,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      activeViewId: SummaryViewId,
    };
  }

  // on Unmount or on !isActive === false remove filters from SS ??

  render() {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        {this.state.activeViewId === SummaryViewId && (
          <CrewLeadDashboard
            switcher={this.renderSwitcher()}
            onClassesListDefined={this.OnClassesListDefined}
          />
        )}
        {this.state.activeViewId === DetailsViewId && (
          <LaborDashboard
            isActive={this.props.isActive}
            switcher={this.renderSwitcher()}
            classesList={this.classesList}
          />
        )}
      </div>
    );
  }

  renderSwitcher = () => {
    return (
      <ButtonGroup className={styles.ButtonGroup}>
        {VIEWS.map((view) => {
          let viewId = view.Id;
          return (
            <Button
              key={viewId}
              id={viewId}
              themeColor={
                this.state.activeViewId === viewId ? "primary" : undefined
              }
              onClick={this.OnSwitchTab}
            >
              {view.Name}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  };

  OnClassesListDefined = (classesList: Array<IComboboxItem>) => {
    this.classesList = classesList;
  };

  OnSwitchTab = (e: any) => {
    let viewId: viewId = e.currentTarget.id;
    if (viewId === DetailsViewId) {
      let filtersByValues = window.localStorage.getItem("labor_ValuesFilter");
      if (filtersByValues !== null) {
        let filters = JSON.parse(filtersByValues);
        if (filters.CrewLeadId) {
          delete filters.CrewLeadId;
          window.localStorage.setItem(
            "labor_ValuesFilter",
            JSON.stringify(filters)
          );
        }
      }
    }
    this.ChangeView(viewId);
  };

  ChangeView = (viewId: viewId) => {
    this.initedViews[viewId] = true;
    this.setState({ activeViewId: viewId });
  };
}

export default CrewLeadSummary;
