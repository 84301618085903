import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { simpleObject } from "../../helpers/interfaces";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { IAdjustmentAllocation } from "../../Pages/TKReview/interfaces";
import { IListWOItem, IWOAllocationCardProps } from "./interfaces";
import CardManagement from "./CardManagement";
import formStyles from "./card.module.scss";
import React from "react";

interface state {
  sumPercentage: number;
  canSaveAllocation: boolean;
  invalidMessage: Array<string> | undefined;
  workOrders: Array<IListWOItem>;
  processing: boolean;
}

const MESSAGES = {
  invalidPercent: "The Total Percentage must be equal to 100%",
  invalidWOS: "Select Work Orders for all rows",
};

class WOAllocationCard extends BaseComponent<IWOAllocationCardProps, state> {
  allocation: Array<IAdjustmentAllocation> = JSON.parse(
    JSON.stringify(this.props.allocation)
  );
  formValid: boolean = true;
  workOrders: Array<IListWOItem>;

  constructor(props: IWOAllocationCardProps) {
    super(props);

    this.state = {
      sumPercentage: this.GetSumPercentage(),
      canSaveAllocation: true,
      invalidMessage: undefined,
      workOrders: [],
      processing: false,
    };
    this.workOrders = [];
    if (this.allocation.length === 0) this.AddAllocation();
  }

  componentDidMount() {
    this.LoadData();
  }

  render() {
    let message = this.state.invalidMessage;

    return (
      <FXCard
        title={this.props.title || "Allocation"}
        onClose={this.Close}
        initialWidth={600}
        initialHeight={350}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          <div style={{ flex: 1, overflow: "auto" }}>
            <div className={`${formStyles.Row} ${formStyles.HeadRow}`}>
              <div
                style={{
                  flex: 1,
                  paddingRight: 8,
                  alignItems: "center",
                }}
              >
                Work Order
              </div>
              <div
                style={{
                  width: "65px",
                  flex: "0 0 auto",
                  paddingLeft: 10,
                  boxSizing: "border-box",
                }}
              >
                <span>{this.state.sumPercentage}</span>
                <span> %</span>
              </div>
              <div style={{ width: 32, textAlign: "right" }}></div>
            </div>
            {this.allocation.map((item, i) => {
              let value = item.WorkOrderId
                ? {
                    Id: item.WorkOrderId,
                    Name: item.WorkOrderName /* , Code: item.WorkOrderNumber */,
                  }
                : undefined;
              return (
                <div className={formStyles.Row} key={item.WorkOrderId || i}>
                  <div style={{ flex: 1, paddingRight: 8 }}>
                    <FilterCombobox
                      value={value}
                      placeholder="Work Order"
                      data={this.state.workOrders}
                      onChange={this.OnChangeCombobox}
                      className={formStyles.FormField}
                      dataAttr={i}
                      loading={this.state.processing}
                      itemRender={this.renderWOListItem}
                    />
                  </div>
                  <div
                    style={{
                      width: "65px",
                      flex: "0 0 auto",
                    }}
                  >
                    <NumericTextBox
                      min={0}
                      max={100}
                      value={item.Percentage}
                      className={formStyles.FormField}
                      onChange={(e: NumericTextBoxChangeEvent) => {
                        this.OnPercentageChange(e.value || 0, i);
                      }}
                    />
                  </div>
                  <div style={{ width: 32, textAlign: "right" }}>
                    <Button
                      icon="minus"
                      fillMode="flat"
                      title="Delete"
                      style={{ marginBottom: 5 }}
                      onClick={() => this.RemoveAllocation(i)}
                    />
                  </div>
                </div>
              );
            })}
            <div className={formStyles.Row} key={"newRow"}>
              <Button
                icon="plus"
                fillMode="flat"
                onClick={this.AddAllocation}
                style={{ marginLeft: "auto" }}
              />
            </div>
          </div>
          <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <Button
              icon="plus"
              fillMode="flat"
              onClick={this.NewDispatch}
              style={{ marginRight: "auto" }}
            >
              New Dispatch
            </Button>
            <div style={{ flex: 1 }}>
              {!!message && (
                <span className={formStyles.InvalidMessage}>
                  {message.map((message, i) => {
                    return <span key={i}>{message}</span>;
                  })}
                </span>
              )}
            </div>
            <Button onClick={this.Close}>Cancel</Button>
            <Button
              onClick={this.SaveAllocation}
              themeColor="primary"
              disabled={!this.state.canSaveAllocation}
            >
              Save
            </Button>
          </div>
        </div>
      </FXCard>
    );
  }

  renderWOListItem = (
    el: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    props: any
  ) => {
    if (!props.dataItem.IsScheduled) {
      el.props.className =
        el.props.className + " " + formStyles.ComboboxGreyRow;
    }
    return el;
  };

  GetAvailableWorkOrders = () => {
    return this.workOrders.filter(
      (wo) =>
        this.allocation.findIndex(
          (allocation) => allocation.WorkOrderId === wo.Id
        ) === -1
    );
  };

  ValidateAllocation = () => {
    let sum = this.GetSumPercentage();
    let allWOSFilled =
      this.allocation.findIndex((item) => !item.WorkOrderId) === -1;
    let message = [];
    if (sum !== 100 && this.allocation.length)
      message.push(MESSAGES.invalidPercent);
    if (!allWOSFilled) message.push(MESSAGES.invalidWOS);
    this.formValid = !message.length;
    let workOrders = this.GetAvailableWorkOrders();
    this.setState({
      canSaveAllocation: !message.length,
      invalidMessage: message.length ? message : undefined,
      sumPercentage: sum,
      workOrders,
    });
  };

  GetSumPercentage = () => {
    return this.allocation.reduce((currentValue, item) => {
      return currentValue + item.Percentage;
    }, 0);
  };

  OnPercentageChange = (value: number, i: number) => {
    let item = this.allocation[i];
    if (item) {
      item.Percentage = value;
      if (this.allocation.length === 2) {
        let anotherItemIndex = i === 0 ? 1 : 0;
        let anotherItem = this.allocation[anotherItemIndex];
        anotherItem.Percentage = 100 - value;
      }
      this.ValidateAllocation();
    }
  };

  OnChangeCombobox = (value: simpleObject | null, i: number) => {
    let item = this.allocation[i];
    if (item) {
      item.WorkOrderId = value?.Id || 0;
      item.WorkOrderName = value?.Name || "";
      item.WorkOrderCode = value?.Code || "";
      item.WorkOrderNumber = value?.Number || "";
      this.ValidateAllocation();
    }
  };

  NewDispatch = () => {
    CardManagement.OpenDispatchCard({
      newDispatch: true,
      crewLeadId: this.props.tcInfo.EmployeeId,
      date: moment(this.props.tcInfo.Date).toDate(),
      afterSave: this.LoadData,
    });
  };

  SaveAllocation = async () => {
    try {
      await this.LoadData();
      this.props.onResult(
        this.allocation,
        this.props.allocation,
        this.workOrders
      );
      this.Close();
    } catch (e) {}
  };

  AddAllocation = () => {
    let workOrders = this.state.workOrders;
    let wo = workOrders.length === 1 ? workOrders[0] : undefined;
    let percentage =
      this.allocation.length === 0
        ? 100
        : this.allocation.length === 1
        ? 100 - this.allocation[0].Percentage
        : 0;
    let newAllocation = {
      Percentage: percentage,
      RowNumber: 0,
      WorkOrderCode: wo?.Code || "",
      WorkOrderId: wo ? +wo.Id : 0,
      WorkOrderName: wo?.Name || "",
      WorkOrderNumber: wo?.Number || "",
    };
    this.allocation.push(newAllocation);
    this.ValidateAllocation();
  };

  RemoveAllocation = (index: number) => {
    this.allocation.splice(index, 1);
    this.ValidateAllocation();
  };

  Close = (e?: any) => {
    this.props.onClose(this.workOrders);
    this.props.finally!();
  };

  LoadData = async () => {
    try {
      this.setState({ processing: true });
      const { tcId } = this.props;
      const [workOrders]: any = await this.GetSQLData({
        spName: "TK_GetAvailableWOs",
        params: { tcId },
      });
      this.workOrders = workOrders;
      this.setState({ workOrders: this.GetAvailableWorkOrders() });
    } finally {
      this.setState({ processing: false });
    }
  };
}

export default WOAllocationCard;
