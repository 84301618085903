import styles from "./timecard.module.scss";
import {simpleObject} from "../../../helpers/interfaces";
import DashboardGrid from "../../../Components/Dashboard/Grid";
import {IColumnSetting} from "../../../Components/Dashboard/interfaces";
import {useState} from "react";

const DETAILS_COLUMNS: IColumnSetting[] = [
    {
        field: "Start",
        title: "Start",
        type: "date",
        filterable: false,
        format: {
            type: "datetime",
        },
        gridWidth: 60
    },
    {
        field: "Finish",
        title: "Finish",
        type: "date",
        filterable: false,
        format: {
            type: "datetime",
        },
    gridWidth: 60
    },
    {
        field: "CostTypeName",
        title: "Cost Type",
        type: "string",
        filterable: false,
        format: {
            type: "string",
        },
        gridWidth: 65,
    },
    {
        field: "WOName",
        title: "WO",
        type: "string",
        filterable: false,
        format: {
            type: "link",
            fieldId: 'WOId',
            refName: 'FSMWorkOrders'
        },
        minWidth: 130,
    },
    {
        field: "StateName",
        title: "State",
        type: "string",
        filterable: false,
        format: {
            type: "link",
            fieldId: 'StateId',
            refName: 'States'
        },
        gridWidth: 40,
    },
    {
        field: "TotalHours",
        title: "Hours",
        type: "number",
        filterable: false,
        format: {
            type: "number",
        },
        aggregate: "sum",
        gridWidth: 50,
    },
    {
        field: "RegularHours",
        title: "Regular",
        type: "number",
        filterable: false,
        format: {
            type: "number",
        },
        aggregate: "sum",
        gridWidth: 50,
    },
    {
        field: "OvertimeHours",
        title: "Overtime",
        type: "number",
        filterable: false,
        format: {
            type: "number",
        },
        aggregate: "sum",
        gridWidth: 50,
    },
    {
        field: "DoubleHours",
        title: "Double",
        type: "number",
        filterable: false,
        format: {
            type: "number",
        },
        aggregate: "sum",
        gridWidth: 50,
    },
    {
        field: "LunchHours",
        title: "Lunch",
        type: "number",
        format: {
            type: "number",
        },
        aggregate: "sum",
        gridWidth: 50,
    },
    {
        field: 'IsPW',
        title: 'PW',
        type: 'string',
        filterable: false,
        format: {
            type: 'string',
            customFormat: true,
            getCustomFormat(dataItem: simpleObject, field: string): any {
                return dataItem[field] ? 'PW' : ''
            }
        },
        gridWidth: 50,
    },
    {
        field: 'Overnight',
        title: 'Overnight',
        filterable: false,
        type: 'string',
        gridWidth: 67,
    },
    {
        field: 'IsOTExclude',
        title: 'OT Exclude',
        type: 'string', // yes/no
        filterable: false,
        format: {
            type: 'string',
            customFormat: true,
            getCustomFormat(dataItem: simpleObject, field: string): any {
                if(dataItem[field] === null) return null
                return <div
                    style={{textAlign: 'center'}}>{dataItem[field] ? 'Yes' : 'No'}</div>
            }
        },
        gridWidth: 50
    },
    {
        field: 'IsLockedWageType',
        title: 'Locked Wage',
        type: 'string', // icon
        filterable: false,
        format: {
            type: 'icon',
            customFormat: true,
            getCustomFormat(dataItem: simpleObject, field: string): any {
                return dataItem[field] ?
                    <div style={{textAlign: 'center'}}><span
                        className={'mdi mdi-lock-outline'}></span></div> : ''
            }
        },
        gridWidth: 50
    },
]

export default function TCDetails(props: { tcId: number }) {
    const [isScrollGrid, setIsScrollGrid] = useState(false)
    return <DashboardGrid
        className={styles.TCDetailsGrid}
        containerClassName={isScrollGrid ? styles.TCDetailsScrollGridContainer : styles.TCDetailsGridContainer}
        isActive={true}
        isParentPage={true}
        columnsSettings={DETAILS_COLUMNS}
        getSqlParams={{
            spName: "DB_TimeCardTimeDetails",
            params: {tcId: props.tcId}
        }}
        excelFileName="TCDetails.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
        toolbar={{
            hide: true
        }}
        prepareData={(result: any) => {
            const data = result[0]
            setIsScrollGrid(data.length > 7)
            return data
        }}
    ></DashboardGrid>
}