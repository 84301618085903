import { Component } from "react";
import styles from "./tc.module.scss";
import { Chip } from "@progress/kendo-react-buttons";
import { formatHoursDuration } from "../../helpers/helpers";
import { ITotalDurationProps } from "../../Pages/TKReview/interfaces";

class TotalDurations extends Component<ITotalDurationProps> {
  render() {
    const { clocked, approved, lunch, className } = this.props;
    return (
      <div className={`${styles.TotalDurations} ${className}`}>
        {this.renderCell("Clocked", formatHoursDuration(clocked))}
        {this.renderCell("Approved", formatHoursDuration(approved), "success")}
        {this.renderCell(
          this.props.isOriginal ? "Audit Lunch" : "Lunch Deduction",
          lunch ? formatHoursDuration(lunch) : "",
          lunch ? "error" : undefined
        )}
        {this.renderCell(
          "Total",
          formatHoursDuration(approved - (lunch || 0)),
          "success"
        )}
      </div>
    );
  }

  renderCell = (title: string, text: string, type?: "success" | "error") => {
    return (
      <div className={styles.TotalDurationsCell}>
        <span className={styles.TotalDurationsName}>{title}</span>
        <Chip themeColor={type} text={text} />
      </div>
    );
  };
}

export default TotalDurations;
