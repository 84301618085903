import { Component } from "react";
import Adjustment from "./Adjustment";
import { IDrawAdjustedTE } from "../../../Pages/TKReview/interfaces";
import styles from "./adjustments.module.scss";

interface props {
  drawAdjusted: IDrawAdjustedTE[];

  refresh(): void;
}

interface state {}

class Adjustments extends Component<props, state> {
  render() {
    return <>{this.renderTimeLine()}</>;
  }

  renderTimeLine = () => {
    let timeLine = this.props.drawAdjusted;
    let timeLineHTML: Array<JSX.Element> = [];
    for (let i = 0; i < timeLine.length; i++) {
      let item = timeLine[i];
      const te = item.te;
      const nextItemStart = timeLine[i + 1]?.Start;

      if (item.Start) {
        timeLineHTML.push(
          <small key={item.Start + te?.RowNumber} className={styles.TETime}>
            {item.Start}
          </small>
        );
      }

      if (te) {
        timeLineHTML.push(
          <Adjustment
            key={te.RowNumber + te.Start}
            data={te}
            refresh={this.props.refresh}
            rowsLength={item.rows}
          />
        );
      } else {
        timeLineHTML.push(
          <div
            key={"noItems" + item.StartTimeIndex + item.FinishTimeIndex}
            style={{ height: 30 * item.rows }}
          ></div>
        );
      }

      if (item.Finish && item.Finish !== nextItemStart) {
        timeLineHTML.push(
          <small key={item.Finish + te?.RowNumber} className={styles.TETime}>
            {item.Finish}
          </small>
        );
      }
    }
    return timeLineHTML;
  };
}

export default Adjustments;
