import { Component } from "react";
import styles from "./relatedDocuments.module.scss";
import expandedPanelStyles from "../../Components/Common/ExpandedPanel/ExpandedPanel.module.scss";
import { IRelatedDocumentItem } from "./interfaces";
import { Card } from "@progress/kendo-react-layout";

interface props {
  data: IRelatedDocumentItem;

  onClick(item: IRelatedDocumentItem): void;
}

interface state {}

class RelatedDocumentsMobileListItem extends Component<props, state> {
  render() {
    let dataItem = this.props.data;
    return (
      <div onClick={this.OnClick} style={{ height: "100%" }}>
        <Card
          className={`${
            dataItem.Selected ? expandedPanelStyles.MobileListItemSelected : ""
          } ${expandedPanelStyles.MobileListItem}`}
        >
          <div className={styles.MobileSingleRowText}>
            {dataItem.DocumentName}
          </div>
          <div
            className={`${styles.MobileSingleRowText} ${styles.MobileAuthor}`}
          >
            {dataItem.AuthorName}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "green",
              }}
            >
              {dataItem.IsPublished && (
                <span style={{ marginRight: 8 }}>Published for Field</span>
              )}
            </div>
            <div
              style={{
                textAlign: "right",
                paddingLeft: 8,
              }}
            >
              Created: {dataItem.CreatedFormatted}
            </div>
          </div>
        </Card>
      </div>
    );
  }

  OnClick = (e: any) => {
    this.props.onClick(this.props.data);
  };

  ExpandChange = (event: any) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.setState({});
  };
}

export default RelatedDocumentsMobileListItem;
