import {getSQLData, ReferenceRecordsDataSource} from './queries'

/* export const GetReferenceRecordName = async (recordId: number) => {
  let result = await getSQLData({
    spName: 'DB_SelectRefRecords',
    params: { recordId }
  })
  return result[0] && result[0][0] && result[0][0].Name || 'No Name'
}

const LoadList = async (refName: string) => {
  let result = await ReferenceRecordsDataSource(refName)
  return result[0]
} */

export const LoadWarehouses = async () => {
    try {
        let result = await getSQLData({spName: 'GetWarehouseRecords'})
        return result[0]
    } catch {
    }
}

export const LoadMaterialCategories = async () => {
    let result = await ReferenceRecordsDataSource('MaterialCategories')
    return result[0]
}

export const LoadBuildPlans = async () => {
    let result = await ReferenceRecordsDataSource('FSMBuildPlans', {activeOnly: false})
    return result[0]
}

export const LoadMarkets = async () => {
    let result = await ReferenceRecordsDataSource('FSMMarkets', {activeOnly: true})
    return result[0]
}

export const LoadLocationTypes = async () => {
    let result = await ReferenceRecordsDataSource('LocationTypes', {activeOnly: true})
    return result[0]
}
export const LoadRegions = async () => {
    let result = await ReferenceRecordsDataSource('FSMRegions', {activeOnly: false})
    return result[0]
}

export const LoadCarriers = async () => {
    let result = await ReferenceRecordsDataSource('FSMCarriers', {activeOnly: true})
    return result[0]
}

/* export const LoadEmployees = async () => {
  let result = await ReferenceRecordsDataSource('Employees')
  return result[0]
}

export const LoadCxStatuses = async () => {
  let result = await ReferenceRecordsDataSource('FSMBPConstructionStatus')
  return result[0]
}

export const LoadPermitStatuses = async () => {
  let result = await ReferenceRecordsDataSource('FSMBPPermitStatus')
  return result[0]
} */
