import React, { useMemo, useRef } from "react";
import { simpleObject } from "../../helpers/interfaces";
import DashboardGrid from "../Dashboard/Grid";
import { tabId } from "../Tabs/interfaces";
import {
  gridChangeDataAction,
  IColumnFilterItem,
  IColumnSetting,
  IGridFilter,
} from "../Dashboard/interfaces";
import style from "../Dashboard/dashboard.module.scss";
import ButtonLink from "../Common/Buttons/ButtonLink";
import { pageId } from "../../Pages/Home/interfaces";
import moment from "moment/moment";

interface IProps {
  remountKey?: number;
  periodId?: number;
  periodName?: string;
  isActive: boolean;
  switchTab?: (
    tab: tabId,
    serverFilters?: simpleObject,
    columnFilters?: IColumnFilterItem[],
    complexGridFilters?: IGridFilter[]
  ) => void;
  onChangeGridData: (
    data: simpleObject[],
    action: gridChangeDataAction
  ) => void;
  doNotSaveFilters: boolean;
  pageId: pageId;
  startDate: string;
  endDate: string;
}

const PayrollStatus = React.forwardRef((props: IProps, ref: any) => {
  const { periodId, isActive, onChangeGridData, startDate, endDate } = props;
  const remountKeyPropsRef = useRef(props.remountKey);
  const onCellClick = (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    dataAttr?: any
  ) => {
    if (!periodId) return;
    const { field, dataItem } = dataAttr;
    const fieldToReviewStateIdParamValue: simpleObject = {
      Pending: "P",
      ChangeTime: "T",
      Acknowledged: "A",
      Adjusted: "D",
      Completed: "C",
    };
    const serverFilters: simpleObject = {
      reviewStateId: fieldToReviewStateIdParamValue[field] || field,
      periodId,
    };
    const columnValuesFilter = dataItem && [
      {
        field: "ReviewerId",
        values: [dataItem.ReviewerId],
      },
      {
        field: "ClassId",
        values: [dataItem.ClassId],
      },
    ];
    const complexGridFilters: IGridFilter[] = [
      {
        logic: "and",
        filters: [
          {
            field: "Date",
            operator: "gte",
            value: moment(startDate).toDate(),
          },
          {
            field: "Date",
            operator: "lte",
            value: moment(endDate).toDate(),
          },
        ],
      },
    ];
    if (props.switchTab)
      props.switchTab(
        "PPTimeCards",
        serverFilters,
        columnValuesFilter,
        complexGridFilters
      );
  };

  const renderLinkCell = (
    value: number,
    dataAttr: { dataItem?: simpleObject; field: string }
  ) => {
    return (
      <div style={{ textAlign: "center" }}>
        {value === 0 ? (
          <span>{value}</span>
        ) : (
          <ButtonLink
            text={value + ""}
            onClick={onCellClick}
            dataAttr={dataAttr}
          />
        )}
      </div>
    );
  };

  const renderPPReviewerCell = (
    dataItem: simpleObject,
    field: string
    // data?: simpleObject[]
  ) => renderLinkCell(dataItem[field], { field, dataItem });

  const renderPPTotalCell = (
    gridData: Array<simpleObject>,
    field: string,
    value: number
  ) => renderLinkCell(value, { field });

  const columns = useMemo<IColumnSetting[]>(() => {
    return [
      {
        title: "Reviewer",
        field: "ReviewerName",
        type: "string",
        gridWidth: 200,
        format: {
          type: "link",
          fieldId: "ReviewerId",
          refName: "Employees",
          notAssignedValueName: "Not Assigned",
        },
      },
      {
        field: "ClassName",
        title: "Employee Class",
        type: "string",
        format: {
          type: "link",
          refName: "Classes",
          fieldId: "ClassId",
        },
        gridWidth: 180,
      },
      {
        field: "PendingTARs",
        title: "Pending TARs",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "PendingTimeOff",
        title: "Pending Time Off",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "PendingMisc",
        title: "Pending Misc.",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "MissingCostType",
        title: "Missing Cost Type",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "MissingWOAllocation",
        title: "Missing WO Allocation",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "MissingPayrollItemAllocation",
        title: "Missing Payroll Item",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "HasMealPremium",
        title: "Has Meal Premium",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "HasPW",
        title: "Has PW",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "HasAdjustedTime",
        title: "Has Adjusted Time",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "Pending",
        title: "Pending",
        titleHTML: <span>Pending</span>,
        headerClassName: `${style.THWithArrow} ${style.THWithArrowThin} ${style.DarkBlue}`,
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "ChangeTime",
        title: "Change Time",
        titleHTML: <span>Change Time</span>,
        headerClassName: `${style.THWithArrow} ${style.THWithArrowThin} ${style.GreyPink}`,
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "Acknowledged",
        title: "Acknowledged",
        titleHTML: <span>Acknowledged</span>,
        headerClassName: `${style.THWithArrow} ${style.THWithArrowThin} ${style.Blue}`,
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "Adjusted",
        title: "Adjusted",
        titleHTML: <span>Adjusted</span>,
        headerClassName: `${style.THWithArrow} ${style.THWithArrowThin} ${style.GreenBlue}`,
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
      {
        field: "Completed",
        title: "Completed",
        titleHTML: <span>Completed</span>,
        headerClassName: `${style.THWithArrow} ${style.THWithArrowThin} ${style.Orange}`,
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: renderPPTotalCell,
        },
        gridWidth: 100,
        aggregate: "sum",
      },
    ];
  }, [startDate, periodId, endDate]);

  if (!periodId) return null;
  // if (!inited) return <LoaderComponent/>
  if (props.isActive) remountKeyPropsRef.current = props.remountKey;
  return (
    <DashboardGrid
      key={remountKeyPropsRef.current}
      ref={ref}
      isActive={isActive}
      isParentPage={false}
      columnsSettings={columns}
      getSqlParams={{
        spName: "TK_GetReviewSummary",
        params: {
          periodId,
          startDate,
          endDate,
        },
      }}
      excelFileName="Payroll Period Status.xlsx"
      getRowKey={(dataItem: simpleObject) => dataItem.ReviewerId}
      columnMenu={{
        filters: true,
        columns: true,
      }}
      reorderable={true}
      onChangeGridData={onChangeGridData}
    />
  );
});

export default PayrollStatus;
