import React, { Component } from "react";
/* import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"; */
import { Modal } from "./Components/Common/Modal/Modal";
import { CardsStack } from "./Components/Common/FXCard/Cards";
import Home from "./Pages/Home/Home";
import Auth from "./Components/Auth";
import { addParamsToUrl, getParamsFromUrl } from "./helpers/helpers";
import BPTabsPage from "./Pages/BPTabs";
import BPHistory from "./Components/BP/History";
import TKCostRates from "./Components/PP/CostRates";
import TKLaborCosts from "./Components/TC/LaborCosts";
import RelatedDocuments from "./Components/RelatedDocuments/RelatedDocuments";
import ObjectMap from "./Pages/ObjectMap/ObjectMap";
import ReportRecordUsage from "./Pages/ReportRecordUsage/ReportRecordUsage";
import DeviceLog from "./Pages/Logs/DeviceLogs";
import TKTCLogs from "./Pages/Logs/TKTCLogs";
import TimeCard from "./Pages/TKReview/TimeCard/TimeCard";

import TCTabs from "./Pages/TCTabs";
import CheckListResults from "./Pages/CLM/ChecklistResults/ChecklistResults";
import MobileChecklistResults from "./Pages/CLM/ChecklistResults/MobileChecklistResults";
import WebIframe from "./Components/WebIframe";
import { simpleObject } from "./helpers/interfaces";
import AuthData from "./helpers/authData";
import {
  currentObjectDataSet,
  currentObjectId,
  IntegrationWithDesktop,
  IntegrationWithDesktopContext,
  isMobile,
} from "./helpers/settings";
import moment from "moment";
import { IAddressInfo } from "./Pages/ObjectMap/interfaces";
import TCHistory from "./Components/TC/History";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

interface state {
  isIntegrationWithDesktop: boolean;
  toggleIntegrationWithDesktop: any;
}

const isIntegrationWithDesktop =
  localStorage.getItem(IntegrationWithDesktop) === "true";

class App extends Component<any, state> {
  params: simpleObject = getParamsFromUrl();
  toggleIntegrationWithDesktop: any;

  constructor(props: any) {
    super(props);
    this.toggleIntegrationWithDesktop = () => {
      this.setState((state: state) => {
        let value = !state.isIntegrationWithDesktop;
        localStorage.setItem(IntegrationWithDesktop, value.toString());
        return { isIntegrationWithDesktop: value };
      });
    };

    this.state = {
      isIntegrationWithDesktop: isIntegrationWithDesktop,
      toggleIntegrationWithDesktop: this.toggleIntegrationWithDesktop,
    };
  }

  render() {
    return (
      <IntegrationWithDesktopContext.Provider value={this.state}>
        <CardsStack />
        <Modal />
        {this.params.folder && this.renderPage()}
        {!this.params.folder && !this.params.buildPlanId && (
          <Home
            isIntegrationWithDesktop={this.state.isIntegrationWithDesktop}
          />
        )}
        {!this.params.folder && this.params.buildPlanId && (
          <BPTabsPage
            rowData={{ BPID: this.params.buildPlanId }}
            isActive={true}
            parentId="BPPage"
          />
        )}
        <Auth />
        {/* <HomeIframe /> */}
        {/* <Router>
        <Home>
          <Switch>
            <Route exact={true} path="/CrewLeadDashboard" component={CrewLeadDashboard}/>
            <Route exact={true} path="/DailyDashboard" component={DailyDashboard}/>
            <Route path="/LiveMap" component={LiveMap}/>
            {/* <Route path="/InventoryDashboard" component={InventoryDashboard}/> */}
        {/* <Route exact={true} path="/VIMDashboard" component={VIMDashboard}/>
          </Switch>
        </Home> */}
        {/* <Auth /> */}
        {/* </Router>  */}
      </IntegrationWithDesktopContext.Provider>
    );
  }

  renderPage = () => {
    let params = this.params;
    let paramsString = addParamsToUrl(this.params, "");
    if (params.folder === "BPHistory") {
      return <BPHistory isPage={true} />;
    } else if (params.folder === "RecordUsage") {
      this.AutoAuth();
      return <ReportRecordUsage recordId={params.recordId} />;
    } else if (params.folder === "DeviceLog") {
      this.AutoAuth();
      let deviceId = currentObjectId ? +currentObjectId : params.deviceId;
      if (deviceId)
        return (
          <DeviceLog
            deviceId={+deviceId}
            date={params.date && moment(params.date).toDate()}
            isModalCard={false}
          />
        );
    } else if (params.folder === "TKTimeCardLog") {
      this.AutoAuth();
      let tcId = currentObjectId ? +currentObjectId : params.tcId;
      if (tcId) return <TKTCLogs tcId={+tcId} isModalCard={false} />;
    } else if (params.folder === "ObjectMap") {
      const onChange = (AddressInfo: IAddressInfo) => {
        currentObjectDataSet!.Requisites("StringTMP").Value =
          JSON.stringify(AddressInfo);
      };
      return (
        <ErrorBoundary>
          <ObjectMap addressId={params.addressId} onChange={onChange} />
        </ErrorBoundary>
      );
    } else if (params.folder === "TKTimeCard") {
      return (
        <TimeCard tcId={currentObjectId ? +currentObjectId : +params.tcId} />
      );
    } else if (params.folder === "TKTimeCardHistory") {
      return (
        <TCHistory
          tcId={
            currentObjectId ? +currentObjectId : +params.tcId
          } /* isModalCard={true} */
        />
      );
    } else if (params.folder === "TKTimeCardTabs") {
      return (
        <TCTabs
          tcId={
            currentObjectId ? +currentObjectId : +params.tcId
          } /* isModalCard={true} */
        />
      );
    } else if (params.folder === "Documents") {
      return (
        <RelatedDocuments
          isActive={true}
          SourceId={params.SourceId}
          isMobile={isMobile}
        />
      );
    } else if (params.folder === "CLPMReview") {
      let Component = isMobile ? MobileChecklistResults : CheckListResults;
      return (
        <Component
          isActive={true}
          isCLM={true}
          pageId={"CLPM"}
          buildPlanId={params.bpId}
        />
      );
    } else if (params.folder === "TKCostRates") {
      this.AutoAuth();
      return <TKCostRates isActive={true} urlParams={params} />;
    } else if (params.folder === "TKLaborCost") {
      this.AutoAuth();
      return <TKLaborCosts isActive={true} urlParams={params} />;
    } else {
      return (
        <WebIframe
          urlParams={paramsString}
          folder={params.folder}
          id={params.folder === "TKAuditCover" ? "TKAuditCoverFrame" : ""}
        />
      );
    }
  };

  AutoAuth = () => {
    let params = this.params;
    if (params.accessToken) {
      let tokens = {
        access_token: params.accessToken,
        expires_in: params.expiresIn,
        refresh_token: params.refreshToken,
        token_type: params.tokenType,
      };
      let url = window.location.href;
      url = AuthData.removeParamFromURL("accessToken", url);
      url = AuthData.removeParamFromURL("expiresIn", url);
      url = AuthData.removeParamFromURL("refreshToken", url);
      url = AuthData.removeParamFromURL("tokenType", url);
      window.history.pushState({}, document.title, url);
      AuthData.storeTokens(tokens);
    } else if (params.refreshToken) {
      AuthData.restoreSavedTokens();
    }
  };
}

export default App;
