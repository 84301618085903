import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import BaseComponent from "../../Components/BaseComponent";
import Loader from "../../Components/Common/Loader";
import ClearableInput from "../../Components/Common/Form/ClearableInput";
import { GridRowHeight } from "../../Components/Dashboard/helpers";
import ComboboxFilterVirtual from "../../Components/Dashboard/ComboboxFilterVirtual";
import {
  IComboboxItem,
  ISortGridItem,
  simpleObject,
} from "../../helpers/interfaces";
import commonStyles from "../../assets/styles/common.module.scss";
import DocumentViewer from "../../Components/Common/DocumentViewer/DocumentViewer";
import { DocumentExtentions } from "../../Components/Common/DocumentViewer/interfaces";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { IGridFilter } from "../../Components/Dashboard/interfaces";
import styles from "./clpm.module.scss";
import { SplitPane } from "react-multi-split-pane";
import { GetCheckListSampleUrl } from "./helpers";
import { DOCS_EXT } from "../../Components/Common/DocumentViewer/helpers";

type filterableColumns = "cliDesc" | "cliName";

interface ICLII {
  clName: string;
  cliDesc: string;
  cliName: string;
  hasSample: boolean;
  id: number;
  index: number;
  sampleExt: DocumentExtentions | null;
  selected?: boolean;
}

interface props {
  isActive: boolean;
  doNotSaveFilters?: boolean;
}

interface state {
  scenariosloading: boolean;
  loading: boolean;
  checklists: { data: Array<ICLII>; total: number };
  previewDocument: IDocument | null;
  scenarios: Array<IComboboxItem>;
  remountKey: number;
}

interface IDocument {
  Id: number;
  Download: string;
  Preview: string;
  Extension: DocumentExtentions;
}

const localStorageFilterName = "CLM_checklist_scenario";

class CLMChecklists extends BaseComponent<props, state> {
  scenario: IComboboxItem | undefined;
  checklists: Array<ICLII> = [];
  selected: ICLII | undefined;
  group: Array<{ field: string; dir?: "asc" | "desc" }> = [
    {
      field: "clName",
      dir: "asc",
    },
  ];
  sort: Array<ISortGridItem> = [];
  filter: {
    [key in filterableColumns]?: string;
  } = {};
  gridFilter: IGridFilter = { logic: "and", filters: [] };
  filterChangeTimeout: any;

  constructor(props: any) {
    super(props);
    this.state = {
      scenariosloading: false,
      scenarios: [],
      loading: false,
      checklists: { data: [], total: 0 },
      previewDocument: null,
      remountKey: +new Date(),
    };
  }

  componentDidMount() {
    this.LoadScenarios();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
  }

  render() {
    if (this.state.loading) return <Loader />;
    let document = this.state.previewDocument;
    let selected = this.selected;
    return (
      <SplitPane split="vertical" defaultSizes={[1, 1]} minSize={[400, 400]}>
        <Grid
          style={{ height: "100%", width: "100%" }}
          data={this.state.checklists}
          groupable={false}
          group={this.group}
          onExpandChange={this.ExpandChange}
          expandField="expanded"
          selectedField="selected"
          onRowClick={this.OnSelectRow}
          sortable={true}
          sort={this.sort}
          onSortChange={this.OnSort}
          rowHeight={GridRowHeight}
          filterable={true}
          filterCellRender={this.renderFilterCell}
        >
          <GridToolbar>
            <ComboboxFilterVirtual
              key={this.state.scenariosloading + "scs"}
              data={this.state.scenarios}
              loading={this.state.scenariosloading}
              onChange={this.OnChangeScenario}
              filter={{
                id: "buildPlans",
                placeholder: "Filter by Scenario",
                type: "combobox",
                width: 350,
              }}
              defaultValue={this.scenario}
              // required={true}
            />
            <ToolbarSpacer />
            <Button icon="refresh" onClick={this.Refresh} />
          </GridToolbar>
          <Column field="index" title="#" width={40} filterable={false} />
          <Column field="cliName" title="Item" />
          <Column field="cliDesc" title="Description" />
          <Column
            field="hasSample"
            title="Sample"
            width={75}
            cell={(props) => {
              if (props.rowType === "groupHeader") return null;
              return (
                <td className={commonStyles.TextCenter}>
                  {props.dataItem.hasSample && (
                    <span className="k-icon k-i-image"></span>
                  )}
                </td>
              );
            }}
            filterable={false}
          />
        </Grid>
        <div className={styles.RightPanel}>
          <Toolbar className={styles.Toolbar}>
            <ToolbarSpacer />
            {document?.Download && (
              <Button
                title="Download Document"
                icon="download"
                onClick={this.DownloadDocument}
                fillMode="flat"
              />
            )}
            {document?.Preview && (
              <a
                className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                href={document.Preview}
                target="__blank"
                title="Open Document in New Tab"
              >
                <span className="k-icon k-i-hyperlink-open"></span>
              </a>
            )}
          </Toolbar>

          <DocumentViewer
            containerId="clm-checklist"
            document={
              document
                ? {
                    id: document.Id,
                    extension: document.Extension,
                    previewUrl: document.Preview,
                  }
                : undefined
            }
            documentLoading={false}
          />
          {selected && !document && (
            <img
              className={styles.NoPhoto}
              src="/tools/viewer/noPhoto.png"
              alt="No Photo"
            ></img>
          )}
        </div>
      </SplitPane>
    );
  }

  renderFilterCell = (el: any, props: simpleObject) => {
    let field: filterableColumns = props.field;
    return (
      <ClearableInput
        key={field + this.state.remountKey}
        dataprops={field}
        defaultValue={this.filter[field] || ""}
        onChange={this.OnChangeFilter}
        clear={this.ClearFilter}
      />
    );
  };

  OnChangeFilter = (e?: InputChangeEvent, field?: filterableColumns) => {
    if (!field) return;

    let value = e?.value || "";
    if (value) this.filter[field] = value;
    else delete this.filter[field];

    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(this.ChangeGridFilter, 1000);
  };

  ChangeGridFilter = () => {
    this.gridFilter = {
      logic: "and",
      filters: [],
    };
    if (this.filter.cliDesc) {
      this.gridFilter.filters.push({
        field: "cliDesc",
        operator: "contains",
        value: this.filter.cliDesc,
      });
    }
    if (this.filter.cliName) {
      this.gridFilter.filters.push({
        field: "cliName",
        operator: "contains",
        value: this.filter.cliName,
      });
    }
    this.SetChecklists();
  };

  ClearFilter = (field: filterableColumns) => {
    if (!field) return;
    delete this.filter[field];
    this.ChangeGridFilter();
  };

  DownloadDocument = async () => {
    let document = this.state.previewDocument;
    if (document && document.Download) {
      window.location.href = document.Download;
    }
  };

  LoadData = async () => {
    this.setState({ loading: true, previewDocument: null });
    this.selected = undefined;
    try {
      let result: any = await this.GetSQLData({
        spName: "CLPM_GetChecklists",
        params: { ScenarioID: this.scenario?.Id || 0 },
      });
      this.checklists = result[0];
      this.SetChecklists();
    } finally {
      this.setState({ loading: false });
    }
  };

  LoadScenarios = async () => {
    this.setState({ scenariosloading: true });
    try {
      let result: any = await this.GetSQLData({ spName: "CLPM_GetScenarios" });
      let scenarios = result[0].map((item: simpleObject) => ({
        Id: item.id,
        Name: item.label,
      }));
      let savedScenario = localStorage.getItem(localStorageFilterName);
      if (savedScenario) {
        let scenario = JSON.parse(savedScenario);
        this.scenario = scenarios.find(
          (item: IComboboxItem) => item.Id === scenario.Id
        );
      }
      this.setState({ scenarios });
      this.LoadData();
    } finally {
      this.setState({ scenariosloading: false });
    }
  };

  OnChangeScenario = (value: IComboboxItem | null) => {
    if (value?.Id !== this.scenario?.Id) {
      this.scenario = value || undefined;
      if (value) {
        localStorage.setItem(localStorageFilterName, JSON.stringify(value));
      } else {
        localStorage.removeItem(localStorageFilterName);
      }
      this.LoadData();
    }
  };

  OnSort = (e: any) => {
    this.sort = e.sort;
    this.group[0].dir = e.sort[0]?.dir;
    this.SetChecklists();
  };

  OnSelectRow = async (e: any) => {
    let dataItem: ICLII = e.dataItem;
    if (this.selected) this.selected.selected = false;
    dataItem.selected = true;
    this.selected = dataItem;
    let previewDocument: IDocument | null = null;

    if (dataItem.hasSample && dataItem.sampleExt) {
      let previewUrl = GetCheckListSampleUrl(dataItem.id, true);
      if (DOCS_EXT.indexOf(dataItem.sampleExt) > -1)
        previewUrl =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          encodeURIComponent(previewUrl);
      previewDocument = {
        Id: dataItem.id,
        Download: GetCheckListSampleUrl(dataItem.id, false),
        Preview: previewUrl,
        Extension: dataItem.sampleExt,
      };
    }
    this.setState({ previewDocument: previewDocument });
  };

  SetChecklists = () => {
    let checklists = process(this.checklists, {
      group: this.group,
      sort: this.sort,
      filter: this.gridFilter,
    });
    this.setState({ checklists });
  };

  ExpandChange = (event: any) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.setState({});
  };

  Refresh = () => {
    this.LoadData();
  };
}

export default CLMChecklists;
