import FXCard from "../Common/FXCard/FXCard";
import { ITCReasonCardProps } from "./interfaces";
import formStyles from "./card.module.scss";
import BaseComponent from "../BaseComponent";
import { Button } from "@progress/kendo-react-buttons";
import FilterCombobox from "../Common/Form/FilterCombobox";
import Loader from "../Common/Loader";
import { IComboboxItem } from "../../helpers/interfaces";
import { RunScriptAsync } from "../../helpers/runscripts";
import React from "react";
import { ReferenceRecordsDataSource } from "../../helpers/queries";

interface state {
  processing: boolean;
  remountKey: number;
  isLoading: boolean;
  reasons: IComboboxItem[];
  reason: IComboboxItem | null;
}

class TCReasonCard extends BaseComponent<ITCReasonCardProps, state> {
  constructor(props: ITCReasonCardProps) {
    super(props);
    this.state = {
      isLoading: false,
      reasons: [],
      reason: props.reason || null,
      processing: false,
      remountKey: +new Date(),
    };
  }

  componentDidMount() {
    this.LoadReasons();
  }

  render() {
    return (
      <FXCard
        title={"Reason"}
        onClose={this.Close}
        initialWidth={350}
        initialHeight={130}
        originalPaddings={false}
      >
        <>
          <div style={{ padding: 8 }}>
            {this.state.processing && <Loader />}
            <FilterCombobox
              key={this.state.remountKey}
              placeholder="Select Reason"
              data={this.state.reasons}
              onChange={this.OnChangeReason}
              defaultValue={this.props.reason}
              loading={this.state.isLoading}
            />
            {this.renderFooter()}
          </div>
        </>
      </FXCard>
    );
  }

  renderFooter = () => {
    const stateReason = this.state.reason || null;
    const propsReason = this.props.reason || null;
    let wasChanged = false;
    if (!stateReason && !propsReason) wasChanged = false;
    else if ((!!stateReason && !propsReason) || (!stateReason && !!propsReason))
      wasChanged = true;
    else if (stateReason?.Id !== propsReason?.Id) wasChanged = true;

    return (
      <div className={`${formStyles.FormFooter} k-action-buttons`}>
        <Button onClick={this.Close}>Cancel</Button>
        <Button
          onClick={this.UpsertReason}
          themeColor="primary"
          disabled={
            this.state.processing || this.state.isLoading || !wasChanged
          }
        >
          Save
        </Button>
      </div>
    );
  };

  LoadReasons = async () => {
    try {
      this.setState({ isLoading: true });
      let result = await ReferenceRecordsDataSource("TKTimeCardReasons");
      this.setState({ reasons: result[0] as IComboboxItem[] });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  OnChangeReason = (value: IComboboxItem | null) => {
    this.setState({ reason: value });
  };

  UpsertReason = async () => {
    try {
      this.setState({ processing: true });
      await RunScriptAsync("TKTimeCards_UpsertReason", {
        TCID: this.props.tcId,
        ReasonID: this.state.reason?.Id || null,
      });
      if (this.props.onFinish) this.props.onFinish();
      this.Close();
    } finally {
      this.setState({ processing: false });
    }
  };

  Close = () => {
    this.props.finally!();
  };
}

export default TCReasonCard
