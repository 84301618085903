import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { Component } from "react";
import OpenCardLink from "../../Common/Buttons/OpenCardLink";
import { IAdjustedTimeLineItem } from "../../../Pages/TKReview/interfaces";
import styles from "./adjustments.module.scss";

interface props {
  data: IAdjustedTimeLineItem;
  rowsLength: number;

  refresh(): void;
}

interface state {}

class Adjustment extends Component<props, state> {
  tooltipRef: any = React.createRef();

  render() {
    let data = this.props.data;
    let isLunch = data.CostTypeCode === "LUNCH";
    return (
      <div
        title={"Adjustment"}
        className={`TE ${styles.TE}`}
        style={{ height: 30 * this.props.rowsLength }}
        onMouseOver={(event) => {
          //@ts-ignore
          let classList = event.target.classList[0];
          if (
            this.tooltipRef?.current?.handleMouseOver &&
            (!classList || classList.indexOf("daylog_PhotoIcon") === -1)
          ) {
            this.tooltipRef.current &&
              this.tooltipRef.current.handleMouseOver(event);
          }
        }}
        onMouseOut={(event) => {
          this.tooltipRef.current &&
            this.tooltipRef.current.handleMouseOut(event);
        }}
      >
        <div className={styles.TESpace}></div>
        <span
          title="Open Time Entry"
          className={`${styles.TECostType} ${
            !isLunch && !data.ApprovedDuration ? styles.thin : ""
          }`}
          style={{ color: data.Color }}
        ></span>
        <div className={styles.TEContent}>
          <div className={styles.TEText}>
            <span className={styles.TEDuration}>
              {data.ApprovedDurationString}
            </span>
            {this.renderAllocation(data)}
          </div>
        </div>
        <Tooltip
          ref={this.tooltipRef}
          anchorElement="target"
          position="top"
          content={this.renderTETooltip}
          parentTitle={true}
          openDelay={400}
          tooltipStyle={{ marginBottom: "-10px" }}
        ></Tooltip>
      </div>
    );
  }

  renderAllocation = (data: IAdjustedTimeLineItem) => {
    let isLunch = data.CostTypeCode === "LUNCH";
    if (isLunch) {
      return (
        <span>
          {data.CostTypeCode} ({data.ActualDurationString})
        </span>
      );
    } else if (data.ManualAllocation.length === 1) {
      let WO = data.ManualAllocation[0];
      return (
        <div className={styles.WOAllocationBox}>
          <OpenCardLink
            className={styles.WOLinkNumber}
            text={WO.WorkOrderNumber}
            refName="FSMWorkOrders"
            dataAttr={WO.WorkOrderId}
          />
          <OpenCardLink
            className={styles.WOLinkName}
            text={WO.WorkOrderName}
            refName="FSMWorkOrders"
            dataAttr={WO.WorkOrderId}
          />
        </div>
      );
    } else if (data.ManualAllocation.length > 1) {
      return <span>Multiple Work Orders ({data.ManualAllocation.length})</span>;
    } else {
      return "";
    }
  };

  renderTETooltip = () => {
    let data = this.props.data;
    if (data === undefined) return null;
    return (
      <div className={`${styles.TEDetailsContent} tool`}>
        <div>
          {data.TimeStart}&nbsp;-&nbsp;{data.TimeFinish}
        </div>
        <div>Cost Type: {data.CostTypeName || ""}</div>
        <div>Duration: {data.ActualDurationString}</div>
        <div>Approved: {data.ApprovedDurationString}</div>

        {!!data.ManualAllocation.length && (
          <>
            <br />
            <div>Allocation:</div>
            <div className={styles.TEDetails}>
              {data.ManualAllocation.map((item) => {
                return (
                  <div
                    key={`${item.RowNumber}_${item.WorkOrderId}`}
                    className={styles.TEDetailsRow}
                  >
                    <span>{item.Percentage} % -&nbsp;</span>
                    <OpenCardLink
                      text={item.WorkOrderName}
                      dataAttr={item.WorkOrderId}
                      refName="FSMWorkOrders"
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };
}

export default Adjustment;
