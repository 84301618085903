import { CardsStackRef } from "../Common/FXCard/Cards";
// import SelectBPCard from './SelectBPCard';
import { IResource } from "../../Pages/CLM/interfaces";
import FXCard from "../Common/FXCard/FXCard";
import InventoryBalance from "../Dashboards/InventoryBalance";
import { IInitialTabSettings, tabId } from "../Tabs/interfaces";
import AddCustomerCard from "./AddCustomerCard";
import AddCustomerJobCard from "./AddCustomerJobCard";
import AddTCCard from "./AddTCCard";
import AddWOCard from "./AddWOCard";
import BPCard from "./BPCard";
import BPHistoryChartCard from "./BPHistoryChartCard";
import CLMResourceCard from "./CLMResourceCard";
import ChangeDocumentKindCard from "./ChangeDocumentKindCard";
import DispatchCard from "./DispatchCard";
import EasyBudgetCard from "./EasyBudgetCard";
import GBPCard from "./GBPCard/GBPCard";
import ClmReview from "./ModalClmReview";
import ModalDeviceLogs from "./ModalDeviceLogs";
import TCHistoryCard from "./ModalTCHistory";
import TKLogsCard from "./ModalTKLogs";
import TCMapCard from "./ModalTCMap";
import RecordHistory from "./RecordHistory";
import SiteCard from "./SiteCard";
import UpdateBudgetProgressCard from "./UpdateBudgetProgressCard";
import UploadDocumentsCard from "./UploadDocumentsCard";
import WOAllocationCard from "./WoAllocationCard";
import {
  IAddCustomerCardProps,
  IAddCustomerJobCardProps,
  IAddTCCardProps,
  IAddWOChecklistCardProps,
  IGBPCardProps,
  ILocationCardProps,
  ISiteCardProps,
  ITCReasonCardProps,
  IWOAllocationCardProps,
  StartCheckClosureCardProps,
} from "./interfaces";
import SolicitRequestCard from "./SoliciteRequestCard";
import MilestoneHistory from "./MilestoneHistory";
import LocationCard from "./LocationCard";
import TCReasonCard from "./TCReasonCard";
import TCCard from "./TCCard";
import PayrollCheckStatusCard from "./PayrollCheckStatusCard";
import { simpleObject } from "../../helpers/interfaces";
import TCRequestsActionsCard from "./TCRequestsActionsCard";
import { IRequestAllocation } from "../TC/interfaces";
import StartCheckClosureCard from "./StartCheckClosureCard";
import AddWOChecklistCard from "./AddWOChecklistCard";

class CardManagement {
  NewWO(buildPlanId?: number, onFinish?: any) {
    CardsStackRef.showCard(AddWOCard, { buildPlanId, afterSave: onFinish });
  }

  OpenDispatchCard(params: {
    newDispatch: boolean;
    buildPlanId?: number;
    woId?: number;
    dsId?: number;
    crewLeadId?: number;
    date?: Date;
    afterSave?: any;
  }) {
    CardsStackRef.showCard(DispatchCard, params);
  }

  OpenWOChecklistCard(params: IAddWOChecklistCardProps) {
    CardsStackRef.showCard(AddWOChecklistCard, params);
  }

  OpenSolicitTKRequestCard(params: {
    employeeId?: number;
    date?: Date;
    afterSave?: any;
  }) {
    CardsStackRef.showCard(SolicitRequestCard, params);
  }

  /* async SelectBp() {
        let id = 'selectBp'
        try {
          let pr = new Deferred()
          BPCardsStackRef.showCard(<SelectBPCard
            onResult={pr.resolve}
            onClose={pr.resolve}
          />, id)
          return await pr.promise
        } finally {
          BPCardsStackRef.hideCard(id)
        }
      } */

  ShowMaterialBalance(materialId: number, materialName: string) {
    let balanceCard = function (props: any) {
      return (
        <FXCard title={"Balance for " + materialName} onClose={props.finally}>
          <InventoryBalance {...props} isModalCard={true} />
        </FXCard>
      );
    };
    CardsStackRef.showCard(balanceCard, { materialId, isActive: true });
  }

  OpenBPCard(
    buildPlanId: number,
    onFinish?: any,
    initialTab?: tabId | IInitialTabSettings
  ) {
    CardsStackRef.showCard(BPCard, {
      Id: buildPlanId,
      finally: onFinish,
      initialTab,
    });
  }

  OpenTCCard(tcId: number, onFinish?: any) {
    CardsStackRef.showCard(TCCard, {
      Id: tcId,
      finally: onFinish,
    });
  }

  OpenClmReviewInterface(buildPlanId: number, workOrderId?: number) {
    CardsStackRef.showCard(ClmReview, {
      buildPlanId,
      workOrderId: workOrderId ? +workOrderId : undefined,
    });
  }

  OpenRecordHistoryCard(id: any, title?: string) {
    CardsStackRef.showCard(RecordHistory, {
      id,
      title,
    });
  }

  OpenMilestoneHistoryCard(bpid: number, msid: number, title?: string) {
    CardsStackRef.showCard(MilestoneHistory, {
      bpid,
      msid,
      title,
    });
  }

  OpenDeviceLogsCard(deviceId: number, date?: Date) {
    CardsStackRef.showCard(ModalDeviceLogs, { deviceId, date });
  }

  OpenTKLogsCard(tcId: number) {
    CardsStackRef.showCard(TKLogsCard, { tcId });
  }

  OpenTCMapCard(
    tcId: number,
    adjustmentsPanelOpened: boolean,
    tcRefresh?: () => void
  ) {
    CardsStackRef.showCard(TCMapCard, {
      tcId,
      adjustmentsPanelOpened,
      tcRefresh,
    });
  }

  OpenTCHistoryCard(tcId: number) {
    CardsStackRef.showCard(TCHistoryCard, { tcId });
  }

  OpenResourceCard(record?: IResource, onFinish?: any) {
    CardsStackRef.showCard(CLMResourceCard, { record, finally: onFinish });
  }

  OpenBPHistoryChartCard(
    buildPlanId: number,
    buildPlanName: string,
    onFinish?: any
  ) {
    CardsStackRef.showCard(BPHistoryChartCard, {
      buildPlanId,
      buildPlanName,
      finally: onFinish,
    });
  }

  WOAllocationCard(cardProps: IWOAllocationCardProps) {
    CardsStackRef.showCard(WOAllocationCard, cardProps);
  }

  OpenStartCheckClosureCard(cardProps: StartCheckClosureCardProps) {
    CardsStackRef.showCard(StartCheckClosureCard, cardProps);
  }

  OpenGBPCard(cardProps?: IGBPCardProps) {
    CardsStackRef.showCard(GBPCard, cardProps || {});
  }

  OpenTCReasonCard(cardProps: ITCReasonCardProps) {
    CardsStackRef.showCard(TCReasonCard, cardProps);
  }

  OpenSiteCard(cardProps: ISiteCardProps) {
    CardsStackRef.showCard(SiteCard, cardProps);
  }

  OpenLocationCard(cardProps: ILocationCardProps) {
    CardsStackRef.showCard(LocationCard, cardProps);
  }

  OpenAddCustomerCard(cardProps: IAddCustomerCardProps) {
    CardsStackRef.showCard(AddCustomerCard, cardProps);
  }

  OpenAddCustomerJobCard(cardProps: IAddCustomerJobCardProps) {
    CardsStackRef.showCard(AddCustomerJobCard, cardProps);
  }

  OpenAddTCCard(cardProps: IAddTCCardProps) {
    CardsStackRef.showCard(AddTCCard, cardProps);
  }

  UploadDocumentsCard(
    isBuildPlanSourceObjectId: boolean,
    sourceId?: number,
    onFinish?: any,
    documentId?: number
  ) {
    CardsStackRef.showCard(UploadDocumentsCard, {
      sourceId,
      afterSave: onFinish,
      documentId,
      isBuildPlanSourceObjectId,
    });
  }

  OpenChangeKindCard(
    documentId: number,
    kindId: number | null,
    onFinish?: any
  ) {
    CardsStackRef.showCard(ChangeDocumentKindCard, {
      onFinish,
      documentId,
      kindId,
    });
  }

  OpenPayrollCheckStatusCard(
    rowData: simpleObject,
    refresh: () => void,
    onSetDate: (
      checkId: number,
      refreshCardFunc: (rowData: simpleObject) => void
    ) => void
  ) {
    CardsStackRef.showCard(PayrollCheckStatusCard, {
      rowData,
      refresh,
      onSetDate,
    });
  }

  OpenTKRequestsActionsCard(
    rowData: simpleObject,
    allocation: IRequestAllocation[],
    refresh: () => void
  ) {
    CardsStackRef.showCard(TCRequestsActionsCard, {
      rowData,
      allocation,
      refresh,
    });
  }

  UpdateBudgetProgressCard(
    buildPlanId: number,
    currentProgress: number,
    afterSave: () => void
  ) {
    CardsStackRef.showCard(UpdateBudgetProgressCard, {
      buildPlanId,
      currentProgress,
      afterSave,
    });
  }

  EasyBudgetCard(buildPlanId: number, afterSave: () => void) {
    CardsStackRef.showCard(EasyBudgetCard, {
      buildPlanId,
      afterSave,
    });
  }
}

export default new CardManagement();
