import React, { useCallback, useEffect } from "react";
import { Popover } from "@progress/kendo-react-tooltip";
import styles from "./timecard.module.scss";
import {IDefaultWO} from "./interfaces";
import {useBooleanState} from "../../../helpers/hooks";
import OpenCardLink from "../../../Components/Common/Buttons/OpenCardLink";

export const MultipleWo = (props: { workOrders: IDefaultWO[]}) => {
  const { workOrders } = props;
  const isShownPopover = useBooleanState(false);
  const anchor = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShownPopover.value) {
      document.addEventListener("click", isShownPopover.setFalse);
    } else {
      document.removeEventListener("click", isShownPopover.setFalse);
    }
    return () => {
      document.removeEventListener("click", isShownPopover.setFalse);
    };
  }, [isShownPopover.value, isShownPopover]);

  const onClickChip = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      isShownPopover.toggle();
    },
    [isShownPopover.toggle]
  );

  const renderPopover = () => {
    return (
      <Popover
        show={isShownPopover.value}
        anchor={anchor.current}
        callout={false}
        position={"bottom"}
      >
        <div style={{ width: 330, overflow: "auto", maxHeight: 400 }}>
          {workOrders.map(({ DefaultWOName, DefaultWOId, Percentage }) => (
            <div key={DefaultWOId} className={styles.WORow}>
              <div>
                <OpenCardLink
                  text={DefaultWOName}
                  refName={"FSMWorkOrders"}
                  dataAttr={DefaultWOId}
                />
              </div>
              <div>{Percentage}%</div>
            </div>
          ))}
        </div>
      </Popover>
    );
  };

  return (
    <>
      <div ref={anchor} onClick={onClickChip} className={styles.MultipleWOBtn}>
        Multiple
      </div>
      {renderPopover()}
    </>
  );
};
