import AuthData from "../helpers/authData";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import "../assets/styles/Auth.scss";
import Logo from "../assets/img/Fieldclix-Logo.svg";
import { ModalRef } from "./Common/Modal/Modal";
import BaseComponent from "./BaseComponent";
import { Deferred } from "../helpers/deffered";
import React from "react";

interface state {
  isLoginValid: boolean;
  login: string;
  isPasswordValid: boolean;
  password: string;
  errorMessage: string;
  showForm: boolean;
}

export class AuthRef {
  static ref: any;
  static initializedRef = new Deferred();
  static credentialsResolve: any;

  static setRef(ref: any) {
    this.ref = ref;
    this.initializedRef.resolve();
  }

  static async checkRefInit() {
    if (this.ref === undefined) await this.initializedRef;
  }

  static async toggleForm(show: boolean) {
    await this.checkRefInit();
    this.ref.toggleForm(show);
  }

  static async askCredentials() {
    await this.checkRefInit();
    this.ref.toggleForm(true);
    let result = await new Promise((resolve) => {
      this.credentialsResolve = resolve;
    });
    this.ref.toggleForm(false);
    return result;
  }
}

class Auth extends BaseComponent<{}, state> {
  autofocusInput: any;
  input: React.RefObject<Input> = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      isLoginValid: true,
      login: "",
      isPasswordValid: true,
      password: "",
      errorMessage: "",
      showForm: false,
    };
  }

  componentDidMount() {
    AuthRef.setRef(this);
  }

  render() {
    if (!this.state.showForm) return null;
    return (
      <div className="credentials">
        <div className="credentials__form">
          <img className="credentials__logo" src={Logo} alt="" />
          <Input
            ref={this.input}
            style={{ width: "100%" }}
            id="sLogin"
            autoComplete="sLogin"
            name="sLogin"
            className="credentials__field"
            label="Login"
            valid={this.state.isLoginValid}
            onChange={this.onChangeLogin}
          />
          <Input
            style={{ width: "100%", marginTop: "5px" }}
            type="password"
            name="sPwd"
            id="sPwd"
            autoComplete="sPwd"
            className="credentials__field"
            label="Password"
            valid={this.state.isPasswordValid}
            onKeyDown={this.onPasswordEnter}
            onChange={this.onChangePassword}
          />
          <div
            className={
              "credentials__message " + (this.state.errorMessage && "error")
            }
          >
            {this.state.errorMessage}
          </div>
          <Button onClick={this.customAuth} className="credentials__submit">
            Sign In
          </Button>
          <div className="credentials__or">
            <span className="credentials__or-text">or</span>
          </div>
          <Button
            onClick={this.nativeAuth}
            className="credentials__auth-conterra"
          >
            Sign In with Native App
          </Button>
        </div>
      </div>
    );
  }

  nativeAuth = async () => {
    AuthData.nativeAuth();
  };

  toggleForm = (show: boolean) => {
    this.setState({ showForm: show });
    if (show && !!this.autofocusInput) this.autofocusInput.focus();
  };

  onChangeLogin = (e: InputChangeEvent) => {
    this.setState({ isLoginValid: true, login: e.value });
  };

  onChangePassword = (e: InputChangeEvent) => {
    this.setState({ isPasswordValid: true, password: e.value });
  };

  onPasswordEnter = (e: any) => {
    if (e.key === "Enter") this.customAuth();
  };

  customAuth = async () => {
    let isLoginValid = !!this.state.login;
    let isPasswordValid = !!this.state.password;
    this.setState({
      errorMessage: "",
      isLoginValid: isLoginValid,
      isPasswordValid: isPasswordValid,
    });
    if (!isLoginValid || !isPasswordValid) return;
    try {
      ModalRef.startProcessing();
      await AuthData.login(this.state.login, this.state.password);
      AuthRef.credentialsResolve();
    } catch (error: any) {
      let errorMessage = "";
      // TODO вынести это в общую обработку ошибок
      if (error.response) errorMessage = error.response.data.Message;
      else if (error.request) errorMessage = error.request;
      else errorMessage = "Error " + error.message;
      this.setState({ errorMessage });
    } finally {
      ModalRef.stopProcessing();
    }
  };
}

export default Auth;
