import { Component } from "react";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";

interface props {
  currentDate: string;
  dates: Array<{ Date: string }>;

  onChangeDate(date: string): void;
}

class SwitchDates extends Component<props> {
  render() {
    if (!this.props.dates.length) return null;
    let dateIndex = this.FindDateIndex();
    return (
      <>
        <Button
          onClick={this.ChangeCurrentDate}
          data-action="first"
          icon="arrow-end-left"
          disabled={dateIndex === 0}
        ></Button>
        <Button
          onClick={this.ChangeCurrentDate}
          data-action="minus"
          icon="arrow-60-left"
          disabled={dateIndex === 0}
        ></Button>
        <span className="current-date">
          {moment(this.props.currentDate).format("L")}
        </span>
        <Button
          onClick={this.ChangeCurrentDate}
          data-action="plus"
          icon="arrow-60-right"
          disabled={dateIndex === this.props.dates.length - 1}
        ></Button>
        <Button
          onClick={this.ChangeCurrentDate}
          data-action="last"
          icon="arrow-end-right"
          disabled={dateIndex === this.props.dates.length - 1}
        ></Button>
        <span>
          {dateIndex + 1} of {this.props.dates.length}
        </span>
      </>
    );
  }

  FindDateIndex = () => {
    return this.props.dates.findIndex(
      (item) => item.Date === this.props.currentDate
    );
  };
  ChangeCurrentDate = (e: any) => {
    var dates = this.props.dates;
    let currentDateIndex = this.FindDateIndex();
    switch (e.currentTarget.getAttribute("data-action")) {
      case "plus":
        if (currentDateIndex === dates.length - 1) return;
        currentDateIndex++;
        break;
      case "minus":
        if (currentDateIndex === 0) return;
        currentDateIndex--;
        break;
      case "last":
        if (currentDateIndex === dates.length - 1) return;
        currentDateIndex = dates.length - 1;
        break;
      case "first":
        if (currentDateIndex === 0) return;
        currentDateIndex = 0;
        break;
      default:
        return;
    }

    var currentDate = dates[currentDateIndex].Date;
    this.props.onChangeDate(currentDate);
  };
}

export default SwitchDates;
