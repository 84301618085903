import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import NoSelectedBP from "../../Common/NoSelectedBP";
import moment from "moment";
import BaseComponent from "../../BaseComponent";
import styles from "./bpgeneral.module.scss";
import Loader from "../../Common/Loader";
import commonStyles from "../../../assets/styles/common.module.scss";
import OpenCardLink from "../../Common/Buttons/OpenCardLink";
import { GridRowHeight } from "../../Dashboard/helpers";
import ButonLink from "../../Common/Buttons/ButtonLink";
import CardManagement from "../../Cards/CardManagement";

interface Info {
  BuildPlanName: string;
  CarrierId: number;
  CarrierName: string;
  CategoryId: number | null;
  CategoryName: string | null;
  ClassId: number | null;
  ClassName: string | null;
  Completed: string | null; // Date
  CustomerId: number;
  CustomerJobId: number | null;
  CustomerJobName: string | null;
  CustomerName: string;
  Description: string | null;
  DueDate: string | null; // Date
  Generated: string | null; // Date
  IsCustomName: boolean;
  MarketId: number;
  MarketName: string;
  Number: string; // BPNumber
  OwnerId: number;
  OwnerName: string;
  ProfitCenterId: number | null;
  ProfitCenterName: string | null;
  Progress: number;
  ProjectId: number;
  ProjectName: string;
  ProjectOwnerId: number;
  ProjectOwnerName: string | null;
  PwByDefault: boolean;
  PwLastUpdate: string | null; // datetime
  RegionId: number;
  RegionName: string;
  SalesRepId: number;
  SalesRepName: string;
  ScenarioId: number;
  ScenarioName: string;
  SiteId: number;
  SiteName: string;
  Started: string | null; // Date
  UpdateProgressAllow: boolean;
  WOforUnscheduledVisitId: number | null;
  WOforUnscheduledVisitName: string | null;
}

interface RoleItem {
  EmployeeName: string;
  RoleName: string;
}

interface AllowedEmployeeItem {
  EmployeeName: string;
}

interface FinancialInfo {
  HasAccess: boolean;
  ExcludeFromWIP: boolean;
  ExcludeFromBD: boolean;
  ExcludeFromCrewLeadBD: boolean;
  POTotal: number | null;
  SOTotal: number | null;
  ReceivableAmount: number | null;
  PaidAmount: number | null;
}

interface props {
  isActive: boolean;
  buildPlanId: number;
}

interface state {
  loading: boolean;
  info: Info | null;
  allowedEmployees: Array<AllowedEmployeeItem>;
  roles: Array<RoleItem>;
  financialInfo: FinancialInfo | null;
}

class BPGeneral extends BaseComponent<props, state> {
  bpId: number = this.props.buildPlanId;

  constructor(props: props) {
    super(props);

    this.state = {
      loading: false,
      info: null,
      roles: [],
      allowedEmployees: [],
      financialInfo: null,
    };
  }

  componentDidMount() {
    this.LoadData();
  }

  componentDidUpdate() {
    if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
      this.bpId = this.props.buildPlanId;
      this.LoadData();
    }
  }

  render() {
    if (!this.props.buildPlanId) return <NoSelectedBP />;
    let financial = this.state.financialInfo;
    let info = this.state.info;
    return (
      <div className={styles.Container}>
        {this.state.loading && <Loader />}
        {!!info && (
          <>
            <div className={`${styles.Column}`}>
              <div className={styles.Block}>
                {this.renderRow("Name", info.BuildPlanName)}
                {this.renderRow(
                  "Custom Name",
                  info.IsCustomName ? "Yes" : "No"
                )}
                {this.renderRow("Number", info.Number)}
                {this.renderRow("Project", info.ProjectName)}
                {this.renderRow(
                  "Scenario",
                  <OpenCardLink
                    text={info.ScenarioName}
                    refName="FSMProjectScenarios"
                    dataAttr={info.ScenarioId}
                  />
                )}
              </div>

              <div className={`${styles.Block}`}>
                {this.renderRow("Customer", info.CustomerName)}
                {this.renderRow("Customer Job", info.CustomerJobName)}
                {this.renderRow("Class", info.ClassName)}
                {this.renderRow(
                  "Sales Rep",
                  info.SalesRepId ? (
                    <OpenCardLink
                      text={info.SalesRepName!}
                      refName="SalesRep"
                      dataAttr={info.SalesRepId}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>

              <div className={`${styles.Block}`}>
                {this.renderRow("Category", info.CategoryName)}
                {this.renderRow("Owner", info.OwnerName)}
                {this.renderRow(
                  "Project Owner",
                  info.ProjectOwnerId ? (
                    <OpenCardLink
                      text={info.ProjectOwnerName!}
                      refName="Employees"
                      dataAttr={info.ProjectOwnerId}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>

              <div className={`${styles.Block}`}>
                {this.renderRow("Profit Center", info.ProfitCenterName)}
              </div>

              <div className={`${styles.Block}`}>
                {this.renderRow(
                  "WO for Unscheduled Visit",
                  info.WOforUnscheduledVisitId ? (
                    <OpenCardLink
                      text={info.WOforUnscheduledVisitName!}
                      refName="Employees"
                      dataAttr={info.WOforUnscheduledVisitId}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>

              <div className={`${styles.Block}`}>
                <div className={styles.Label}>Description:</div>
                <div className={styles.Description}>{info.Description}</div>
              </div>
            </div>

            <div className={`${styles.Column}`}>
              <div className={`${styles.Block}`}>
                {this.renderRow(
                  "Site",
                  <OpenCardLink
                    text={info.SiteName}
                    refName="FSMSites"
                    dataAttr={info.SiteId}
                  />
                )}
                {this.renderRow("Region", info.RegionName)}
                {this.renderRow("Market", info.MarketName)}
                {this.renderRow("Carrier", info.CarrierName)}
              </div>

              <div className={`${styles.Block}`}>
                {this.renderRow(
                  "Progress",
                  <div style={{ display: "flex" }}>
                    <span>{info.Progress} </span>
                    {!!this.state.info?.UpdateProgressAllow && (
                      <ButonLink
                        text="Change"
                        style={{ marginLeft: 8 }}
                        onClick={this.UpdateProgress}
                      />
                    )}
                  </div>
                )}
                {this.renderRow(
                  "Generated",
                  info.Generated && moment(info.Generated).format("L")
                )}
                {this.renderRow(
                  "Started",
                  info.Started && moment(info.Started).format("L")
                )}
                {this.renderRow(
                  "Due Date",
                  info.DueDate && moment(info.DueDate).format("L")
                )}
                {this.renderRow(
                  "Completed",
                  info.Completed &&
                    moment(info.Completed).format("L hh:mm:ss A")
                )}
                {this.renderRow(
                  "PW by default",
                  info.PwByDefault ? "Yes" : "No"
                )}
                {!!info.PwLastUpdate &&
                  this.renderRow(
                    "PW last update",
                    moment(info.PwLastUpdate).format("L LT")
                  )}
              </div>
              {financial && financial.HasAccess && (
                <div className={styles.Financial}>
                  <div className={`${styles.Block}`}>
                    {this.renderRow(
                      "Sales Order Total",
                      financial.SOTotal && `$${financial.SOTotal.toFixed(2)}`
                    )}
                    {this.renderRow(
                      "Customer PO Total",
                      financial.POTotal && `$${financial.POTotal.toFixed(2)}`
                    )}
                  </div>

                  <div className={`${styles.Block}`}>
                    {this.renderRow(
                      "Open Invoices Total",
                      financial.ReceivableAmount &&
                        `$${financial.ReceivableAmount.toFixed(2)}`
                    )}
                    {this.renderRow(
                      "Paid Invoices Total",
                      financial.PaidAmount &&
                        `$${financial.PaidAmount.toFixed(2)}`
                    )}
                  </div>

                  <div className={`${styles.Block}`}>
                    {this.renderRow(
                      "Exclude From WIP",
                      financial.ExcludeFromWIP ? "Yes" : "No"
                    )}
                    {this.renderRow(
                      "Exclude From CL",
                      financial.ExcludeFromCrewLeadBD ? "Yes" : "No"
                    )}
                    {this.renderRow(
                      "Exclude From Budget",
                      financial.ExcludeFromBD ? "Yes" : "No"
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className={`${styles.ColumnRight}`}>
          <Grid
            data={this.state.roles}
            className={`${styles.Block} ${commonStyles.SmallKendoGrid}`}
            scrollable="none"
            rowHeight={GridRowHeight}
          >
            <Column field="RoleName" title="Role" width="160" />
            <Column field="EmployeeName" title="Employee" />
          </Grid>

          <Grid
            data={this.state.allowedEmployees}
            className={`${styles.Block} ${commonStyles.SmallKendoGrid}`}
            scrollable="none"
            rowHeight={GridRowHeight}
          >
            <Column field="EmployeeName" title="Allowed Allocation Employee" />
          </Grid>
        </div>
      </div>
    );
  }

  renderRow = (label: string, value: any, children?: any) => {
    return (
      <div className={styles.Row}>
        <div className={styles.Label}>{label}:</div>
        <div className={styles.Value}>{value}</div>
        {children}
      </div>
    );
  };

  async LoadData() {
    if (!this.props.buildPlanId) return;
    this.setState({ loading: true });
    try {
      let params = { buildPlanId: this.props.buildPlanId };
      let result: any = await this.GetSQLData({
        spName: "DB_BuildPlanInfo",
        params,
      });
      this.setState({
        info: result[0][0] || null,
        roles: result[1] || [],
        allowedEmployees: result[2] || [],
        financialInfo: result[3] || null,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  UpdateProgress = async () => {
    let info = this.state.info;
    if (info)
      CardManagement.UpdateBudgetProgressCard(
        this.props.buildPlanId,
        info.Progress,
        this.Refresh
      );
  };

  Refresh = () => {
    this.LoadData();
  };
}

export default BPGeneral;
