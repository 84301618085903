import { ModalRef } from "../Components/Common/Modal/Modal";
import { getSQLData } from "../helpers/queries";
import {
  CheckListStatuses,
  ICLMSettings,
  ICLPMBPItem,
  ICLPMWOItem,
} from "./interfaces";

const USER_ROLES = {
  SUBMITTER: "Submitter",
  REVIEWER: "Reviewer",
  CUSTOMER: "Customer",
};

class CLPMSettings {
  readonly CLM_BUILDPLAN_LS: string = "CLM_filterBuildPlanId";
  RouteToReview?: (woId: number, bpId: number) => void;
  private settings: ICLMSettings | null = null;
  private CLPMStatusesLib: { [key in CheckListStatuses]: string } = {
    NoResult: "",
    Pending: "Pending",
    Rejected: "Rejected",
    CustomerRejected: "",
    Reviewed: "Reviewed",
    DoNotSubmit: "Do Not Submit",
    Approved: "Approved",
    InProgress: "In Progress",
  };
  private buildPlansLoaded: boolean = false;
  private buildPlans: ICLPMBPItem[] = [];
  private workOrders: ICLPMWOItem[] = [];

  getSettings = async () => {
    if (!this.settings) await this.LoadSetCLPMSetings();
    return this.settings!;
  };

  getStatusesList = async () => {
    if (!this.settings) await this.LoadSetCLPMSetings();

    let statusesLib = this.CLPMStatusesLib;
    let statuses: Array<{ value: CheckListStatuses; text: string }> = [];
    statuses.push({ value: "NoResult", text: "" });
    if (this.settings?.IsSubmitter || this.settings?.IsReviewer) {
      statuses.push(
        { value: "Pending", text: statusesLib.Pending },
        { value: "Rejected", text: statusesLib.Rejected }
      );

      if (this.settings?.IsSubmitter || this.settings?.UseCustomer) {
        statuses.push({
          value: "CustomerRejected",
          text: statusesLib.CustomerRejected,
        });
      }

      statuses.push(
        { value: "Reviewed", text: statusesLib.Reviewed },
        { value: "DoNotSubmit", text: statusesLib.DoNotSubmit },
        { value: "Approved", text: statusesLib.Approved },
        { value: "InProgress", text: statusesLib.InProgress }
      );
    } else if (this.settings?.IsCustomer) {
      statuses.push(
        { value: "CustomerRejected", text: statusesLib.Rejected },
        { value: "Approved", text: statusesLib.Approved }
      );
    }
    return statuses;
  };

  getCLPMStatusesLib = async () => {
    if (!this.settings) await this.LoadSetCLPMSetings();
    return this.CLPMStatusesLib;
  };

  setRouteToReview = (func: (woId: number, bpId: number) => void) => {
    window.helpers.CLPMRouteToReview = func;
    this.RouteToReview = func;
  };

  deleteRouteToReview = () => {
    delete window.helpers.CLPMRouteToReview;
    this.RouteToReview = undefined;
  };

  getUserRolesLib = () => USER_ROLES;

  loadActiveBuildPlans = async () => {
    try {
      let [buildPlans, workOrders]: [ICLPMBPItem[], ICLPMWOItem[]] =
        await getSQLData({ spName: "CLPM_GetActiveBuildPlans" });
      this.buildPlans = buildPlans;
      this.workOrders = workOrders;
      this.buildPlansLoaded = true;
    } catch (e: any) {
      ModalRef.showDialog({
        type: "error",
        title: "Error",
        text: e.message || e,
      });
    }
  };

  getActiveBuildPlans = async () => {
    if (this.buildPlansLoaded) return this.buildPlans;
    await this.loadActiveBuildPlans();
    return this.buildPlans;
  };

  getActiveWorkOrders = async () => {
    if (this.buildPlansLoaded) return this.workOrders;
    await this.loadActiveBuildPlans();
    return this.workOrders;
  };

  getActiveBuildPlansAndWorkOrders = async () => {
    if (this.buildPlansLoaded)
      return {
        buildPlans: this.buildPlans,
        workOrders: this.workOrders,
      };
    await this.loadActiveBuildPlans();
    return { buildPlans: this.buildPlans, workOrders: this.workOrders };
  };

  getSelectedBP = (bpId?: number) => {
    const savedBP = localStorage.getItem(this.CLM_BUILDPLAN_LS);
    const Id = !bpId && savedBP ? JSON.parse(savedBP).Id : bpId;
    const bp = Id
      ? this.buildPlans.find((item) => item.Id === Id) || null
      : null;

    if (bp) localStorage.setItem(this.CLM_BUILDPLAN_LS, JSON.stringify(bp));
    else localStorage.removeItem(this.CLM_BUILDPLAN_LS);

    return bp;
  };

  private LoadSetCLPMSetings = async () => {
    let result = await getSQLData({ spName: "CLPM_GetSettings" });
    let serverSettings: ICLMSettings = result[0];
    this.settings = {
      ...serverSettings,
      IsCustomer: serverSettings.UserRole === USER_ROLES.CUSTOMER,
      IsSubmitter: serverSettings.UserRole === USER_ROLES.SUBMITTER,
      IsReviewer: serverSettings.UserRole === USER_ROLES.REVIEWER,
    };

    this.CLPMStatusesLib.CustomerRejected = this.settings.IsCustomer
      ? "Rejected"
      : "Customer Rej."; // TODO ??
  };
}

export default new CLPMSettings()
