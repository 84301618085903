import { Component } from "react";
import { MultiSelectTree as KendoMultiSelectTree } from "@progress/kendo-react-dropdowns";
import styles from "./form.module.scss";

interface props<I> {
  data: Array<I>;
  dataAttr?: any;
  textField: keyof I;
  idField: keyof I;
  checkedField: keyof I;
  itemName: string;
  placeholder: string;
  className?: string;

  onChange(selectedIds: Array<string | number>, dataAttr?: any): void;
}

interface state {}

class MultiSelectTree<I> extends Component<props<I>, state> {
  selectedIds: Array<number | string>;

  constructor(props: props<I>) {
    super(props);
    this.state = {};
    // @ts-ignore
    this.selectedIds = this.props.data
      .map((item) =>
        item[this.props.checkedField] ? item[this.props.idField] : null
      )
      .filter((item) => item !== null);
  }

  render() {
    return (
      <KendoMultiSelectTree
        className={`${this.props.className} ${styles.MultiSelect} ${
          !this.selectedIds.length ? styles.MultiSelectNoSelected : ""
        }`}
        data={this.props.data}
        value={[{}]}
        onChange={this.OnChange}
        textField={this.props.textField.toString()}
        dataItemKey={this.props.idField.toString()}
        checkField={this.props.checkedField.toString()}
        tags={[
          {
            text:
              this.selectedIds.length > 0
                ? `${this.selectedIds.length} ${this.props.itemName}${
                    this.selectedIds.length > 1 ? "s" : ""
                  } Selected`
                : this.props.placeholder,
            data: [],
          },
        ]}
        popupSettings={{ popupClass: styles.MultiSelectPopup }}
      />
    );
  }

  OnChange = (e: any) => {
    if (e.items.length) {
      e.items[0][this.props.checkedField] =
        !e.items[0][this.props.checkedField];
      let id = e.items[0][this.props.idField];
      let index = this.selectedIds.findIndex((item) => item === id);
      if (index === -1) {
        this.selectedIds.push(id);
      } else {
        this.selectedIds.splice(index, 1);
      }
    } else {
      for (let item of this.props.data) {
        //@ts-ignore
        item[this.props.checkedField] = false;
      }
      this.selectedIds = [];
    }
    this.props.onChange(this.selectedIds, this.props.dataAttr);
  };
}

export default MultiSelectTree;
