import { EFigureType, IAddressInfo, objectType } from "./interfaces";
import styles from "./objectmap.module.scss";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import intersection from "sweepline-intersections";
import {
  DEFAULT_LOCATION_RADIUS,
  DEFAULT_SITE_RADIUS,
  GetAddressFromPlace,
  GetDefaultBoundaries,
  GetFullAddressString,
  getMaxRadiusBoundaries,
  LoadAddressInfo,
  polygonPointsToBoundaries,
} from "./helpers";
import { getSQLData } from "../../helpers/queries";
import { isConterraForm, mapQuestKey } from "../../helpers/settings";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import ButtonLink from "../../Components/Common/Buttons/ButtonLink";
import OpenCardLink from "../../Components/Common/Buttons/OpenCardLink";
import OtherNodesControl from "../../Components/Map/OtherNodesControl";
import debounce from "lodash.debounce";
import {
  GetPolygonCoordinatesFromStrBoundaries,
  ICONS,
  OpenObject,
} from "../../Components/Map/helpers";
import LoaderComponent from "../../Components/Common/Loader";
import SearchNodesLocation from "../../Components/Map/SearchNodesLocation";
import LastStopPointsControl from "../../Components/Map/LastStopPointsControl";

const MAP_CONTAINER_ID = "object-map-container";

interface IProps {
  objectType?: objectType;
  addressId?: number | null;
  lastResize?: number;
  coordinates?: { lat: number; lng: number };

  onChange(AddressInfo: IAddressInfo): void;
}

const ObjectMap = (props: IProps) => {
  const { addressId, coordinates, onChange } = props;
  const mapContainerIdRef = useRef(MAP_CONTAINER_ID + +new Date());
  const [isLoading, setIsLoading] = useState<boolean>();
  const [address, setAddressState] = useState<IAddressInfo>();
  const [newAddress, setNewAddressState] = useState<IAddressInfo>();
  const [relatedAddresses, setRelatedAddressesState] =
    useState<IAddressInfo[]>();
  const [figureType, setFigureType] = useState<EFigureType>(EFigureType.circle);
  const radiusRef = useRef<number>();
  const boundariesRef = useRef<string | null>();
  const objectTypeRef = useRef<objectType>(props.objectType || "Site");
  const mapRef = useRef<any>();
  const figuresGroupRef = useRef<any>(null);

  const fitMap = debounce(() => {
    if (
      !mapRef.current ||
      !figuresGroupRef.current ||
      !figuresGroupRef.current.getLayers().length
    )
      return;
    const bounds = figuresGroupRef.current.getBounds();
    if (!bounds._northEast) return;
    mapRef.current.fitBounds(bounds, { padding: [10, 10] });
  }, 5);

  const toggleFigureType = (e: React.MouseEvent<HTMLButtonElement>) => {
    const type = +e.currentTarget.getAttribute("datatype")! as EFigureType;
    if (figureType === type) return;

    if (type === EFigureType.circle) {
      radiusRef.current =
        objectTypeRef.current === "Location"
          ? DEFAULT_LOCATION_RADIUS
          : DEFAULT_SITE_RADIUS;
    }
    const { Lat, Lng } = address || {};
    boundariesRef.current =
      type === EFigureType.polygon && Lat && Lng
        ? GetDefaultBoundaries(mapRef.current)
        : null;
    setFigureType(type);

    if (!address) return;
    address.Radius = radiusRef.current;
    address.Boundaries = boundariesRef.current;
    onChange(address);
  };

  const setAddress = (address: IAddressInfo) => {
    address.Radius = radiusRef.current;
    address.Boundaries = boundariesRef.current;
    setAddressState(address);
    onChange(address);
  };
  const setRadius = (radius: number) => {
    radiusRef.current = radius;
    if (!address) return;
    address.Radius = radius;
    onChange(address);
  };
  const setBoundaries = (boundaries: string | null) => {
    boundariesRef.current = boundaries;
    if (!address) return;
    address.Boundaries = boundaries;
    onChange(address);
  };
  const applyNewAddress = () => {
    if (newAddress) {
      setAddress(newAddress);
      setNewAddressState(undefined);
    }
  };
  // Init Map
  useLayoutEffect(() => {
    if (!!mapRef.current) return;
    window.L.mapquest.key = mapQuestKey;
    mapRef.current = window.L.mapquest.map(mapContainerIdRef.current, {
      center: [37.2566, -104.6759],
      layers: window.L.mapquest.tileLayer("hybrid"),
      zoom: 100,
      zoomOnDoubleClick: true,
      bestFitMargin: 200,
      editable: true,
    });
    mapRef.current.addControl(
      window.L.mapquest.satelliteControl({
        position: "topleft",
        mapType: "map",
      })
    );
    mapRef.current.addControl(
      window.L.mapquest
        .geocodingControl({
          position: "topright",
          removePreviousMarker: true,
          placeholderText: "Enter address",
          pointZoom: 15,
          keepOpen: true,
          searchAhead: true,
          searchAheadOptions: {
            collection: "address",
          },
        })
        .on("searchahead_selected", (e: any) => {
          mapRef.current.removeLayer(e.target._marker);
          setAddress(GetAddressFromPlace(e.result.place));
        })
        .on("geocode_response", async (e: any) => {
          mapRef.current.removeLayer(e.target._marker);
          const { lat, lng } = e.results[0].locations[0].latLng;
          const newAddress = await LoadAddressInfo(lat, lng);
          if (newAddress) setAddress(newAddress);
        })
    );
    figuresGroupRef.current = new window.L.FeatureGroup();
    figuresGroupRef.current.addTo(mapRef.current);

    return () => {
      if (mapRef.current) figuresGroupRef.current?.removeFrom(mapRef.current);
      mapRef.current?.remove();
      figuresGroupRef.current = null;
      mapRef.current = null;
    };
  }, []);
  // Load Address
  useEffect(() => {
    if (addressId) {
      setIsLoading(true);
      getSQLData({
        spName: "GetObjectMapData",
        params: {
          addressId: addressId,
        },
      }).then((data) => {
        const mainAddress = data[0].find((n: IAddressInfo) => n.IsMainObject);
        const relatedAddresses = data[0].filter(
          (n: IAddressInfo) => !n.IsMainObject
        );
        objectTypeRef.current = mainAddress.ObjectType;
        radiusRef.current = mainAddress.Radius;
        boundariesRef.current = mainAddress.Boundaries;
        if (mainAddress.Boundaries) setFigureType(EFigureType.polygon);
        setAddressState(mainAddress);
        setRelatedAddressesState(relatedAddresses);
        setIsLoading(false);
      });
    } else if (coordinates) {
      LoadAddressInfo(coordinates.lat, coordinates.lng).then(
        (resultAddress) => {
          if (resultAddress) setAddress(resultAddress);
        }
      );
    }
  }, []);
  // Draw Center Marker
  useEffect(() => {
    if (!mapRef.current) return;
    if (!address || !address.Lat || !address.Lng) return;
    const marker = window.L.mapquest
      .textMarker([address.Lat, address.Lng], {
        position: "right",
        type: "marker",
        icon: ICONS.Current,
        draggable: true,
      })
      .addTo(figuresGroupRef.current);
    marker.on("dragend", async (e: any) => {
      const { lat: newLat, lng: newLng } = e.target._latlng;
      setIsLoading(true);
      setAddress({ ...address, Lat: newLat, Lng: newLng });
      const newAddress = await LoadAddressInfo(newLat, newLng);
      setIsLoading(false);
      if (newAddress) setNewAddressState(newAddress); // TODO show message if error geocoding?
    });
    fitMap();
    return () => {
      if (figuresGroupRef.current) marker.removeFrom(figuresGroupRef.current);
    };
  }, [address, mapRef.current]);
  // Draw Circle
  useEffect(() => {
    if (!mapRef.current) return;
    if (figureType !== EFigureType.circle) return;
    if (!address || !address.Lat || !address.Lng) return;
    let radius = address.Radius;
    if (radius === undefined || radius === null) {
      radius =
        objectTypeRef.current === "Location"
          ? DEFAULT_LOCATION_RADIUS
          : DEFAULT_SITE_RADIUS;
      setRadius(radius);
    }

    const circle = window.L.circle([address.Lat, address.Lng], {
      radius,
      color: ICONS.Current.primaryColor,
      stroke: true,
      weight: 3,
      fillOpacity: 0.05,
    }).addTo(figuresGroupRef.current);

    const onEdit = debounce((e: any) => setRadius(e.layer._mRadius), 500);
    circle.enableEdit();

    circle.on("editable:editing", onEdit);
    circle.on("editable:vertex:dragstart", function (e: any) {
      const isCenterVertex = circle.getLatLng().equals(e.vertex.latlng);
      if (isCenterVertex) e.vertex.dragging.disable();
    });

    fitMap();

    return () => {
      if (figuresGroupRef.current) circle.removeFrom(figuresGroupRef.current);
    };
  }, [address, figureType, mapRef.current]);
  // Draw Polygon
  useEffect(() => {
    if (!mapRef.current) return;
    if (figureType !== EFigureType.polygon) return;
    if (!address) return;
    let boundaries = address.Boundaries;
    if (!boundaries) {
      boundaries = GetDefaultBoundaries(mapRef.current);
      setBoundaries(boundaries);
    }
    const points = GetPolygonCoordinatesFromStrBoundaries(boundaries);
    const polygon = window.L.polygon(points, {
      color: ICONS.Current.primaryColor,
      fillColor: ICONS.Current.primaryColor,
      stroke: true,
      weight: 3,
      fillOpacity: 0.05,
    }).addTo(figuresGroupRef.current);

    setRadius(
      getMaxRadiusBoundaries(
        window.L.latLng(address.Lat, address.Lng),
        polygon.getLatLngs()[0]
      )
    );
    polygon.enableEdit();

    const onEdit = debounce(() => {
      const points = polygon.getLatLngs();
      const isIntersectionSelfPolygon =
        intersection(polygon.toGeoJSON(), false).length > 0;
      // const center = polygon.getCenter();

      if (isIntersectionSelfPolygon) {
        polygon.disableEdit(); // https://github.com/Leaflet/Leaflet.Editable/issues/147
        polygon.setLatLngs(
          GetPolygonCoordinatesFromStrBoundaries(
            boundariesRef.current as string
          )
        );
        polygon.enableEdit();
        return;
      }

      setRadius(
        getMaxRadiusBoundaries(
          window.L.latLng(address.Lat, address.Lng),
          points[0]
        )
      );
      setBoundaries(polygonPointsToBoundaries(points[0]));
    }, 500);
    polygon.on("editable:editing", onEdit);

    fitMap();
    return () => {
      if (figuresGroupRef.current) polygon.removeFrom(figuresGroupRef.current);
    };
  }, [address, figureType]);
  // Draw Related Sites
  useEffect(() => {
    if (!mapRef.current) return;
    const mapLayers: any[] = [];
    if (!relatedAddresses?.length) return;
    for (let address of relatedAddresses) {
      const center = [address.Lat, address.Lng];
      const marker = window.L.mapquest
        .textMarker(center, {
          position: "right",
          type: "marker",
          icon: ICONS.RelatedSite, // always relatedSite??
          draggable: true,
          refName: address.ObjectType === "Location" ? "Locations" : "FSMSites",
          objectId: address.ObjectId,
        })
        .bindTooltip(
          "<b>" + address.ObjectName + "</b><br/>" + address.AddressString
        )
        .on("contextmenu", OpenObject)
        .addTo(figuresGroupRef.current);
      mapLayers.push(marker);
      if (address.Boundaries) {
        const polygon = window.L.polygon(
          GetPolygonCoordinatesFromStrBoundaries(address.Boundaries),
          {
            color: ICONS.RelatedSite.primaryColor,
            fillColor: ICONS.RelatedSite.secondaryColor,
            stroke: true,
            weight: 1.5,
            fillOpacity: 0.05,
          }
        ).addTo(figuresGroupRef.current);
        mapLayers.push(polygon);
      } else {
        const radius = window.L.circle(center, {
          radius: address.Radius,
          color: ICONS.RelatedSite.secondaryColor,
          fillColor: ICONS.RelatedSite.secondaryColor,
          stroke: true,
          weight: 1.5,
          fillOpacity: 0.05,
        }).addTo(figuresGroupRef.current);
        mapLayers.push(radius);
      }
      fitMap();
    }

    return () => {
      if (figuresGroupRef.current) {
        for (let layer of mapLayers) {
          layer.removeFrom(figuresGroupRef.current);
        }
      }
    };
  }, [relatedAddresses]);

  return (
    <div
      className={isConterraForm ? styles.ConterraContainer : styles.Container}
    >
      {isLoading && (
        <LoaderComponent
          theme={"light"}
          style={{
            background: "transparent",
            color: "#fff",
          }}
        />
      )}

      <div className={styles.Toolbar}>
        {!!address && (
          <>
            <div>
              <div className={styles.ToolbarRow}>
                {addressId ? (
                  <OpenCardLink
                    text="Address: "
                    refName="Addresses"
                    dataAttr={addressId}
                  />
                ) : (
                  <b>Address:&nbsp;</b>
                )}
                <span id="addres-string">{GetFullAddressString(address)}</span>
              </div>
              {!!newAddress && (
                <div className={styles.ToolbarRow}>
                  <ButtonLink
                    text={"Apply New Address: "}
                    onClick={applyNewAddress}
                  />
                  <span className={styles.NewAddress}>
                    {GetFullAddressString(newAddress)}
                  </span>
                </div>
              )}
            </div>
            {!!address && (
              <div className={styles.RightContainer}>
                {radiusRef.current !== undefined &&
                  figureType === EFigureType.circle && (
                    <span>{Math.round(radiusRef.current)} meters</span>
                  )}
                <ButtonGroup>
                  <Button
                    togglable={true}
                    datatype={EFigureType.circle + ""}
                    selected={figureType === EFigureType.circle}
                    onClick={toggleFigureType}
                  >
                    Circle
                  </Button>
                  <Button
                    togglable={true}
                    datatype={EFigureType.polygon + ""}
                    selected={figureType === EFigureType.polygon}
                    onClick={toggleFigureType}
                  >
                    Polygon
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.MapContainer} id={"mainContainer"}>
        <div
          id={mapContainerIdRef.current}
          className={styles.MapContainer}
        ></div>
        {!!mapRef.current && (
          <OtherNodesControl
            className={styles.CustomMapControls}
            map={mapRef.current}
          />
        )}
        {!!mapRef.current && (
          <SearchNodesLocation
            map={mapRef.current}
            className={styles.CustomSearchMapControls}
          />
        )}
        {!!mapRef.current && !!addressId && (
          <LastStopPointsControl
            map={mapRef.current}
            addressId={addressId}
            className={styles.LastStopsControl}
          />
        )}
      </div>
    </div>
  );
};
export default ObjectMap
