import moment from "moment";
import React, { Component } from "react";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { IFilterSetting } from "../../Components/Dashboard/interfaces";
import { simpleObject } from "../../helpers/interfaces";
import { COLUMNS, PrepareData } from "./helpers";

const FILTERS: Array<IFilterSetting> = [
  {
    id: "timeFilter",
    type: "rangetime",
    idColumn: "D",
    toolbarRow: 1,
  },
];

interface props {
  tcId: number;
  isModalCard: boolean;
  isActive?: boolean;
  doNotSaveFilters?: boolean;
  pageId?: string;

  onChangeGridData?(data: Array<simpleObject>): void;
}

interface state {
  title: string;
}

class TKTCLogs extends Component<props, state> {
  gridRef: any = React.createRef();
  tcId = this.props.tcId;

  constructor(props: props) {
    super(props);
    this.state = {
      title: "",
    };
  }

  render() {
    if (this.props.isActive && this.tcId !== this.props.tcId) {
      this.tcId = this.props.tcId;
    }
    if (
      !this.props.tcId ||
      (!this.props.isActive && this.tcId !== this.props.tcId)
    )
      return null;

    return (
      <DashboardGrid
        key={this.tcId}
        ref={this.gridRef}
        isActive={
          this.props.isActive === undefined ? true : this.props.isActive
        } // ??
        isParentPage={this.props.isModalCard || !this.props.pageId} // tkReview modal || open in new browsertab
        columnsSettings={COLUMNS}
        getSqlParams={{
          spName: "TK_GetTCLogs",
          params: {
            TCID: this.props.tcId,
          },
        }}
        prepareData={this.PrepareData}
        filters={FILTERS}
        initRefresh={this.InitRefresh}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{
          // hideRightBtns: this.props.pageId === 'TCGrid' || getParamsFromUrl()?.folder === 'TKTimeCardTabs',
          notModeTitle: !this.props.isModalCard ? (
            <div
              style={{
                display: "block",
                flex: "1 0 auto",
                textAlign: "center",
              }}
            >
              {this.state.title}
            </div>
          ) : undefined,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  PrepareData = (
    result: [
      {
        Date: string;
        EmployeeName: string;
      },
      Array<simpleObject>
    ]
  ) => {
    let titleData = result[0];
    let title = `${titleData.EmployeeName} - ${moment(titleData.Date).format(
      "L"
    )}`;
    if (!this.props.isModalCard) {
      this.setState({ title });
    }
    return PrepareData(result[1]);
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default TKTCLogs;
