import FXCard from "../Common/FXCard/FXCard";
import {ISiteCardProps} from "./interfaces";
import {
    IAddressInfo,
    IEditableSiteInfo,
} from "../../Pages/ObjectMap/interfaces";
import ObjectMap from "../../Pages/ObjectMap/ObjectMap";
import formStyles from "./card.module.scss";
import BaseComponent from "../BaseComponent";
import {Button, Toolbar, ToolbarSpacer} from "@progress/kendo-react-buttons";
import FilterCombobox from "../Common/Form/FilterCombobox";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    TextArea,
    TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import Loader from "../Common/Loader";
import {IComboboxItem} from "../../helpers/interfaces";

import styles from "../../Pages/ObjectMap/objectmap.module.scss";
import {OpenRecord, RunScriptAsync} from "../../helpers/runscripts";
import {LoadCarriers, LoadMarkets} from "../../helpers/LoadLists";
import OpenCardIconLink from "./OpenCardIconLink";
import {sortByNameCombobox} from "../../helpers/helpers";
import React, {useEffect, useMemo, useState} from "react";
import CardManagement from "./CardManagement";
import UserInfo from "../../stores/User";
import {IServerRelatedSite, IServerSiteInfo} from "../Map/interfaces";
import ComboboxFilterVirtual from "../Dashboard/ComboboxFilterVirtual";
import {ReferenceRecordsDataSource} from "../../helpers/queries";
import {useRefresher} from "../../helpers/hooks";

type tabId = "general" | "accessProtocols" | "relatedSites";

const tabs: {
    Id: tabId,
    Text: string
}[] = [
    {Id: "general", Text: 'General'},
    {Id: "accessProtocols", Text: 'Access Protocols'},
    {Id: "relatedSites", Text: 'Related Sites'},
]

interface state {
    lastResizeCard: number;

    loadedInitialData: boolean;
    contextMenu: null | { left: number; top: number };

    carriers: Array<IComboboxItem>;
    markets: Array<IComboboxItem>;

    processing: boolean;
    remountKey: number;
    invalidForm: boolean;

    addressString: string;
    activeTab: tabId;
    relatedSites: IServerRelatedSite[]
    availableSites: IComboboxItem[] | null
}

const tabContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "absolute",
    left: 8,
    right: 8,
    top: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0,
};

const tabContainerActiveStyles: React.CSSProperties = {
    zIndex: 2,
    opacity: 1,
};

class SiteCard extends BaseComponent<ISiteCardProps, state> {
    isNewSiteCard = this.props.siteId === undefined;
    EditableSiteInfo: IEditableSiteInfo = {
        IsCustomName: true,
        SiteId: "",
        SiteName: "",
        Market: null,
        Region: null,
        Carrier: null,
        Address: null,
        DirectionsFromLocalOffice: "",
        SpecialAccessInstruction: "",
        Notes: "",
        Jurisdiction: ""
    };
    RelatedSites: IComboboxItem[] | null = null

    ServerSiteInfo: IServerSiteInfo | null = null;
    canGenerateBP: boolean = false;

    constructor(props: ISiteCardProps) {
        super(props);
        this.state = {
            lastResizeCard: +new Date(),

            loadedInitialData: false,
            contextMenu: null,

            carriers: [],
            markets: [],

            addressString: "",

            processing: false,
            remountKey: +new Date(),
            invalidForm: true,
            activeTab: tabs[0].Id,
            relatedSites: [],
            availableSites: [],
        };
    }

    componentDidMount(): void {
        this.LoadInitialData();
    }

    render() {
        const {activeTab} = this.state
        return (
            <FXCard
                title={this.renderCardTitle()}
                onClose={this.Close}
                initialWidth={730}
                initialHeight={750}
                originalPaddings={false}
                onResize={this.OnCardResize}
                onStageChange={this.OnCardResize}
            >
                {!this.state.loadedInitialData ? (
                    <Loader/>
                ) : (
                    <>
                        {this.state.processing && <Loader/>}
                        <Toolbar style={{marginBottom: 8, flex: "0 0 auto"}}>
                            {tabs.map(({Id, Text}) => <Button
                                key={Id}
                                themeColor={
                                    activeTab === Id ? "primary" : undefined
                                }
                                id={Id}
                                onClick={this.ChangeTab}
                            >
                                {Text}
                            </Button>)}
                            <ToolbarSpacer/>
                            {activeTab === 'relatedSites' && <Button icon={'plus'}
                                     onClick={() => CardManagement.OpenSiteCard({
                                         onFinish: this.LoadAvailableSites
                                     })}
                            >New Site</Button>}
                        </Toolbar>
                        <div
                            className={formStyles.FormWrapper}
                            style={{position: "relative"}}
                        >
                            <div
                                style={{
                                    ...tabContainerStyles,
                                    ...(activeTab === "general"
                                        ? tabContainerActiveStyles
                                        : {}),
                                }}
                            >
                                <div style={{display: "flex"}}>
                                    <div className={styles.Left}>
                                        <Input
                                            key={this.state.remountKey + "siteId"}
                                            className={`${formStyles.FormField} ${formStyles.TextField}`}
                                            placeholder="Site ID *"
                                            defaultValue={this.EditableSiteInfo.SiteId}
                                            onChange={this.OnChangeTextField}
                                            name="SiteId"
                                            required={true}
                                        />
                                        {this.EditableSiteInfo.IsCustomName && (
                                            <Input
                                                key={this.state.remountKey + "sitename"}
                                                className={`${formStyles.FormField} ${formStyles.TextField}`}
                                                placeholder="Site Name"
                                                defaultValue={this.EditableSiteInfo.SiteName}
                                                name="SiteName"
                                                onChange={this.OnChangeTextField}
                                                disabled={!this.EditableSiteInfo.IsCustomName}
                                            />
                                        )}
                                        <div className={formStyles.CheckboxRow}>
                                            <Checkbox
                                                defaultValue={this.EditableSiteInfo.IsCustomName}
                                                label={"Use Custom Name"}
                                                onChange={this.OnChangeUseCustomName}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.Right}>
                                        <FilterCombobox
                                            key={this.state.remountKey + "market"}
                                            placeholder="Market *"
                                            defaultValue={this.EditableSiteInfo.Market}
                                            data={this.state.markets}
                                            onChange={this.OnChangeField}
                                            className={`${formStyles.FormField}`}
                                            dataAttr={"Market"}
                                            required={true}
                                        />
                                        <FilterCombobox
                                            key={this.state.remountKey + "carrier"}
                                            placeholder="Carrier *"
                                            defaultValue={this.EditableSiteInfo.Carrier}
                                            data={this.state.carriers}
                                            onChange={this.OnChangeField}
                                            className={`${formStyles.FormField}`}
                                            dataAttr={"Carrier"}
                                            required={true}
                                        />
                                        <Input
                                            key={this.state.remountKey + "jurisdiction"}
                                            className={`${formStyles.FormField} ${formStyles.TextField}`}
                                            placeholder="Jurisdiction"
                                            defaultValue={this.EditableSiteInfo.Jurisdiction}
                                            name="Jurisdiction"
                                            onChange={this.OnChangeTextField}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "relative",
                                    }}
                                >
                                    <ObjectMap
                                        lastResize={this.state.lastResizeCard}
                                        addressId={this.ServerSiteInfo?.AddressId}
                                        coordinates={this.props.coordinates}
                                        onChange={this.OnAddressChange}
                                        objectType={"Site"}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    ...tabContainerStyles,
                                    ...(activeTab === "accessProtocols"
                                        ? tabContainerActiveStyles
                                        : {}),
                                }}
                            >
                                <div style={{marginBottom: 4}}>
                                    Special Access Instruction:
                                </div>
                                <TextArea
                                    key={this.state.remountKey + "SpecialAccessInstruction"}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.SpecialAccessInstruction}
                                    name="SpecialAccessInstruction"
                                    onChange={this.OnChangeTextField}
                                ></TextArea>
                                <div style={{marginBottom: 4, marginTop: 4}}>
                                    Directions from Local Office:
                                </div>
                                <TextArea
                                    key={this.state.remountKey + "DirectionsFromLocalOffice"}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.DirectionsFromLocalOffice}
                                    name="DirectionsFromLocalOffice"
                                    onChange={this.OnChangeTextField}
                                ></TextArea>
                                <div
                                    style={{
                                        marginBottom: 4,
                                        marginTop: 4,
                                    }}
                                >
                                    Notes:
                                </div>
                                <TextArea
                                    key={this.state.remountKey + "Notes"}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.Notes}
                                    name="Notes"
                                    onChange={this.OnChangeTextField}
                                ></TextArea>
                            </div>

                            <div
                                style={{
                                    ...tabContainerStyles,
                                    ...(activeTab === "relatedSites"
                                        ? tabContainerActiveStyles
                                        : {}),
                                }}
                            >
                                <RelatedSitesList
                                    relatedSites={this.state.relatedSites}
                                    availableSites={this.state.availableSites || []}
                                    onChange={this.OnChangeRelatedSites}/>
                            </div>
                        </div>
                        {this.renderFooter()}
                    </>
                )}
            </FXCard>
        );
    }

    renderCardTitle = () => {
        let content: any;
        if (this.isNewSiteCard) content = "New Site";
        else {
            content = (
                <>
                    <OpenCardIconLink onClick={this.OpenSite}
                                      title="Open Site Card"/>
                    <span className={styles.HeaderTitle}>
            {!this.state.loadedInitialData
                ? "Loading..."
                : this.ServerSiteInfo?.SiteId || "Edit Site"}
          </span>
                </>
            );
        }
        return <span className={formStyles.HeaderTitle}>{content} </span>;
    };

    renderFooter = () => {
        return (
            <div
                className={`${formStyles.FormFooter} k-action-buttons`}
                style={{paddingLeft: 8, paddingRight: 8}}
            >
        <span className={formStyles.InvalidMessage}>
          {this.state.invalidForm && <span>Fill all mandatory fields</span>}
        </span>
                <Button onClick={this.Close}>Cancel</Button>
                {this.canGenerateBP && (
                    <Button
                        onClick={
                            this.isNewSiteCard ? this.CreateSiteAndGBP : this.SaveSiteAndGBP
                        }
                        themeColor="primary"
                        disabled={this.state.invalidForm}
                    >
                        {this.isNewSiteCard ? "Create " : "Save "}and Generate
                        BP
                    </Button>
                )}
                {this.isNewSiteCard ? (
                    <Button
                        onClick={this.OnClickCreateSite}
                        themeColor="primary"
                        disabled={this.state.invalidForm}
                    >
                        Create Site
                    </Button>
                ) : (
                    <Button
                        onClick={this.OnClickSaveSite}
                        themeColor="primary"
                        disabled={this.state.invalidForm}
                    >
                        Save
                    </Button>
                )}
            </div>
        );
    };

    ChangeTab = (e: any) => {
        let tabId = e.currentTarget.id;
        let activeTab = this.state.activeTab;
        if (tabId === 'relatedSites' || !this.state.availableSites) {
            this.LoadAvailableSites()
        }
        if (activeTab !== tabId) this.setState({activeTab: tabId});
    };

    OnChangeRelatedSites = (relatedSites: IServerRelatedSite[]) => {
        this.RelatedSites = relatedSites.map(({Id, SiteID}) => ({
            Id, Name: SiteID
        }))
    }

    OnChangeTextField = (e: InputChangeEvent | TextAreaChangeEvent) => {
        let field = e.target.name! as keyof IEditableSiteInfo; //: 'SiteId' |
        // 'SiteName' | 'Notes' | 'SpecialAccessInstruction' |
        // 'DirectionsFromLocalOffice' | 'Jurisdiction'
        this.OnChangeField(e.value, field);
    };

    OnChangeUseCustomName = (e: CheckboxChangeEvent) => {
        this.EditableSiteInfo.IsCustomName = e.value;
        this.EditableSiteInfo.SiteName = this.EditableSiteInfo.SiteId;
        this.setState({remountKey: +new Date()});
    };

    OnChangeField = (value: any, dataAttr: keyof IEditableSiteInfo) => {
        // @ts-ignore
        this.EditableSiteInfo[dataAttr] = value;
        if (dataAttr === "Market") {
            if (value) {
                this.EditableSiteInfo.Region = {
                    Id: value.HighLvlId,
                    Name: value.HighLvlName,
                };
                this.setState({remountKey: +new Date()});
            }
        } else if (dataAttr === "SiteId" && !this.EditableSiteInfo.IsCustomName) {
            this.EditableSiteInfo.SiteName = value;
        }
        this.setState({invalidForm: this.IsInValidForm()});
    };

    IsInValidForm = () => {
        if (this.isNewSiteCard) {
            return (
                !this.EditableSiteInfo.SiteId ||
                !this.EditableSiteInfo.Market ||
                !this.EditableSiteInfo.Region ||
                !this.EditableSiteInfo.Carrier ||
                !this.EditableSiteInfo.Address
            );
        } else {
            return (
                !this.EditableSiteInfo.SiteId ||
                !this.EditableSiteInfo.Market ||
                !this.EditableSiteInfo.Region ||
                !this.EditableSiteInfo.Carrier
            );
        }
    };

    LoadAvailableSites = async (siteId?: number) => {
        this.setState({processing: true})
        try {
            let result = await ReferenceRecordsDataSource("FSMSites");
            const availableSites: IComboboxItem[] = result[0]
            if (siteId) {
                const createdSite = availableSites.find((site) => site.Id === siteId)
                if (createdSite) {
                    if (this.RelatedSites === null) this.RelatedSites = []
                    this.RelatedSites.push(createdSite)
                }
            }
            const relatedSites: IServerRelatedSite[] = siteId ?
                (this.RelatedSites || [])
                    .map(site => ({
                        Id: +site.Id,
                        SiteID: site.Name
                    })) : this.state.relatedSites

            this.setState({
                availableSites,
                relatedSites
            })
        } catch (e) {

        } finally {
            this.setState({processing: false})
        }
    }

    LoadInitialData = async () => {
        try {
            this.canGenerateBP = await UserInfo.canGenerateBP();
            let result: Array<PromiseSettledResult<any>> = await Promise.allSettled([
                LoadMarkets(),
                LoadCarriers(),
                this.LoadSiteData(),
            ]);
            let [marketsResult, carriersResult, siteDataResult] = result;
            let [markets, carriers, siteInfo] = [
                // @ts-ignore
                marketsResult.value || [],
                // @ts-ignore
                carriersResult.value || [],
                // @ts-ignore
                siteDataResult.value,
            ];
            if (siteInfo) {
                this.ProcessingSiteData(siteInfo);
            }
            markets.forEach((item: IComboboxItem) => {
                item.Name = this.GetMarketName(item.HighLvlName!, item.Name);
            });
            markets.sort(sortByNameCombobox);
            this.setState({
                markets,
                carriers,
                invalidForm: this.IsInValidForm(),
                remountKey: +new Date(),
                relatedSites: siteInfo?.relatedSites || []
            });
        } finally {
            this.setState({loadedInitialData: true});
        }
    };

    GetMarketName = (regionName: string, marketName: string) =>
        `${regionName}: ${marketName}`;

    ProcessingSiteData = (props: {
        siteInfo: IServerSiteInfo[],
        relatedSites: IServerRelatedSite[]
    }) => {
        const {siteInfo} = props
        this.ServerSiteInfo = siteInfo[0];
        this.EditableSiteInfo.SiteId = this.ServerSiteInfo.SiteId || "";
        this.EditableSiteInfo.SiteName = this.ServerSiteInfo.SiteName || "";
        this.EditableSiteInfo.IsCustomName = this.ServerSiteInfo.IsCustomName;
        if (this.ServerSiteInfo.CarrierId && this.ServerSiteInfo.CarrierName)
            this.EditableSiteInfo.Carrier = {
                Id: this.ServerSiteInfo.CarrierId,
                Name: this.ServerSiteInfo.CarrierName,
            };
        if (
            this.ServerSiteInfo.MarketId &&
            this.ServerSiteInfo.MarketName &&
            this.ServerSiteInfo.RegionId &&
            this.ServerSiteInfo.RegionName
        ) {
            this.EditableSiteInfo.Market = {
                Id: this.ServerSiteInfo.MarketId,
                Name: this.GetMarketName(this.ServerSiteInfo.RegionName, this.ServerSiteInfo.MarketName),
            };
            this.EditableSiteInfo.Region = {
                Id: this.ServerSiteInfo.RegionId,
                Name: this.ServerSiteInfo.RegionName,
            };
        }
        this.EditableSiteInfo.Notes = this.ServerSiteInfo.Notes || "";
        this.EditableSiteInfo.SpecialAccessInstruction =
            this.ServerSiteInfo.SpecialAccessInstruction || "";
        this.EditableSiteInfo.DirectionsFromLocalOffice =
            this.ServerSiteInfo.DirectionsFromLocalOffice || "";
        this.EditableSiteInfo.Jurisdiction =
            this.ServerSiteInfo.Jurisdiction || "";
    };

    LoadSiteData = async () => {
        if (this.isNewSiteCard) return null;
        let result: any = await this.GetSQLData({
            spName: "FSMSites_GetData",
            params: {
                siteId: this.props.siteId
            },
        });
        return {
            siteInfo: result[0] as IServerSiteInfo,
            relatedSites: result[2] as IServerRelatedSite[]
        };
    };

    OpenSite = () => {
        if (this.props.siteId) OpenRecord("FSMSites", this.props.siteId);
    };

    CreateSiteAndGBP = () => this.CreateSite(true);

    SaveSiteAndGBP = () => this.SaveSite(true);

    OnClickCreateSite = () => this.CreateSite();

    OnClickSaveSite = () => this.SaveSite();

    CreateSite = async (gbp?: boolean) => {
        try {
            this.setState({processing: true});
            let newSiteId = await RunScriptAsync("FSMSites_Upsert", {
                SiteJSON: this.GetSiteJSON(),
            });
            if (this.props.onFinish) this.props.onFinish(newSiteId);
            if (gbp) {
                CardManagement.OpenGBPCard({siteId: newSiteId});
            }
            this.Close();
        } finally {
            this.setState({processing: false});
        }
    };

    OnAddressChange = (AddressInfo: IAddressInfo | null) => {
        this.EditableSiteInfo.Address = AddressInfo;
        this.setState({
            invalidForm: this.IsInValidForm(),
        });
    };

    GetSiteJSON = () => {
        const {
            IsCustomName,
            SiteName,
            SiteId,
            DirectionsFromLocalOffice,
            SpecialAccessInstruction,
            Notes,
            Carrier,
            Region,
            Market,
            Address,
            Jurisdiction,
        } = this.EditableSiteInfo;

        return JSON.stringify({
            IsCustomName,
            SiteName,
            SiteId,
            DirectionsFromLocalOffice,
            SpecialAccessInstruction,
            Notes,
            CarrierId: Carrier?.Id,
            RegionId: Region?.Id,
            MarketId: Market?.Id,
            AddressJSON: JSON.stringify(Address),
            Jurisdiction,
            RelatedSites: this.RelatedSites ? this.RelatedSites.map(({Id}) => Id) : undefined
        });
    };

    SaveSite = async (gbp?: boolean) => {
        try {
            this.setState({processing: true});

            await RunScriptAsync("FSMSites_Upsert", {
                SiteId: this.props.siteId,
                SiteJSON: this.GetSiteJSON()
            });
            if (this.props.onFinish && this.props.siteId) {
                this.props.onFinish(this.props.siteId);
            }
            if (gbp) {
                CardManagement.OpenGBPCard({siteId: this.props.siteId});
            }
            this.Close();
        } catch (e) {
            // console.log(e)
        } finally {
            this.setState({processing: false});
        }
    };

    OnCardResize = () => {
        this.setState({lastResizeCard: +new Date()});
    };

    Close = () => {
        this.props.finally!();
    };
}

function RelatedSitesList(props: {
    availableSites: IComboboxItem[],
    relatedSites: IServerRelatedSite[],
    onChange: (relatedSites: IServerRelatedSite[]) => void
}) {
    const [relatedSites, setRelatedSites] = useState<(IServerRelatedSite | null)[]>([])
    const comboBoxRefresher = useRefresher()

    useEffect(() => {
        setRelatedSites([...props.relatedSites])
    }, [props.relatedSites])

    useEffect(() => {
        props.onChange(relatedSites.filter(rs => !!rs) as IServerRelatedSite[])
    }, [relatedSites, props.onChange])

    const addRow = () => {
        setRelatedSites([...relatedSites, null])
    }

    const deleteRow = (index: number) => {
        relatedSites.splice(index, 1);
        setRelatedSites([...relatedSites])
    }

    const onChange = (index: number, value: IComboboxItem | null) => {
        relatedSites[index] = value ? {Id: +value.Id, SiteID: value.Name} : null
        setRelatedSites([...relatedSites])
    }

    const availableSites = useMemo(() => {
        return props.availableSites.filter((site) => !relatedSites.some((rs) => rs?.Id === site.Id))
    }, [props.availableSites, relatedSites])

    useEffect(() => {
        comboBoxRefresher()
    }, [availableSites, comboBoxRefresher])

    if (availableSites.length === 0) return null
    return <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }}>
        <div style={{overflow: 'auto'}}>
            {relatedSites.map((item, i) => {
                return (
                    <div
                        className={formStyles.Row}
                        key={i.toString() + comboBoxRefresher.value}
                    >
                        <div style={{
                            flex: 1,
                            paddingRight: 8,
                            boxSizing: 'border-box'
                        }}>
                            <ComboboxFilterVirtual
                                width={400}
                                placeholder="Site"
                                data={availableSites}
                                defaultValue={item ? {
                                    Id: item.Id,
                                    Name: item.SiteID
                                } : undefined}
                                onChange={(value) => onChange(i, value)}
                                className={formStyles.FormField}
                                filter={i}
                            />
                        </div>
                        <div style={{
                            width: 32,
                            textAlign: 'right'
                        }}>
                            <Button
                                icon="minus"
                                fillMode="flat"
                                title="Delete"
                                style={{marginBottom: 5}}
                                onClick={() => deleteRow(i)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
        <div className={formStyles.Row} key={"newRow"}>
            <Button
                icon="plus"
                onClick={addRow}
                disabled={relatedSites.some((item) => item === null)}
                style={{
                    width: '100%',
                    padding: 0,
                    height: 'auto',
                    border: 'none',
                }}
            />
        </div>
    </div>
}

export default SiteCard
