import React, { useEffect, useRef } from "react";
import { IActivity } from "../../Pages/LiveMap/interfaces";
import { MARKERS_COLORS } from "./helpers";
import { simpleObject } from "../../helpers/interfaces";
import moment from "moment/moment";
import throttle from "lodash.throttle";

interface IProps {
  map: any;
}

export const DetailsActivityControl = (props: IProps) => {
  const { map } = props;
  const mapTimelineRef = useRef<simpleObject | null>(null);
  const timeElementRef = useRef<HTMLDivElement | null>(null);
  const employeeNameRef = useRef<HTMLDivElement | null>(null);
  const sliderElRef = useRef<HTMLInputElement | null>(null);
  const markerRef = useRef<any | null>(null);
  const msRef = useRef<number | null>(null);

  const invalidateSliderSize = () => {
    const mapContainer = map._container;
    const sliderEl = sliderElRef.current;
    if (mapContainer && sliderEl) {
      const availableWidth = mapContainer.offsetWidth - 210;
      const sliderWidth = availableWidth > 800 ? 800 : availableWidth;
      sliderEl.style.width = sliderWidth + "px";
    }
  };

  const toggleMarker = (show: boolean) => {
    if (markerRef.current) {
      markerRef.current
        .setZIndexOffset(show ? 999999 : -1)
        .setOpacity(show ? 1 : 0);
    }
  };

  const setTime = (ms: number) => {
    if (timeElementRef.current) {
      timeElementRef.current.innerHTML = moment(ms).format("LT");
      const value = sliderElRef.current?.value || 0;
      const min = sliderElRef.current?.min || 0;
      const max = sliderElRef.current?.max || 0;
      const percent = ((+value - +min) / (+max - +min)) * 100;
      const amendmentPX = (15 / 100) * percent;
      timeElementRef.current.style.left = `calc(${percent}% - ${amendmentPX}px)`;
    }
  };

  const toggleEmployeeNames = () => {
    const mapContainerEl = map._container;
    if (mapContainerEl) {
      const employees =
        mapContainerEl.getElementsByClassName("playback-employee");
      const isContains = mapContainerEl.classList.contains(
        "show-playback-employee"
      );
      if (employees.length > 1) {
        if (!isContains) mapContainerEl.classList.add("show-playback-employee");
      } else {
        if (isContains)
          mapContainerEl.classList.remove("show-playback-employee");
      }
    }
  };

  const setMapView = (e: any) => {
    const { lat, lng } = e.latlng;

    const value = sliderElRef.current?.value || 0;
    if (!msRef.current) {
      msRef.current = +value;
    } else if (value && +value !== msRef.current) {
      if (map && lat && lng) {
        map.setView([lat, lng]);
        msRef.current = +value;
      }
    }
  };

  const destroySlider = () => {
    map.off("resize", invalidateSliderSize);
    if (mapTimelineRef.current) {
      mapTimelineRef.current.destroy();
      mapTimelineRef.current = null;
    }
    toggleEmployeeNames();
  };

  useEffect(() => {
    return () => {
      if (map) {
        destroySlider();
      }
    };
  }, [map]);

  const initSlider = (detailsActivity: IActivity[], employeeName: string) => {
    if (!map) return;
    if (mapTimelineRef.current) return;

    map.on("resize", invalidateSliderSize);
    const playBackTrack: {
      type: "Feature";
      geometry: {
        type: "MultiPoint";
        coordinates: number[][];
      };
      properties: {
        time: number[];
      };
    } = {
      type: "Feature",
      geometry: {
        type: "MultiPoint",
        coordinates: [],
      },
      properties: {
        time: [],
      },
    };
    for (let i = 0; i < detailsActivity.length; i++) {
      if (detailsActivity[i].Lat) {
        playBackTrack.geometry.coordinates.push([
          detailsActivity[i].Lng,
          detailsActivity[i].Lat,
        ]);
        playBackTrack.properties.time.push(
          new Date(detailsActivity[i].T).valueOf()
        );
      }
    }
    const playbackOptions = {
      sliderControl: true,
      tickLen: 150,
      tracksLayer: false,
      fadeMarkersWhenStale: true,
      marker: {
        icon: window.L.mapquest.icons.marker({
          primaryColor: MARKERS_COLORS.BLUE,
          secondaryColor: MARKERS_COLORS.WHITE,
          size: "lg",
        }),
      },
    };
    mapTimelineRef.current = new window.L.Playback(
      map,
      playBackTrack,
      setTime,
      playbackOptions
    );

    const sliderContainer = mapTimelineRef.current?.sliderControl?._container;
    if (sliderContainer) {
      const timeElement = window.document.createElement("div");
      timeElement.innerText = moment(detailsActivity[0].T).format("LT");
      timeElement.className = "playback-time";
      timeElementRef.current = timeElement;

      const sliderEl = sliderContainer.getElementsByClassName("slider")[0];
      const wrapper = document.createElement("div");
      wrapper.className = "playback-slider-wrapper";
      sliderContainer.insertBefore(wrapper, sliderEl);
      wrapper.appendChild(sliderEl);
      wrapper.appendChild(timeElement);

      sliderElRef.current = sliderEl;
      sliderContainer.addEventListener("mouseover", () => {
        map.dragging.disable();
        toggleMarker(true);
      });
      sliderContainer.addEventListener("mouseleave", () => {
        map.dragging.enable();
        toggleMarker(false);
      });

      sliderContainer.classList.add("playback-slider-container");
      const nameEl = document.createElement("div");
      nameEl.className = "playback-employee";
      nameEl.innerText = employeeName;
      sliderContainer.appendChild(nameEl);
      employeeNameRef.current = nameEl;
    }

    markerRef.current =
      mapTimelineRef.current?._trackController?._tracks[0]?._marker;
    markerRef.current.on("move", throttle(setMapView, 400));
    toggleMarker(false);
    invalidateSliderSize();
    toggleEmployeeNames();
    return () => {
      destroySlider();
    };
  };

  return {
    initSlider,
    destroySlider,
  };
};
