import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./map.module.scss";

interface IProps {
  isOn: boolean;

  onToggle(value: boolean): any;
}

const RelatedSitesControl = (props: IProps) => {
  const [isOn, setState] = useState(props.isOn);

  const toggle = () => {
    const newValue = !isOn;
    setState(newValue);
    props.onToggle(newValue);
  };

  return (
    <div className={styles.Controls}>
      <Button
        iconClass={
          isOn
            ? "mdi mdi-map-marker-multiple-outline"
            : "mdi mdi-map-marker-off-outline"
        }
        title={isOn ? "Hide Related Sites" : "Show Related Sites"}
        onClick={toggle}
      />
    </div>
  );
};

export default RelatedSitesControl;