import React, {Component} from "react";
import DashboardGrid from "../Dashboard/Grid";
import {IColumnSetting} from "../Dashboard/interfaces";
import {RunScriptLocalAsync} from "../../helpers/runscripts";
import {Button} from "@progress/kendo-react-buttons";
import {simpleObject} from "../../helpers/interfaces";
import DesktopActions from "../Common/DesktopActions";

function getCustomAggregate(
    field: string,
    gridData: Array<simpleObject>,
    isActiveField: string,
    idField?: string
) {
    let Ids: Array<string> = [];
    let sum = gridData.reduce(function (sum, current) {
        let id = idField && current[idField];
        let isUnic = !idField || Ids.indexOf(id) === -1;
        if (!current[isActiveField] || !isUnic) return sum;
        let value = current[field] || 0;
        if (idField) Ids.push(id);
        return sum + value;
    }, 0);
    return sum;
}

const COLUMNS: Array<IColumnSetting> = [
    {
        field: "SONumber",
        title: "Sales Order Number",
        type: "string",
        format: {
            type: "link",
            fieldId: "SOId",
            refName: "FSMQuotes",
        },
    },
    {
        field: "SODate",
        title: "Sales Order Date",
        type: "date",
        format: {
            type: "date",
        },
    },
    {
        field: "SOStatus",
        title: "Sales Order Status",
        type: "string",
        gridWidth: 135,
        format: {
            type: "string",
            fieldId: "SOStatus",
        },
    },
    {
        field: "SOIsQuoteOnlyStr",
        title: "Quote Only",
        type: "string",
        gridWidth: 100,
        format: {
            type: "string",
            fieldId: "SOIsQuoteOnly",
        },
    },
    {
        field: "SOTotal",
        title: "Sales Order Amount",
        type: "number",
        aggregate: "sum",
        gridWidth: 140,
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                return getCustomAggregate("SOTotal", gridData, "SOIsActive", "SOId");
            },
        },
    },
    {
        field: "SOPOAmount",
        title: "Sales Order Applied to PO Amount",
        type: "number",
        aggregate: "sum",
        gridWidth: 140,
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                let Ids: Array<string> = [];
                let sum = gridData.reduce(function (sum, current) {
                    let id = current.SOId + "_" + current.POId;
                    let isUnic = Ids.indexOf(id) === -1;
                    if (!current.InvoiceIsActive || !isUnic) return sum;
                    let value = current.SOPOAmount || 0;
                    Ids.push(id);
                    return sum + value;
                }, 0);
                return sum;
            },
        },
    },
    {
        field: "InvoiceTotal",
        title: "Invoice Total",
        type: "number",
        aggregate: "sum",
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                let sum = gridData.reduce(function (sum, current) {
                    if (!current.InvoiceIsActive) return sum;
                    let value = current.InvoiceTotal || 0;
                    return sum + value;
                }, 0);
                return sum;
            },
        },
    },
    {
        field: "SOBalance",
        title: "Sales Order Balance",
        type: "number",
        aggregate: "sum",
        minWidth: 100,
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                return getCustomAggregate("SOBalance", gridData, "SOIsActive", "SOId");
            },
        },
    },
    {
        field: "PONumber",
        title: "PO Number",
        type: "string",
        minWidth: 110,
        format: {
            type: "link",
            fieldId: "POId",
            refName: "FSMPO",
        },
    },
    {
        field: "PODate",
        title: "PO Date",
        type: "date",
        format: {
            type: "date",
        },
    },
    {
        field: "POTotal",
        title: "PO Amount",
        type: "number",
        aggregate: "sum",
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                return getCustomAggregate("POTotal", gridData, "POIsActive", "POId");
            },
        },
    },
    {
        field: "POBalance",
        title: "PO Balance",
        aggregate: "sum",
        type: "number",
        format: {
            type: "currency",
            customTotal: true,
            getCustomTotal: (gridData: Array<simpleObject>) => {
                return getCustomAggregate("POBalance", gridData, "POIsActive", "POId");
            },
        },
    },
    {
        field: "POStatus",
        title: "PO Status",
        type: "string",
        gridWidth: 100,
        format: {
            type: "string",
            fieldId: "POStatus",
        },
    },
    {
        field: "InvoiceNumber",
        title: "Invoice Number",
        type: "string",
        gridWidth: 120,
        format: {
            type: "link",
            fieldId: "InvoiceId",
            refName: "FSMInvoices",
        },
    },
    {
        field: "InvoiceDate",
        title: "Invoice Date",
        type: "date",
        format: {
            type: "date",
        },
    },
    {
        field: "InvoiceStatus",
        title: "Invoice Status",
        type: "string",
        gridWidth: 105,
        format: {
            type: "string",
            fieldId: "InvoiceStatus",
        },
    },
];

interface props {
    buildPlanId: number;
    isActive: boolean;
    doNotSaveFilters: boolean;

    onChangeGridData?(data: Array<simpleObject>): void;
}

class CIMSummary extends Component<props> {
    bpId: number = this.props.buildPlanId;
    gridRef: any = React.createRef();

    render() {
        if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
            this.bpId = this.props.buildPlanId;
        }

        if (
            !this.props.buildPlanId ||
            (!this.props.isActive && this.bpId !== this.props.buildPlanId)
        )
            return null;
        return (
            <DashboardGrid
                ref={this.gridRef}
                isActive={this.props.isActive}
                key={this.props.buildPlanId}
                columnsSettings={COLUMNS}
                getSqlParams={{
                    spName: "DB_CIM",
                    params: {buildPlanId: this.props.buildPlanId},
                }}
                prepareData={this.PrepareData}
                excelFileName="CIM Summary.xlsx"
                initRefresh={this.InitRefresh}
                columnMenu={{
                    filters: true,
                    columns: false,
                }}
                toolbar={{
                    actionBtns: (
                        <DesktopActions
                            actions={
                                <>
                                    <Button
                                        onClick={this.CreateNewOrder}
                                        data-component="FSMQuotes_CreateNew"
                                    >
                                        New Sales Order
                                    </Button>
                                    <Button
                                        onClick={this.CreateNewOrder}
                                        data-component="FSMPO_CreateNew"
                                    >
                                        New Purchase Order
                                    </Button>
                                </>
                            }
                        />
                    ),
                }}
                getRowKey={(dataItem: simpleObject) => dataItem.InvoiceId} // ???
                onChangeGridData={this.props.onChangeGridData}
            />
        );
    }

    PrepareData = (result: any) => {
        const data = result[0];
        for (let row of data) {
            row.SOIsQuoteOnlyStr = row.SOIsQuoteOnly ? "Yes" : "No";
        }
        return data;
    };

    Refresh = () => {
    };

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh;
    };

    CreateNewOrder = async (e: any) => {
        try {
            let name = e.currentTarget.dataset.component;
            await RunScriptLocalAsync(name, {BPID: this.props.buildPlanId});
        } finally {
            this.Refresh();
        }
    };
}

export default CIMSummary;
