import React, { Component } from "react";
import { isObjectForm } from "../../../helpers/settings";
import { ITCSummaryInfo } from "../interfaces";
import OpenCardLink from "../../../Components/Common/Buttons/OpenCardLink";
import styles from "./timecard.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import ButtonLink from "../../../Components/Common/Buttons/ButtonLink";
import CardManagement from "../../../Components/Cards/CardManagement";
import {IDefaultWO} from "./interfaces";
import {MultipleWo} from "./MultipleWo";

interface IProps {
  tcId: number;
  info: ITCSummaryInfo | null;
  defaultWOs: IDefaultWO[]
  refresh(): void;
}

interface IState {}

class TCInfo extends Component<IProps, IState> {
  render() {
    const {info} = this.props
    if (!info) return null;
    const showClockInOutInfo = !!info.FirstClockIn || !!info.LastClockOut;
    return (
      <div className={styles.TCInfoBox}>
        <div className={styles.TCRow}>
          {!isObjectForm && (
            <>
              <div
                className={styles.TCRow}
                style={
                  showClockInOutInfo
                    ? {
                        position: "relative",
                        paddingRight: 76,
                      }
                    : undefined
                }
              >
                <div className={styles.TCInfoCOL}>
                  {this.renderInfoRow(
                    info.Date,
                    info.EmployeeName,
                    info.EmployeeId,
                    "Employees"
                  )}
                  {this.renderInfoPeriod(info)}
                </div>

                <div className={styles.TCInfoCOL}>
                  {this.renderInfoRow(
                    "Payroll State",
                    info.PayrollStateName,
                    info.PayrollStateId,
                    "States"
                  )}
                  {this.renderInfoEmployeeCategory(info)}
                  {info.IsWageAllocationLocked && (
                      <div
                          className={styles.TCInfoCell}
                          style={{color: 'red'}}
                      >
                        Locked Allocation
                      </div>
                  )}
                </div>
                {this.renderInfoDefaultWO()}
                {showClockInOutInfo && (
                  <div className={styles.TCClockInOut}>
                    {!!info.FirstClockIn &&
                      this.renderInfoClockInOut(
                        "green",
                        info.FirstClockIn,
                        "PD",
                        info.OverClockIn
                      )}
                    {!!info.LastClockOut &&
                      this.renderInfoClockInOut(
                        "red",
                        info.LastClockOut,
                        "ND",
                        info.OverClockOut
                      )}
                  </div>
                )}
              </div>

              <div className={styles.TCRow}>
                {this.renderInfoRow(
                  info.CanChangeReason ? (
                    <ButtonLink text={"Reason"} onClick={this.OpenReasonCard} />
                  ) : (
                    "Reason"
                  ),
                  info.ReasonName,
                  info.ReasonId,
                  "TKTimeCardReasons"
                )}
              </div>
            </>
          )}

          {isObjectForm && (
            <div className={styles.TCRow}>
              {this.renderInfoPeriod(info)}
              {this.renderInfoEmployeeCategory(info)}
              {info.IsWageAllocationLocked && (
              <div
                  className={styles.TCInfoCell}
                  style={{color: 'red'}}
              >
                Locked Allocation
              </div>
              )}
              {this.renderInfoDefaultWO()}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderInfoTooltip = () => {
    let info = this.props.info;
    if (!info) return null;
    return (
      <>
        <div className={styles.TooltipRow}>
          <span className={styles.TooltipRowName}></span>
          <span className={styles.TooltipColName}>Available to Request</span>
          <span className={styles.TooltipColName}>Requested, Not Completed</span>
        </div>
        {this.renderTooltipRow(
          "Sick",
          info.SickHoursAvailableToRequest || 0,
          info.SickHoursRequestedNotCompleted || 0,
        )}
        {this.renderTooltipRow(
          "Vacation",
          info.VacationHoursAvailableToRequest || 0,
          info.VacationHoursRequestedNotCompleted || 0
        )}
      </>
    );
  };

  renderTooltipRow = (
    rowName: string,
    availableToRequest: number,
    requestedNotCompleted: number,
  ) => {
    return (
      <div className={styles.TooltipRow}>
        <span className={styles.TooltipRowName}>{rowName}</span>
        <span className={styles.TooltipValue}>{availableToRequest.toFixed(2)}</span>
        <span className={styles.TooltipValue}>{requestedNotCompleted.toFixed(2)}</span>
      </div>
    );
  };

  renderInfoClockInOut = (
    color: string,
    time: string,
    overName: "PD" | "ND",
    isOver?: boolean
  ) => {
    return (
      <div className={styles.TCInfoCell}>
        {isOver && (
          <small
            className={styles.TCInfoName}
            title={overName === "PD" ? "Previous Day" : "Next Day"}
          >
            {overName}
          </small>
        )}
        <span className="k-icon k-i-round-corners" style={{ color }}></span>
        <span className={styles.TCInfoTime}>{time}</span>
      </div>
    );
  };

  renderInfoPeriod = (info: ITCSummaryInfo) => {
    return this.renderInfoRow(
      "Period",
      info.PeriodName,
      info.PeriodId,
      "TKPayrollPeriods"
    );
  };

  renderInfoEmployeeCategory = (info: ITCSummaryInfo) => {
    return this.renderInfoRow(
      "Employee category",
      info.EmployeeCategoryName,
      info.EmployeeCategoryId,
      "EmployeeCategories"
    );
  };

  renderInfoDefaultWO = () => {
    const {defaultWOs} = this.props
    if (!defaultWOs.length) return null;

    if (defaultWOs.length === 1) {
      const { DefaultWOId, DefaultWOName } = defaultWOs[0];
      return this.renderInfoRow(
          "Default WO",
          DefaultWOName,
          DefaultWOId,
          "FSMWorkOrders"
      );
    }

      return (
          <div className={styles.TCInfoCell}>
            <span className={styles.TCInfoName}>Default WO:</span>
            <MultipleWo workOrders={defaultWOs} />
          </div>
      );
  };

  renderInfoRow = (
    Name: any,
    linkName: string | null,
    linkId: number | null,
    refName: string
  ) => {
    return (
      <div className={styles.TCInfoCell}>
        <span className={styles.TCInfoName}>{Name}:</span>
        {!!linkName && !!linkId && (
          <OpenCardLink text={linkName} refName={refName} dataAttr={linkId} />
        )}
        {refName === "Employees" && (
          <div
            title="Available Hours"
            style={{
              position: "relative",
              width: 35,
              height: 16,
            }}
          >
            <Tooltip
              anchorElement="target"
              position="bottom"
              parentTitle={true}
              style={{ width: "230px" }}
              tooltipStyle={{ marginTop: "-10px" }}
              showCallout={true}
              content={this.renderInfoTooltip}
            >
              <Button
                iconClass="mdi mdi-information-outline"
                fillMode="flat"
                className={styles.InfoBtn}
                style={{
                  position: "absolute",
                  right: 0,
                  top: -6,
                }}
              ></Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  OpenReasonCard = () => {
    const { ReasonId: Id, ReasonName: Name } = this.props.info || {};
    CardManagement.OpenTCReasonCard({
      tcId: this.props.tcId,
      reason: !!Name && !!Id ? { Id, Name } : null,
      onFinish: this.props.refresh,
    });
  };
}

export default TCInfo;
