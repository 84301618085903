import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import FXCard from "../Common/FXCard/FXCard";
import FilterCombobox from "../Common/Form/FilterCombobox";
import styles from "./card.module.scss";
import { IAddWOChecklistCardProps } from "./interfaces";
import React, { useEffect, useRef, useState } from "react";
import { getSQLData } from "../../helpers/queries";
import { IComboboxItem } from "../../helpers/interfaces";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import CLPMSettings from "../../stores/CLPMSettings";
import { ICLPMWOItem } from "../../stores/interfaces";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { ModalRef } from "../Common/Modal/Modal";
import { useBooleanState } from "../../helpers/hooks";
import { RunScriptAsync } from "../../helpers/runscripts";
import LoaderComponent from "../Common/Loader";
import MobilePopup from "../Common/MobilePopup/Index";
import formStyles from "../Common/Form/form.module.scss";
import { getRequestErrorText } from "../../helpers/axiosHelpers";

interface ITemplate {
  CheckListTemplateId: number;
  CheckListTemplateName: string;
}

interface IClassifierItem {
  Id: number;
  Name: string;
  Edit: boolean;
}

interface IResult {
  id: number;
  success: boolean;
  message?: string;
}

const delimitedClassifiersInstrunction = (
  <div style={{ padding: "4px 4px 8px" }}>
    Put the list of Classifiers with following delimiters: semicolon, new line.
  </div>
);

export default function AddWOChecklistCard(props: IAddWOChecklistCardProps) {
  const { isMobile } = props;
  const isProcessing = useBooleanState(false);
  const isLoadingBPsandWOs = useBooleanState(false);
  const isLoadingTemplates = useBooleanState(false);
  const isMobileMultiplePopup = useBooleanState(false);
  const mobileMultipleFillTextareaValueRef = useRef("");
  const [buildPlans, setBuildPlans] = useState<IComboboxItem[]>([]);
  const [filteredWorkOrders, setFilteredWorkOrders] = useState<ICLPMWOItem[]>(
    []
  );
  const [templates, setTemplates] = useState<ITemplate[]>();

  const bpIdRef = useRef<number | null>(null);
  const [buildPlan, setBuildPlan] = useState<IComboboxItem | null>(null);
  const [workOrder, setWorkOrder] = useState<ICLPMWOItem | null>(null);
  const [template, setTemplate] = useState<ITemplate | null>(null);

  const [isValidForm, setIsValidForm] = useState(false);
  const valuesRef = useRef<{
    WOID: number | null;
    ChecklistTemplateId: number | null;
    Classifiers: IClassifierItem[];
  }>({
    WOID: null,
    ChecklistTemplateId: null,
    Classifiers: [
      {
        Id: 1,
        Name: "",
        Edit: true,
      },
    ],
  });
  const [classifiers, setClassifiers] = useState<IClassifierItem[]>(
    valuesRef.current.Classifiers
  );

  useEffect(() => {
    isLoadingBPsandWOs.setTrue();
    CLPMSettings.getActiveBuildPlansAndWorkOrders()
      .then(({ buildPlans, workOrders }) => {
        const activeBuildPlans = buildPlans.filter((bp) => bp.IsActive);
        let bp = props.buildPlanId
          ? activeBuildPlans.find((bp) => bp.Id === props.buildPlanId)
          : null;
        if (!bp && activeBuildPlans.length === 1) bp = activeBuildPlans[0];
        if (bp) {
          bpIdRef.current = +bp.Id;
          setBuildPlan(bp);
        }
        setBuildPlans(activeBuildPlans);
        prepareSetFilteredWorkOrders(
          workOrders,
          bp ? +bp.Id : null,
          props.workOrderId
        );
      })
      .finally(isLoadingBPsandWOs.setFalse);
  }, []);

  useEffect(() => {
    const woId = workOrder?.Id;
    if (!woId) {
      valuesRef.current.ChecklistTemplateId = null;
      setTemplate(null);
      setTemplates([]);
      return;
    }
    isLoadingTemplates.setTrue();
    getSQLData({
      spName: "CLPM_GetWOChecklistTemplates",
      params: { woId },
    })
      .then((result) => {
        setTemplates(result[0]);
        if (result[0].length === 1) {
          setTemplate(result[0][0]);
          valuesRef.current.ChecklistTemplateId =
            result[0][0].CheckListTemplateId;
        } else {
          setTemplate(null);
          valuesRef.current.ChecklistTemplateId = null;
        }
      })
      .finally(isLoadingTemplates.setFalse);
  }, [workOrder]);

  const createWOChecklistItem = async (
    Name: string,
    Id: number,
    results: IResult[]
  ) => {
    try {
      await RunScriptAsync(
        "CLPM_CreateWOChecklist",
        {
          WOID: valuesRef.current.WOID,
          ChecklistTemplateId: valuesRef.current.ChecklistTemplateId,
          AdditionalClassifier: Name,
        },
        undefined,
        true
      );
      results.push({ id: Id, success: true });
    } catch (error) {
      results.push({
        id: Id,
        success: false,
        message: `${Name}: ${getRequestErrorText(error)}`,
      });
    } finally {
      if (results.length === valuesRef.current.Classifiers.length) {
        const errorResults = results.filter((r) => !r.success);
        if (!errorResults.length) {
          changeClassifiersArr([]);
          ModalRef.showDialog({
            title: "Success",
            type: "success",
            text: "All Checklists are created",
          });
          props.finally?.();
        } else {
          const errorClassifiers = valuesRef.current.Classifiers.filter(
            (item) => {
              return (
                errorResults.findIndex((result) => {
                  return result.id === item.Id && !result.success;
                }) > -1
              );
            }
          );
          ModalRef.showDialog({
            title: "Error",
            type: "error",
            text: "Not all Checklists are created",
            children: (
              <div style={{ marginTop: 4 }}>
                {errorResults.map((r) => (
                  <div key={r.id} style={{ padding: "2px 0" }}>
                    {r.message}
                  </div>
                ))}
              </div>
            ),
          });
          changeClassifiersArr(errorClassifiers);
        }
        props.onFinish();
        isProcessing.setFalse();
      }
    }
  };

  const createWOChecklists = () => {
    isProcessing.setTrue();
    const results: IResult[] = [];
    valuesRef.current.Classifiers = valuesRef.current.Classifiers.filter(
      (c) => !!c.Name
    );
    for (let item of valuesRef.current.Classifiers) {
      createWOChecklistItem(item.Name, item.Id, results);
    }
  };

  const changeClassifiersArr = (arr: IClassifierItem[]) => {
    setClassifiers(arr);
    valuesRef.current.Classifiers = arr;
    checkIsValidForm();
  };

  const parseTextareaValue = (value: string) => {
    const ms = +new Date();
    const arr = value
      .split(/\n|;/)
      .filter((str: string) => !!str)
      .map((str: string, index: number) => ({
        Id: ms + index,
        Name: str,
        Edit: false,
      }));
    changeClassifiersArr([...classifiers, ...arr]);
  };

  const OpenMultipleFill = () => {
    if (isMobile) {
      isMobileMultiplePopup.setTrue();
      mobileMultipleFillTextareaValueRef.current = "";
      return;
    }
    const textareaRef: any = React.createRef();
    ModalRef.showDialog({
      title: "Delimited Classifiers",
      buttons: [
        {
          text: "Cancel",
          action: () => ModalRef.hideDialog(),
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            const value = textareaRef.current?.element.current.value;
            if (value) {
              parseTextareaValue(value);
              ModalRef.hideDialog();
            }
          },
        },
      ],
      children: (
        <div>
          {delimitedClassifiersInstrunction}
          <TextArea ref={textareaRef} placeholder={""} rows={6}></TextArea>
        </div>
      ),
    });
  };

  const getIsValidForm = () => {
    return (
      !!valuesRef.current.WOID &&
      !!valuesRef.current.ChecklistTemplateId &&
      !!valuesRef.current.Classifiers.find((item) => !!item.Name.length)
    );
  };

  const checkIsValidForm = () => {
    setIsValidForm(getIsValidForm());
  };

  const prepareSetFilteredWorkOrders = (
    workOrders: ICLPMWOItem[],
    bpId?: number | null,
    woId?: number | null
  ) => {
    const filteredWorkOrders = workOrders.filter(
      (wo) =>
        wo.IsActive && (!bpIdRef.current || wo.BuildPlanId === bpIdRef.current)
    );
    setFilteredWorkOrders(filteredWorkOrders);
    const currentWoId = valuesRef.current.WOID;
    let wo = currentWoId
      ? filteredWorkOrders.find((wo) => wo.Id === currentWoId)
      : null;
    if (!wo && filteredWorkOrders.length === 1) wo = filteredWorkOrders[0];
    valuesRef.current.WOID = wo ? +wo.Id : null;
    setWorkOrder(wo || null);
  };

  const renderCardContent = () => {
    return (
      <>
        <FilterCombobox
          placeholder="Build Plans"
          data={buildPlans}
          value={buildPlan}
          onChange={(value: IComboboxItem | null) => {
            bpIdRef.current = value ? +value.Id : null;
            setBuildPlan(value);
            CLPMSettings.getActiveWorkOrders().then((workOrders) => {
              prepareSetFilteredWorkOrders(
                workOrders,
                bpIdRef.current,
                valuesRef.current.WOID
              );
              checkIsValidForm();
            });
          }}
          className={styles.FormField}
          popupSettings={
            isMobile ? { popupClass: styles.MobileComboboxPopup } : undefined
          }
          loading={isLoadingBPsandWOs.value}
        />
        <FilterCombobox
          placeholder="Work Orders *"
          data={filteredWorkOrders}
          value={workOrder}
          onChange={(wo: ICLPMWOItem | null) => {
            valuesRef.current.WOID = wo ? +wo.Id : null;
            setWorkOrder(wo);
            checkIsValidForm();
          }}
          className={styles.FormField}
          popupSettings={
            isMobile ? { popupClass: styles.MobileComboboxPopup } : undefined
          }
          loading={isLoadingBPsandWOs.value}
        />
        <FilterCombobox
          placeholder="CheckList Templates *"
          data={templates}
          value={template}
          onChange={(template: ITemplate | null) => {
            valuesRef.current.ChecklistTemplateId = template
              ? +template.CheckListTemplateId
              : null;
            setTemplate(template);
            checkIsValidForm();
          }}
          className={styles.FormField}
          popupSettings={
            isMobile ? { popupClass: styles.MobileComboboxPopup } : undefined
          }
          loading={isLoadingTemplates.value}
          dataItemKey={"CheckListTemplateId"}
          textField={"CheckListTemplateName"}
          disabled={!workOrder}
        />

        <Grid
          style={{ height: "250px" }}
          data={classifiers}
          editField="Edit"
          onRowClick={(event: GridRowClickEvent) => {
            classifiers.forEach(
              (item) => (item.Edit = item.Id === event.dataItem.Id)
            );
            changeClassifiersArr([...classifiers]);
          }}
          onItemChange={(event: GridItemChangeEvent) => {
            const item = classifiers.find(
              (item) => item.Id === event.dataItem.Id
            );
            item!.Name = event.value;
            changeClassifiersArr([...classifiers]);
          }}
        >
          <GridColumn
            field="Name"
            title="Classifiers"
            width={390}
            headerCell={(props) => (
              <div className={styles.Row}>
                <div
                  className={`${styles.FormFieldLabel} ${styles.AlignCenter}`}
                >
                  {props.title} *
                </div>
                <ToolbarSpacer />
                <Button
                  fillMode={"flat"}
                  style={{ marginLeft: 8 }}
                  iconClass={"mdi mdi-format-list-group-plus"}
                  title={"Add multiple lines"}
                  onClick={OpenMultipleFill}
                />
                <Button
                  icon={"plus"}
                  fillMode={"flat"}
                  style={{ marginLeft: 8 }}
                  title={"Add new line"}
                  onClick={() => {
                    classifiers.forEach((item) => (item.Edit = false));
                    changeClassifiersArr([
                      {
                        Id: +new Date(),
                        Name: "",
                        Edit: true,
                      },
                      ...classifiers,
                    ]);
                  }}
                />
              </div>
            )}
          />
          <GridColumn
            field="Id"
            title={" "}
            headerCell={(props) => (
              <div style={{ textAlign: "center" }}>
                {!!classifiers.length && (
                  <Button
                    icon={"close"}
                    fillMode={"flat"}
                    title={"Remove All"}
                    onClick={() => {
                      changeClassifiersArr([]);
                    }}
                  />
                )}
              </div>
            )}
            cell={(props: GridCellProps) => {
              return (
                <td style={{ textAlign: "center" }}>
                  <Button
                    icon={"minus"}
                    fillMode={"flat"}
                    size={"small"}
                    themeColor={"base"}
                    style={{ color: "#c2c2c2" }}
                    onClick={() => {
                      changeClassifiersArr(
                        classifiers.filter(
                          (item) => item.Id !== props.dataItem.Id
                        )
                      );
                    }}
                  />
                </td>
              );
            }}
          />
        </Grid>
      </>
    );
  };

  if (isMobile) {
    return (
      <>
        <MobilePopup
          height={480}
          width={520}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
          onHide={props.finally}
        >
          <div className={formStyles.MobileForm}>
            {isProcessing.value && <LoaderComponent />}
            {renderCardContent()}
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                justifyContent: "center",
                height: 55,
              }}
            >
              {isValidForm && (
                <Button
                  onClick={createWOChecklists}
                  themeColor="primary"
                  disabled={/*!isValidForm || */ isProcessing.value}
                  size={"large"}
                >
                  Create Checklists
                </Button>
              )}
              {!isValidForm && (
                <span className={formStyles.InvalidMessage}>
                  <span>Fill in all mandatory fields</span>
                </span>
              )}
            </div>
          </div>
        </MobilePopup>

        {isMobileMultiplePopup.value && (
          <MobilePopup
            height={195}
            width={350}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              overflow: "auto",
            }}
            onHide={() => {
              isMobileMultiplePopup.setFalse();
            }}
          >
            <div
              className={formStyles.MobileForm}
              style={{ width: "100%", height: "100%" }}
            >
              <div>
                <TextArea
                  placeholder={""}
                  rows={6}
                  onChange={(e: TextAreaChangeEvent) =>
                    (mobileMultipleFillTextareaValueRef.current = e.value)
                  }
                ></TextArea>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "space-between",
                    paddingTop: 8,
                  }}
                >
                  {delimitedClassifiersInstrunction}
                  <Button
                    themeColor="primary"
                    size={"large"}
                    onClick={() => {
                      const value = mobileMultipleFillTextareaValueRef.current;
                      if (value) {
                        parseTextareaValue(value);
                        mobileMultipleFillTextareaValueRef.current = "";
                        isMobileMultiplePopup.setFalse();
                      }
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </MobilePopup>
        )}
      </>
    );
  }

  return (
    <FXCard
      title={"New Checklist"}
      onClose={props.finally}
      initialWidth={480}
      initialHeight={450}
      originalPaddings={true}
    >
      <div className={styles.FormWrapper}>
        {isProcessing.value && <LoaderComponent />}
        {renderCardContent()}
        <div className={`${styles.FormFooter} k-action-buttons`}>
          <Button onClick={props.finally}>Cancel</Button>
          <Button
            onClick={createWOChecklists}
            themeColor="primary"
            disabled={!isValidForm || isProcessing.value}
          >
            Create Checklists
          </Button>
        </div>
      </div>
    </FXCard>
  );
}
