import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

interface props {
  title: string;
  content: any;
  className?: string;
}

export default function FXExpansionPanel(props: props) {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <ExpansionPanel
      className={props.className}
      title={props.title}
      // subtitle={item.continent}
      expanded={expanded}
      tabIndex={0}
      onAction={(event: ExpansionPanelActionEvent) => {
        setExpanded(!event.expanded);
      }}
    >
      <Reveal>
        {expanded && (
          <ExpansionPanelContent>
            <div className="content">{props.content}</div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
}
