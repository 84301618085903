import { AxiosRequestConfig } from "axios";
import moment from "moment";
import { DataServerHost } from "./settings";
import { axiosQuery, showRequestError } from "./axiosHelpers";
import AuthData from "./authData";
import { SasTokenType } from "./interfaces";

export const SasTokenTypes: {
  [key in SasTokenType]: SasTokenType;
} = {
  ViewWorkResults: "ViewWorkResults",
  ViewFieldPublished: "ViewFieldPublished",
  ViewFieldNotPublished: "ViewFieldNotPublished",
  ViewTkPhotos: "ViewTkPhotos",
  ViewFinancial: "ViewFinancial",
};

class FileAccessToken {
  tokens: {
    [key in SasTokenType]?: {
      token: string;
      expires: moment.Moment;
      objectId: number;
      userId: number;
    };
  } = {};

  SaveFileAccessToken = (
    type: SasTokenType,
    token: string,
    expires: moment.Moment,
    objectId: number
  ) => {
    this.tokens[type] = {
      token,
      expires,
      objectId,
      userId: AuthData.currentUserId!,
    };
  };

  ClearFileAccessToken = (type: SasTokenType) => {
    delete this.tokens[type];
  };

  ClearAllTokens = () => {
    for (let [key, type] of Object.entries(SasTokenTypes)) {
      this.ClearFileAccessToken(type);
    }
  };

  GetFileAccessToken = async (type: SasTokenType, objectId: number) => {
    let tokenInfo = this.tokens[type];
    if (
      !!tokenInfo &&
      tokenInfo.token &&
      tokenInfo.expires &&
      tokenInfo.objectId === objectId &&
      tokenInfo.userId === AuthData.currentUserId &&
      tokenInfo.expires.diff(moment(), "minutes") > 5
    ) {
      return tokenInfo.token;
    }

    this.ClearFileAccessToken(type);

    let data = await AuthData.queueGetAuthToken();
    if (data && data.length && data[0] && data[1]) {
      let access_token = data[0];
      let token_type = data[1];
      let expiresIn = 60 * 60;
      let expires = moment().add(expiresIn, "seconds");
      let url = `https://${DataServerHost}/api/service/GetSasQueryString?type=${type}&objectId=${objectId}&expiresIn=${expiresIn}`;
      let axiosConfig: AxiosRequestConfig = {
        method: "get",
        url: url,
        headers: {
          Authorization: `${token_type} ${access_token}`,
          timestamp: moment().format(),
        },
      };
      try {
        let token = await axiosQuery(axiosConfig);
        if (token) {
          this.SaveFileAccessToken(type, token.queryString, expires, objectId);
          return token.queryString;
        }
      } catch (error: any) {
        let title =
          error.response && error.response.status === 403
            ? "Access Denied"
            : "Get File Access Token Error:";
        showRequestError(error, title);
      }
    }
  };
}

export default new FileAccessToken()
