import VIMReview from "../VIMDashboard/VIMDashboard";
import VIMInvoices from "../VIMDashboard/Invoices";
import PLDashboard from "../PLDashboard/PLDashboard";
import LaborDashboard from "../LaborDashboard";
import WIPDashboard from "../WIPDashboard";
import WebIframe from "../../Components/WebIframe";
import DailyDashboard from "../DailyDashboard/DailyDashboard";
import CrewLeadSummary from "../CrewLeadSummary/Index";
import ExecutiveDailyDashboard from "../ExecutiveDailyDashboard/ExecutiveDailyDashboard";
import StatusDashboard from "../StatusDashboard";
import InventoryDashboard from "../InventoryDashboard";
import ActionsHistory from "../ActionsHistory";
import Milestones from "../Milestones/Milestones";
import CalendarComponent from "../../Components/Frames/CalendarComponent";
import LiveMap from "../LiveMap/LiveMap";
import CLM from "../CLM/CLM";
import BPDashboard from "../BPDashboard/BPDashboard";
import ExternalLinks from "../ExternalLinks/ExternalLinks";
import BudgetDashboard from "../BudgetDashboard/BudgetDashboard";
import TKDashboard from "../TKDashboard/Index";
import WorkOrdersReport from "../../Components/BP/WorkOrders";
import { IPage, pageId } from "./interfaces";
import EmployeesDashboard from "../EmployeesDashboard";
import TKAuditCover from "../TKReview";

export const PAGES: { [key in pageId]: IPage } = {
  StatusDashboard: {
    component: StatusDashboard,
  },
  DailyDashboard: {
    component: DailyDashboard,
  },
  Milestones: {
    component: Milestones,
  },
  FXApp: {
    component: WebIframe, // todo
  },
  DB_Employees: {
    component: EmployeesDashboard,
  },
  TKAuditCover: {
    component: TKAuditCover,
  },
  Calendar: {
    component: CalendarComponent,
  },
  VIM_Invoices: {
    component: VIMInvoices,
  },
  VIM_Review: {
    component: VIMReview,
  },
  CLPM: {
    component: CLM,
  },
  CrewLeadDashboard: {
    component: CrewLeadSummary,
  },
  BPDashboard: {
    component: BPDashboard,
  },
  ExecutiveDailyDashboard: {
    component: ExecutiveDailyDashboard,
  },
  LaborDashboard: {
    component: LaborDashboard,
  },
  WorkOrdersReport: {
    component: WorkOrdersReport,
  },
  WIPDashboard: {
    component: WIPDashboard,
  },
  PLDashboard: {
    component: PLDashboard,
  },
  InventoryDashboard: {
    component: InventoryDashboard,
  },
  LiveMap: {
    component: LiveMap,
  },
  ActionsHistory: {
    component: ActionsHistory,
  },
  ExternalLinks: {
    component: ExternalLinks,
  },
  BudgetDashboard: {
    component: BudgetDashboard,
  },
  TKDashboard: {
    component: TKDashboard,
  },
};
