import React, { Component } from "react";
import { simpleObject } from "../../helpers/interfaces";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting, IDashboardChildProps } from "../Dashboard/interfaces";

const COLUMNS: IColumnSetting[] = [
  {
    field: "MaterialName",
    title: "Material",
    type: "string",
    locked: true,
    format: {
      type: "link",
      fieldId: "MaterialId",
      refName: "FSMMaterials",
    },
  },
  {
    field: "PartNo",
    title: "Part No",
    type: "string",
    gridWidth: 110,
    locked: true,
    format: {
      type: "string",
    },
  },
  {
    field: "MaterialCategoryName",
    title: "Material Category",
    type: "string",
    gridWidth: 200,
    locked: true,
    format: {
      type: "link",
      fieldId: "MaterialCategoryId",
      refName: "MaterialCategories",
    },
  },
  {
    field: "UOMName",
    title: "UOM",
    type: "string",
    gridWidth: 110,
    locked: true,
    format: {
      type: "link",
      fieldId: "UOMId",
      refName: "UOM",
    },
  },
  {
    title: "QTY",
    type: "parent",
    expandedByDefault: true,
    columns: [
      {
        field: "QTYTotal",
        title: "Total",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
      {
        field: "QTYPending",
        title: "Pending",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
      {
        field: "QTYSentToWarehouse",
        title: "Sent to Warehouse",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
      {
        field: "QTYKitted",
        title: "Kitted",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
      {
        field: "QTYCompleted",
        title: "Completed",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
      {
        field: "QTYReturned",
        title: "Returned",
        type: "number",
        format: {
          type: "integer",
        },
        aggregate: "sum",
      },
    ],
  },
  {
    field: "TotalCOGs",
    title: "Total COGs",
    type: "number",
    format: {
      type: "currency",
    },
    aggregate: "sum",
  },
];

class BOMDetails extends Component<IDashboardChildProps> {
  gridRef: any = React.createRef();
  bpId = this.props.rowData?.BPID;

  render() {
    if (this.props.isActive && this.bpId !== this.props.rowData?.BPID) {
      this.bpId = this.props.rowData?.BPID;
    }

    if (
      !this.props.rowData?.BPID ||
      (!this.props.isActive && this.bpId !== this.props.rowData?.BPID)
    )
      return null;

    return (
      <DashboardGrid
        isActive={this.props.isActive}
        ref={this.gridRef}
        key={this.bpId}
        columnsSettings={COLUMNS}
        getSqlParams={{
          spName: "DB_BOMDetails",
          params: { buildPlanId: this.bpId },
        }}
        excelFileName="BOM_Details.xlsx"
        initRefresh={this.InitRefresh}
        isParentPage={this.props.parentId === "InventoryDashboard"}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.MaterialId}
        toolbar={{
          switcher: this.props.props?.switcher,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default BOMDetails
