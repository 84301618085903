import React from "react";
import moment, { Moment } from "moment";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Offset, Popup } from "@progress/kendo-react-popup";
import { TextArea } from "@progress/kendo-react-inputs";
import {
  TimePicker,
  TimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  costTypeCode,
  IAdjustedTimeLineItem,
  IAdjustmentAllocation,
  IAdjustmentEditPanelProps,
} from "../../../Pages/TKReview/interfaces";
import {
  formatHoursDuration,
  getActualDuration,
} from "../../../helpers/helpers";
import formStyles from "../../Cards/card.module.scss";
import styles from "./adjustmentsEdit.module.scss";
import { getColorByCostType } from "../../../Pages/TKReview/helpers";
import BaseComponent from "../../BaseComponent";
import LoaderComponent from "../../Common/Loader";
import { ModalRef } from "../../Common/Modal/Modal";
import CardManagement from "../../Cards/CardManagement";
import {
  ICostType,
  IListWOItem, IStaticWO,
} from "../../Cards/interfaces";
import DurationInput from "../../Common/Form/DurationInput";
import AdjustmentEditRow from "./AdjustmentEditRow";
import { getDefaultStateCode } from "./helpers";
import UserInfo from "../../../stores/User";
import { RunScriptAsync } from "../../../helpers/runscripts";

interface IUpdateItem {
  Start: string;
  Finish: string;
  ApprovedDuration: number;
  CostTypeCode: costTypeCode;
  Allocation: Array<IUpdateAllocationItem>;
  StateCode: string | null;
}

interface IUpdateAllocationItem {
  WorkOrderCode: string;
  Percentage: number;
}

interface state {
  processing: boolean;
  costTypes: ICostType[];
  showCostypeMenu: boolean;
  offsetCostTypeMenu?: Offset;
  costTypeAdjustment?: IAdjustedTimeLineItem;
  messages: string[];
  invalidForm: boolean;
  totalApprovedDuration: string;
  totalActualDuration: string;
  totalDuration: string;
  remountKey: number;
  remountKeyAdjustment: number;
  availableStates: string[];
  workOrders: IListWOItem[];
  staticWOs: IStaticWO[]
}

type adjustmentsAction =
  | "restore"
  | "split"
  | "delete"
  | "add"
  | "undo"
  | "clearAll";
const FORMAT = "YYYY-MM-DDTHH:mm:ss";
const costTypeCodeDefault: costTypeCode = "EMPTY";

class AdjustmentsEdit extends BaseComponent<IAdjustmentEditPanelProps, state> {
  ShowStateAllocation: boolean = false;
  adjustments: Array<IAdjustedTimeLineItem> = [];
  lunchDuration: number | null = this.props.adjustmentsInfo.LunchDuration;
  Comment: string | null = this.props.adjustmentsInfo.Comment || null;
  totalApprovedDuration: number = 0;
  validation: {
    costType: boolean;
    allocation: boolean;
    invalidTime: boolean;
  } = {
    costType: false,
    allocation: false,
    invalidTime: false,
  };

  constructor(props: IAdjustmentEditPanelProps) {
    super(props);
    this.state = {
      processing: false,
      costTypes: [],
      showCostypeMenu: false,
      offsetCostTypeMenu: undefined,
      messages: [],
      invalidForm: true,
      totalApprovedDuration: "",
      totalActualDuration: "",
      remountKey: +new Date(),
      remountKeyAdjustment: +new Date(),
      totalDuration: "",
      availableStates: [],
      workOrders: [],
      staticWOs: [],
    };
  }

  async componentDidMount() {
    const userInfo = await UserInfo.getInfo();
    this.ShowStateAllocation = !!userInfo?.ShowStateAllocation;
    this.LoadData();
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    document.removeEventListener("click", this.CloseCostTypeSelect);
  }

  render() {
    const { adjusmentsWaivedLunches } = this.props;
    const { processing, remountKey } = this.state;
    return (
      <>
        {processing && <LoaderComponent />}
        {this.renderToolbar()}
        {!!adjusmentsWaivedLunches.length && (
          <div>
            Waived Lunches:{" "}
            {adjusmentsWaivedLunches.map((l, i) => (
              <span className={styles.WaivedLunch} key={l + i}>
                {l}
              </span>
            ))}
          </div>
        )}
        {!processing && this.renderAdjustments()}
        {this.renderCostTypeContextMenu()}
        <TextArea
          key={"comment" + remountKey}
          rows={5}
          placeholder="Comment..."
          defaultValue={this.Comment || ""}
          className={styles.AdjustmentsCardComment}
          onChange={this.OnChangeComment}
        ></TextArea>
        {this.renderFooter()}
      </>
    );
  }

  renderToolbar = () => {
    const {
      totalActualDuration,
      totalApprovedDuration,
      totalDuration,
      remountKey,
    } = this.state;

    return (
      <div className={styles.AdjustmentCardToolbar}>
        <div className={styles.DurationItem}>
          <span>Clocked</span>
          <Chip text={totalActualDuration} />
        </div>
        <div className={styles.DurationItem}>
          <span>Approved</span>
          <Chip themeColor="success" text={totalApprovedDuration} />
        </div>
        <div className={styles.DurationItem}>
          <span>Lunch Deduction</span>
          <div style={{ width: 58, marginTop: 4 }}>
            <DurationInput
              key={"lunch" + remountKey}
              duration={this.lunchDuration}
              onChange={this.OnChangeLunchDuration}
              start={""}
              finish={""}
            />
          </div>
        </div>
        <div className={styles.DurationItem}>
          <span>Total</span>
          <Chip themeColor="success" text={totalDuration} />
        </div>
        <div style={{ flex: 1 }}></div>
        <Button
          iconClass={"mdi mdi-trash-can-outline"}
          title={"Clear All"}
          onClick={this.ClearAll}
        />
        <Button
          iconClass={"mdi mdi-history"}
          title={"Restore All"}
          onClick={this.RestoreAll}
        />
        <Button
          iconClass={"mdi mdi-database-clock"}
          title={"Allocate All"}
          onClick={this.AllocateAll}
        />
        <Button
          iconClass={"mdi mdi-database-clock-outline"}
          title={"Allocate Unallocated"}
          onClick={this.AllocateUnallocated}
        />
      </div>
    );
  };

  renderFooter = () => {
    const { messages, invalidForm } = this.state;
    return (
      <div className={`${formStyles.FormFooter} k-action-buttons`}>
        <span className={formStyles.InvalidMessage}>
          {messages.map((message, i) => {
            return <span key={i}>{message}</span>;
          })}
        </span>
        {this.props.closeCardButton}
        <Button onClick={this.UndoChanges}>Undo Changes</Button>
        <Button onClick={this.Save} themeColor="primary" disabled={invalidForm}>
          Save
        </Button>
      </div>
    );
  };

  renderAdjustments = () => {
    let adjustments = this.adjustments;
    return (
      <div className={styles.ListBox}>
        {adjustments.map((data: IAdjustedTimeLineItem, i: number) => {
          let prevAdjustment = adjustments[i - 1];
          let nextAdjustment = adjustments[i + 1];
          let splitTimeLine =
            nextAdjustment && nextAdjustment.Start !== data.Finish;
          let PrevSplitTimeLine =
            prevAdjustment && prevAdjustment.Finish !== data.Start;
          let renderFinishTime = splitTimeLine || i === adjustments.length - 1;
          return (
            <div className={`${styles.EditRow}`} key={i}>
              <Button
                className={styles.TEEditNewTE}
                icon="plus"
                fillMode="flat"
                onClick={this.AddTE}
                title="Add Time Entry"
                data-time={data.Start}
                data-number={data.sortNumber}
                data-side={"before"}
              ></Button>
              {this.renderTime(
                i,
                "start",
                data.Start,
                PrevSplitTimeLine
                  ? prevAdjustment?.Finish
                  : prevAdjustment?.Start,
                data.Finish,
                PrevSplitTimeLine
              )}
              {this.renderItemRow(data, i, renderFinishTime)}
              <Button
                className={`${styles.TEEditNewTE} ${styles.TEEditNewTEFinish}`}
                icon="plus"
                fillMode="flat"
                onClick={this.AddTE}
                title="Add Time Entry"
                data-time={data.Finish}
                data-number={data.sortNumber}
                data-side={"after"}
              ></Button>
              {renderFinishTime &&
                this.renderTime(
                  i,
                  "finish",
                  data.Finish,
                  data.Start,
                  nextAdjustment?.Start
                )}
              {splitTimeLine && <div style={{ height: 50 }}></div>}
            </div>
          );
        })}

        <div style={{ clear: "both" }}></div>
      </div>
    );
  };

  renderItemRow = (
    data: IAdjustedTimeLineItem,
    i: number,
    isFinish: boolean
  ) => {
    const { remountKeyAdjustment, availableStates, workOrders } = this.state;
    const { tcId, tcInfo, adjustmentsInfo } = this.props;
    return (
      <div
        className={`${styles.AdjustmentEdit} ${
          isFinish ? styles.EditRowBorderBottom : ""
        }`}
      >
        <AdjustmentEditRow
          key={data.sortNumber! + remountKeyAdjustment}
          tcId={tcId}
          tcInfo={tcInfo}
          info={adjustmentsInfo}
          index={i}
          data={data}
          delete={this.DeleteAdjustment}
          selectCostType={this.CostTypesSelect}
          updateAllocation={this.UpdateAllocation}
          updateApprovedHours={this.OnChangeApprovedHours}
          availableStates={availableStates}
          updateStateCode={this.UpdateStateCode}
          workOrders={workOrders}
          ShowStateAllocation={this.ShowStateAllocation}
          onCloseAllocationCard={this.OnCloseAllocationCard}
        />
      </div>
    );
  };

  renderTime = (
    i: number,
    type: "start" | "finish",
    valueString: string,
    prevDateString?: string,
    nextDateString?: string,
    PrevSplitTimeLine?: boolean
  ) => {
    const value: Moment = moment(valueString);
    const minValue: Moment = prevDateString
      ? moment(prevDateString)
      : moment(valueString).clone().set("h", 0).set("m", 0);
    const maxValue: Moment = nextDateString
      ? moment(nextDateString)
      : moment(valueString).clone().set("h", 23).set("m", 59);
    if (maxValue?.get("hours") === 23 && maxValue.get("minutes") === 59) {
      maxValue.set("seconds", 59);
    }
    const diff = maxValue.diff(value, "m");
    const showSplit =
      type !== "finish" && i > 0 && !PrevSplitTimeLine && diff > 2;
    return (
      <small className={styles.TETimeEdit} key={i + type + type}>
        {showSplit && (
          <Button
            className={styles.TEEditSplit}
            iconClass="mdi mdi-arrow-split-horizontal"
            fillMode="flat"
            onClick={this.SplitAdjustments}
            title="Split TimeLine"
            data-time={valueString}
            data-finish={nextDateString}
          ></Button>
        )}
        <TimePicker
          defaultValue={value.toDate()}
          key={i + this.state.remountKey}
          width="100%"
          nowButton={false}
          min={minValue.toDate()}
          max={maxValue.toDate()}
          formatPlaceholder={{ hour: "hh", minute: "mm" }}
          required={true}
          className={styles.KendoTimePicker}
          onChange={(e: TimePickerChangeEvent) =>
            this.OnChangeTime(e, minValue, maxValue, type, i)
          }
          smoothScroll={false}
        />
      </small>
    );
  };

  renderCostTypeContextMenu = () => {
    const { showCostypeMenu, offsetCostTypeMenu, costTypes } = this.state;
    return (
      <Popup
        show={showCostypeMenu}
        offset={offsetCostTypeMenu}
        popupAlign={{ horizontal: "left", vertical: "center" }}
        collision={{
          horizontal: "none",
          vertical: "fit",
        }}
      >
        <Menu vertical={true} onSelect={this.OnSelectCostType}>
          {costTypes.map(this.renderCostTypeItem)}
        </Menu>
      </Popup>
    );
  };

  renderCostTypeItem = (costType: ICostType) => {
    let selectedCostTypeCode = this.state.costTypeAdjustment?.CostTypeCode;
    let cssClass =
      selectedCostTypeCode === costType.Code ? styles.SelectedMenuItem : "";
    cssClass += " " + styles.CostTypeMenuItem;
    // @ts-ignore
    let code: costTypeCode = costType.Code;
    return (
      <MenuItem
        key={costType.Id}
        text={costType.Name}
        data={costType}
        cssClass={cssClass}
        cssStyle={{ color: costType.Color || getColorByCostType(code) }}
      />
    );
  };

  GetTotalDuration = () => {
    return formatHoursDuration(
      this.totalApprovedDuration - (this.lunchDuration || 0)
    );
  };

  OnChangeComment = (e: any) => {
    let value = e.value;
    this.Comment = value || null;
  };

  OnChangeLunchDuration = (hours: number) => {
    this.lunchDuration = hours;
    this.setState({
      totalDuration: this.GetTotalDuration(),
    });
  };

  OnChangeTime = (
    e: TimePickerChangeEvent,
    minValue: Moment,
    maxValue: Moment,
    type: "start" | "finish",
    i: number
  ) => {
    let value = e.value;
    let valueMoment = moment(value);
    let isValidTime = valueMoment.isValid();
    if (value && isValidTime) {
      const date = moment(this.props.tcInfo.Date, "L");
      valueMoment.set("year", date.get("year"));
      valueMoment.set("month", date.get("month"));
      valueMoment.set("date", date.get("date"));
    }
    if (value?.getHours() === 23 && value.getMinutes() === 59) {
      valueMoment.set("seconds", 59);
    }

    if (isValidTime) {
      let item = this.adjustments[i];
      if (type === "start") {
        let oldValueString = item.Start;
        item.Start = valueMoment.format(FORMAT);
        item.TimeStart = valueMoment.format("LT");
        let prevItem = this.adjustments[i - 1];
        if (prevItem && prevItem.Finish === oldValueString) {
          prevItem.Finish = item.Start;
          prevItem.TimeFinish = item.TimeStart;
          let [hours, durationString] = getActualDuration(
            prevItem.Start,
            prevItem.Finish
          );
          if (prevItem.CostTypeCode !== "LUNCH") {
            if (!!prevItem.ApprovedDuration) {
              prevItem.ApprovedDuration = hours;
              prevItem.ApprovedDurationString = durationString;
            }
          }
          prevItem.ActualDuration = hours;
          prevItem.ActualDurationString = durationString;
        }
      } else if (type === "finish") {
        item.Finish = valueMoment.format(FORMAT);
        item.TimeFinish = valueMoment.format("LT");
      }
      let [hours, durationString] = getActualDuration(item.Start, item.Finish);
      if (item.CostTypeCode !== "LUNCH" && !!item.ApprovedDuration) {
        item.ApprovedDuration = hours;
        item.ApprovedDurationString = durationString;
      }
      item.ActualDuration = hours;
      item.ActualDurationString = durationString;

      if (!valueMoment.isBefore(minValue) && !valueMoment.isAfter(maxValue)) {
        isValidTime = true;
        this.SetData();
        this.setState({ remountKeyAdjustment: +new Date() });
      } else {
        isValidTime = false;
      }
    }
    let hasTEWithNoDuration = false;
    if (isValidTime && !this.validation.invalidTime) {
      hasTEWithNoDuration =
        this.adjustments.findIndex((item) => item.Start === item.Finish) > -1;
    }
    if ((!isValidTime || hasTEWithNoDuration) && !this.validation.invalidTime) {
      this.validation.invalidTime = !isValidTime || hasTEWithNoDuration;
      this.setState({ messages: this.GetMessages(), invalidForm: true });
    }
  };

  OnChangeApprovedHours = (
    hours: number,
    valueString: string,
    oldData: IAdjustedTimeLineItem
  ) => {
    oldData.ApprovedDuration = hours;
    oldData.ApprovedDurationString = valueString;
    if (hours) {
      oldData.ActualDuration = hours;
      oldData.ActualDurationString = valueString;
    }
    this.SetData();
  };

  OnSelectCostType = (event: MenuSelectEvent) => {
    const costType = event.item.data as ICostType;
    let adjustment = this.state.costTypeAdjustment;
    if (adjustment) {
      adjustment.CostTypeCode = costType.Code as costTypeCode;
      adjustment.CostTypeName = costType.Name;
      if (adjustment.CostTypeCode === "LUNCH") {
        adjustment.ApprovedDuration = 0;
        adjustment.ApprovedDurationString = "00:00";
      }
      adjustment.CostTypeColor = costType.Color;
      const clearAllocation = !!adjustment.isStaticAllocation !== costType.IsNonWork
      if (clearAllocation) adjustment.ManualAllocation = []
      adjustment.isStaticAllocation = costType.IsNonWork;
      if (adjustment.isStaticAllocation && this.state.staticWOs.length) {
        adjustment.ManualAllocation = this.state.staticWOs.map(wo => ({
          Percentage: 100 / this.state.staticWOs.length,
          RowNumber: adjustment!.RowNumber,
          WorkOrderCode: wo.StaticWOCode,
          WorkOrderId: wo.StaticWOId,
          WorkOrderName: wo.StaticWOName,
          WorkOrderNumber: "",
        })
        );
      }
      this.SetData();
    }
  };

  UpdateStateCode = (oldData: IAdjustedTimeLineItem, newStateCode: string) => {
    oldData.StateCode = newStateCode;
    this.SetData();
  };

  UpdateAllocation = (
    oldData: IAdjustedTimeLineItem,
    allocation: Array<IAdjustmentAllocation>,
    stateCode: string | null
  ) => {
    oldData.ManualAllocation = allocation;
    oldData.StateCode = stateCode;
    this.SetData();
  };

  UndoChanges = () => {
    this.lunchDuration = this.props.adjustmentsInfo.LunchDuration;
    this.Comment = this.props.adjustmentsInfo.Comment || null;
    this.InitData(
      this.props.adjustmentsTimeline,
      this.state.costTypes,
      this.state.workOrders,
      this.state.availableStates,
      "undo"
    );
  };

  GetMessages = () => {
    let messages: Array<string> = [];
    if (this.validation.invalidTime) messages.push("Invalid Time");
    if (this.validation.costType)
      messages.push("Specify Cost Type for All Time Entries");
    if (this.validation.allocation)
      messages.push("Some Time Entries do not have a Work Order Allocation");
    return messages;
  };

  InitData = (
    data: Array<IAdjustedTimeLineItem>,
    costTypes: ICostType[],
    workOrders: IListWOItem[],
    states: string[],
    action?: adjustmentsAction
  ) => {
    // rename
    let [
      adjustments,
      totalApprovedDuration,
      totalActualDuration,
      messages,
      invalidForm,
    ] = this.GetData(data, costTypes, workOrders, states, action);

    this.adjustments = adjustments.filter((item) => !item.IsWaivedLunch);
    this.totalApprovedDuration = totalApprovedDuration;
    this.setState((state) => ({
      messages,
      invalidForm,
      totalApprovedDuration: formatHoursDuration(totalApprovedDuration),
      totalActualDuration: formatHoursDuration(totalActualDuration),
      remountKey: action ? +new Date() : state.remountKey,
      remountKeyAdjustment: action ? +new Date() : state.remountKeyAdjustment,
      totalDuration: this.GetTotalDuration(),
    }));
  };

  GetData = (
    dataParam: Array<IAdjustedTimeLineItem>,
    costTypes: ICostType[],
    workOrders: IListWOItem[],
    states: string[],
    action?: adjustmentsAction
  ): [Array<IAdjustedTimeLineItem>, number, number, string[], boolean] => {
    const data = action === "clearAll" ? [] : [...dataParam];
    let adjustments: Array<IAdjustedTimeLineItem> = [];
    let hasEmptyCostType = !data.length;
    let hasEmptyAllocation = !data.length;
    let totalApprovedDuration = 0;
    let totalActualDuration = 0;

    if (!data.length) {
      const StartMoment = moment(this.props.tcInfo.Date)
        .set("h", 12)
        .set("m", 0)
        .set("s", 0);
      const FinishMoment = StartMoment.clone();
      adjustments.push({
        ActualDuration: 0,
        ApprovedDuration: 0,
        CostTypeCode: costTypeCodeDefault,
        CostTypeName: "",
        Finish: FinishMoment.format(FORMAT),
        RowNumber: 1,
        Start: StartMoment.format(FORMAT),

        // frontend fields
        ApprovedDurationString: "00:00",
        ActualDurationString: "00:00",
        TimeStart: StartMoment.format("LT"),
        TimeFinish: FinishMoment.format("LT"),
        ManualAllocation: [],
        CostTypeColor: "",
        Color: getColorByCostType(costTypeCodeDefault),
        StateCode: null,
        sortNumber: 1,
        isNewTe: true,
        IsWaivedLunch: false,
      });
    }
    for (let i = 0; i < data.length; i++) {
      const TE: IAdjustedTimeLineItem = JSON.parse(JSON.stringify(data[i]));
      const costTypeCode = TE.CostTypeCode || costTypeCodeDefault;
      if (TE.CostTypeCode) {
        TE.isStaticAllocation = !!costTypes.find(
          (costType) => costType.Code === costTypeCode
        )?.IsNonWork;
      }
      if (TE.CostTypeColor) TE.Color = TE.CostTypeColor;
      else if (costTypeCode) TE.Color = getColorByCostType(costTypeCode);
      const isLunch = TE.CostTypeCode === "LUNCH";
      if (TE.CostTypeCode === "EMPTY") hasEmptyCostType = true;
      if (!isLunch && !TE.ManualAllocation.length) hasEmptyAllocation = true;

      if (action === "restore" && !isLunch) {
        TE.ApprovedDuration = TE.ActualDuration;
        TE.ApprovedDurationString = formatHoursDuration(TE.ActualDuration);
      }

      TE.ActualDurationString = formatHoursDuration(TE.ActualDuration);
      totalActualDuration += TE.ActualDuration;
      if (!isLunch) {
        totalApprovedDuration += TE.ApprovedDuration;
      }
      adjustments.push(TE);
    }

    let lastTime = "";
    let invalidTime = false;
    let HasTEWithNoDuration = false;
    for (let i = 0; i < adjustments.length; i++) {
      let item = adjustments[i];
      item.sortNumber = i + 1;
      if (!invalidTime) {
        if (
          (lastTime && moment(item.Start).isBefore(lastTime)) ||
          moment(item.Finish).isBefore(item.Start)
        ) {
          invalidTime = true;
        }
      }
      if (!invalidTime && !HasTEWithNoDuration && item.Finish === item.Start) {
        HasTEWithNoDuration = true;
      }
      lastTime = item.Finish;
    }
    adjustments.sort((A, B) => {
      return A.sortNumber - B.sortNumber;
    });

    this.validation = {
      costType: hasEmptyCostType,
      allocation: hasEmptyAllocation,
      invalidTime: invalidTime || HasTEWithNoDuration,
    };

    const invalidForm = hasEmptyCostType || invalidTime;
    return [
      adjustments,
      totalApprovedDuration,
      totalActualDuration,
      this.GetMessages(),
      invalidForm,
    ];
  };

  DeleteAdjustment = (data: IAdjustedTimeLineItem) => {
    this.adjustments = this.adjustments.filter(
      (item) => data !== item && item.sortNumber !== data.sortNumber
    );
    this.SetData(undefined, "delete");
  };

  SetData = (
    data?: Array<IAdjustedTimeLineItem>,
    action?: adjustmentsAction
  ) => {
    this.InitData(
      data || this.adjustments,
      this.state.costTypes,
      this.state.workOrders,
      this.state.availableStates,
      action
    );
  };

  LoadWorkOrders = async () => {
    try {
      const [workOrders] = await this.GetSQLData({
        spName: "TK_GetAvailableWOs",
        params: { tcId: this.props.tcId },
      });
      return workOrders;
    } finally {
    }
  };

  LoadData = async () => {
    try {
      this.setState({ processing: true });
      const result: any = await this.GetSQLData({
        spName: "TK_GetDataForAdjustment",
        params: { TCId: this.props.tcId },
      });
      const costTypes: ICostType[] = result[0]
      const states = result[1]
      const staticWOs: IStaticWO[] = result[2]
      const availableStates = states.map((item: { Code: string }) => item.Code);
      const workOrders = await this.LoadWorkOrders();
      this.InitData(
        this.props.adjustmentsTimeline,
        costTypes,
        workOrders,
        availableStates
      );
      this.setState({
        staticWOs,
        costTypes,
        workOrders,
        availableStates,
      });
    } finally {
      this.setState({ processing: false });
    }
  };

  AddTE = (e: any) => {
    const { time, number, side } = e.currentTarget.dataset;
    let Start = moment(time);
    let Finish = moment(time);
    this.adjustments.push({
      ActualDuration: 0,
      ApprovedDuration: 0,
      CostTypeCode: costTypeCodeDefault,
      CostTypeName: "",
      Finish: Finish.format(FORMAT),
      RowNumber: 1, // ??
      Start: Start.format(FORMAT),

      // frontend fields
      ApprovedDurationString: "00:00",
      ActualDurationString: "00:00",
      TimeStart: Start.format("LT"),
      TimeFinish: Finish.format("LT"),
      ManualAllocation: [],
      CostTypeColor: "",
      Color: getColorByCostType(costTypeCodeDefault),
      StateCode: null,
      sortNumber: side === "before" ? +number - 0.1 : +number + 0.1,
      isNewTe: true,
      IsWaivedLunch: false,
    });
    this.adjustments.sort((a, b) => a.sortNumber - b.sortNumber);

    this.SetData(undefined, "add");
  };

  SplitAdjustments = (e: any) => {
    let time = e.currentTarget.dataset.time;
    let finish = e.currentTarget.dataset.finish;
    let itemSplit = this.adjustments.find(
      (item) => item.Start === time && item.Finish === finish
    );
    itemSplit!.Start = moment(time).add(1, "m").format(FORMAT);
    this.SetData(undefined, "split");
  };

  Save = async () => {
    try {
      let updateData: Array<IUpdateItem> = [];
      for (let i = 0; i < this.adjustments.length; i++) {
        let item = this.adjustments[i];
        const data: IUpdateItem = {
          Start: moment(item.Start).format("HH:mm:ss"), // todo change format global
          Finish: moment(item.Finish).format("HH:mm:ss"),
          ApprovedDuration: item.ApprovedDuration,
          CostTypeCode: item.CostTypeCode,
          Allocation: [],
          StateCode: this.ShowStateAllocation ? item.StateCode : null,
        };

        item.ManualAllocation.forEach((allocation) => {
          data.Allocation.push({
            WorkOrderCode: allocation.WorkOrderCode,
            Percentage: allocation.Percentage,
          });
        });
        updateData.push(data);
      }
      updateData.sort((A, B) => {
        return moment(A.Start).isAfter(moment(B.Start)) ? -1 : 1;
      });
      this.setState({ processing: true });
      let data = JSON.stringify({
        Allocation: updateData,
        LunchDuration: this.lunchDuration,
        Comment: this.Comment,
      });

      await RunScriptAsync("TKAdjustments_Update", {
        TCID: this.props.tcId,
        AdjustmentJSON: data,
      });
      if (this.props.onSave) this.props.onSave();
    } catch {
    } finally {
      this.setState({ processing: false });
    }
  };

  CostTypesSelect = (e: any, data: IAdjustedTimeLineItem) => {
    e.stopPropagation();
    document.addEventListener("click", this.CloseCostTypeSelect);
    let position = e.target.getBoundingClientRect();
    this.setState({
      showCostypeMenu: true,
      offsetCostTypeMenu: {
        left: position.left + 20,
        top: position.bottom + 1,
      },
      costTypeAdjustment: data,
    });
  };

  CloseCostTypeSelect = () => {
    document.removeEventListener("click", this.CloseCostTypeSelect);
    this.setState({
      showCostypeMenu: false,
      offsetCostTypeMenu: undefined,
      costTypeAdjustment: undefined,
    });
  };

  AllocateAll = () => {
    this.Allocate(true);
  };

  AllocateUnallocated = () => {
    this.Allocate();
  };

  Allocate = (all?: boolean) => {
    if (!all) {
      const unallocated = this.adjustments.findIndex(
        (item) => item.CostTypeCode !== "LUNCH" && !item.ManualAllocation.length
      );
      if (unallocated === -1) {
        ModalRef.showDialog({
          title: "Impossible Action",
          text: "All Time Entries are allocated",
          width: 450,
        });
        return;
      }
    } else {
      const dinamicAllocationTEs = this.adjustments.filter(
        (item) => item.CostTypeCode !== "LUNCH" && !item.isStaticAllocation
      );
      if (!dinamicAllocationTEs.length) {
        ModalRef.showDialog({
          title: "Impossible Action",
          text: "Any Time Entry is not available for changing allocation",
          width: 450,
        });
        return;
      }
    }

    CardManagement.WOAllocationCard({
      tcId: this.props.tcId,
      tcInfo: this.props.tcInfo,
      onResult: (
        result: Array<IAdjustmentAllocation>,
        oldAllocation: IAdjustmentAllocation[],
        workOrders: IListWOItem[]
      ) => {
        this.setState({ workOrders }, () => {
          let resultJSON = JSON.stringify(result);
          this.adjustments.forEach((item) => {
            if (
              item.CostTypeCode !== "LUNCH" &&
              !item.isStaticAllocation &&
              (all || !item.ManualAllocation.length)
            ) {
              item.ManualAllocation = JSON.parse(resultJSON);
              item.StateCode = getDefaultStateCode(
                item.ManualAllocation,
                workOrders
              );
            }
          });
          this.SetData();
        });
      },
      onClose: this.OnCloseAllocationCard,
      allocation: [],
      title: all ? "Allocate All" : "Allocate Unallocated",
    });
  };

  OnCloseAllocationCard = (workOrders: IListWOItem[]) => {
    this.setState({ workOrders });
  };

  RestoreAll = () => {
    this.SetData(undefined, "restore");
  };

  ClearAll = () => {
    this.SetData(undefined, "clearAll");
  };
}

export default AdjustmentsEdit;
