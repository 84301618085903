import { getSQLData } from "../helpers/queries";
import { CurrentUserInfo, IUserRole } from "./interfaces";

class UserInfo {
  private info: CurrentUserInfo | null = null;
  private roles: IUserRole[] | null = null;
  private canGBP: boolean = false;
  private isFinancialDataAllow: boolean | null = null;

  setInfo = (info: CurrentUserInfo | null) => {
    this.info = info;
  };

  setRoles = (roles: IUserRole[]) => {
    this.canGBP =
      roles.findIndex(
        ({ RoleServiceName }) => RoleServiceName === "CanGenerateBPs"
      ) > -1;
    this.roles = roles;
  };

  getInfo = async () => {
    if (!this.info) {
      await this.LoadUserInfo();
    }
    return this.info;
  };

  getRoles = async () => {
    if (!this.roles) {
      await this.LoadUserInfo();
    }
    return this.roles;
  };

  canGenerateBP = async () => {
    if (!this.roles) {
      await this.LoadUserInfo();
    }
    return this.canGBP;
  };

  getIsFinancialDataAllow = async () => {
    if (this.isFinancialDataAllow === null) {
      await this.LoadIsFinancialDataAllow();
    }
    return this.isFinancialDataAllow;
  };

  private LoadUserInfo = async () => {
    let response = await getSQLData({ spName: "GetCurrentUserInfo" });
    let settings: CurrentUserInfo = response[0];
    const roles: IUserRole[] = response[1];
    this.setInfo(settings);
    this.setRoles(roles);
  };

  private LoadIsFinancialDataAllow = async () => {
    let response = await getSQLData({ spName: "IsFinancialDataAllow" });
    this.isFinancialDataAllow = response[0][0].IsFinancialDataAllow;
  };
}

export default new UserInfo()
