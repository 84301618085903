import React, { Component } from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting, IFilterSetting } from "../Dashboard/interfaces";
import { simpleObject } from "../../helpers/interfaces";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { formatDateColumn } from "./helpers";
import CardManagement from "../Cards/CardManagement";

interface props {
  tcId?: number;
  isActive: boolean;
  switcher?: any;
  onChangeGridData?: (data: Array<simpleObject>) => void;
}

interface state {}

class TCAuditResults extends Component<props, state> {
  gridRef: any = React.createRef();
  tcId = this.props.tcId;
  isPage: boolean; // or tab
  COLUMNS: Array<IColumnSetting>;
  FILTERS: Array<IFilterSetting> = [];

  constructor(props: props) {
    super(props);
    this.state = {};

    this.isPage = !!this.props.switcher;
    if (this.isPage) {
      this.COLUMNS = [
        {
          field: "RowNum",
          title: "#",
          type: "string",
          gridWidth: 40,
          filterable: false,
          columnMenu: false,
          aggregate: "count",
        },
        {
          field: "Date",
          title: "Date",
          type: "date",
          format: {
            type: "date",
            customFormat: true,
            getCustomFormat: formatDateColumn,
          },
        },
        {
          field: "EmployeeName",
          title: "Employee",
          type: "string",
          gridWidth: 200,
          format: {
            type: "link",
            fieldId: "EmployeeId",
            refName: "Employees",
          },
        },
        {
          field: "PeriodName",
          title: "Payroll Period",
          type: "string",
          minWidth: 200,
          format: {
            type: "string",
            fieldId: "PeriodId",
          },
        },
        {
          field: "FirstClockIn",
          title: "First Clock In",
          type: "date",
          gridWidth: 130,
          format: {
            type: "datetime",
          },
        },
        {
          field: "LastClockOut",
          title: "Last Clock Out",
          type: "date",
          gridWidth: 130,
          format: {
            type: "datetime",
          },
        },
        {
          field: "ClockedHours",
          title: "Clocked Hours",
          type: "number",
          gridWidth: 90,
          aggregate: "sum",
          format: {
            type: "number",
          },
        },
        {
          title: "Lunch Hours",
          type: "parent",
          columns: [
            {
              field: "AuditLunchHours",
              title: "Audit",
              type: "number",
              gridWidth: 90,
              format: {
                type: "number",
              },
            },
            {
              field: "ManualLunchHours",
              title: "Manual",
              type: "number",
              gridWidth: 90,
              format: {
                type: "number",
              },
            },
          ],
        },
        {
          field: "ApprovedHours",
          title: "Approved Hours",
          type: "number",
          gridWidth: 90,
          aggregate: "sum",
          format: {
            type: "number",
          },
        },
        {
          title: "Audit Detail",
          type: "parent",
          columns: [
            {
              field: "AuditRuleName",
              title: "Audit Rule",
              type: "string",
              minWidth: 120,
              format: {
                type: "string",
                fieldId: "AuditRuleId",
              },
            },
            {
              field: "ResultText",
              title: "Text",
              type: "string",
              gridWidth: 70,
              format: {
                type: "icon",
                iconClass: "k-icon k-i-file-txt",
                tooltipProps: {
                  position: "top",
                },
              },
            },
            {
              field: "Adjustment",
              title: "Adjustment",
              type: "number",
              gridWidth: 100,
              format: {
                type: "number",
              },
            },
          ],
        },
      ];
      this.FILTERS = [
        {
          id: "filterPeriod",
          type: "dateRange",
          serverFilter: {
            param: "",
          },
          dateRangeSettings: {
            dateFromParam: "dateFrom",
            dateToParam: "dateTo",
            defaultPeriod: "notApplied",
            allowEmptyValues: true,
          },
          localStoragePrefix: "TKDashboard",
          toolbarRow: 1,
        },
        {
          id: "filterPP",
          placeholder: "Filter by Payroll Period",
          type: "combobox",
          serverFilter: {
            param: "periodId",
            getData: async () => {
              let result = await ReferenceRecordsDataSource(
                "TKPayrollPeriods",
                { activeOnly: false }
              );
              return result[0];
            },
          },
          localStoragePrefix: "TKDashboard",
          width: 298,
          toolbarRow: 2,
        },
      ];
    } else {
      this.COLUMNS = [
        {
          field: "RowNum",
          title: "#",
          type: "string",
          aggregate: "count",
          gridWidth: 40,
          filterable: false,
          columnMenu: false,
        },
        {
          field: "AuditRuleName",
          title: "Audit Rule",
          type: "string",
          minWidth: 200,
          format: {
            type: "string",
            fieldId: "AuditRuleId",
          },
        },
        {
          field: "ResultText",
          title: "Text",
          type: "string",
          gridWidth: 70,
          format: {
            type: "icon",
            iconClass: "k-icon k-i-file-txt",
            tooltipProps: {
              position: "top",
            },
          },
        },
        {
          field: "Adjustment",
          title: "Adjustment",
          type: "number",
          gridWidth: 100,
          format: {
            type: "number",
          },
        },
      ];
    }
  }

  render() {
    if (this.props.isActive && this.tcId !== this.props.tcId) {
      this.tcId = this.props.tcId;
    }

    if (
      (!this.isPage && !this.props.tcId) ||
      (!this.props.isActive && this.tcId !== this.props.tcId)
    )
      return null;
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        key={this.props.tcId}
        ref={this.gridRef}
        columnsSettings={this.COLUMNS}
        getSqlParams={{ spName: "DB_Audits", params: { tcId: this.tcId } }}
        getRowKey={this.GetRowKey}
        initRefresh={this.InitRefresh}
        excelFileName="AuditResults.xlsx"
        saveSettings={{
          prefix: "auditResultsDash",
          saveFilters: !!this.isPage,
        }}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        filters={this.FILTERS}
        toolbar={{
          switcher: this.props.switcher,
          hide: !this.isPage,
        }}
        selectSettings={{
          pageId: "TKDashboard",
          onSelectRow: this.OpenTCCard,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  GetRowKey = (dataItem: simpleObject) => dataItem.RowNum;

  OpenTCCard = (rowData: simpleObject | null) => {
    if (rowData) {
      let tcId = rowData.TCId;
      if (tcId) CardManagement.OpenTCCard(tcId);
    }
  };
}

export default TCAuditResults;
