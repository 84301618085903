import CardManagement from "../Components/Cards/CardManagement";
import { ModalRef } from "../Components/Common/Modal/Modal";
import { ModalComponentInIframeRef } from "../Components/Common/PortalLiveMap/PortalLiveMap";
import { ModalTimeCardInIframeRef } from "../Components/Common/PortalTimeCard/PortalTimeCard";
import { ILiveMapProps } from "../Pages/LiveMap/interfaces";
import AuthData from "./authData";
import FileAccessToken from "./fileAccessToken";
import {
  calendarId,
  IComboboxItem,
  SasTokenType,
  simpleObject,
} from "./interfaces";
import { OpenRecord, RunScript, RunScriptAsync } from "./runscripts";
import {
  DataServerHost,
  IntegrationWithDesktop,
  isConterra,
  isConterraForm,
} from "./settings";

import { Button } from "@progress/kendo-react-buttons";
import { AxiosRequestConfig } from "axios";
import moment from "moment";
import styles from "../Components/Common/Form/form.module.scss";
import { IInitialTabSettings, tabId } from "../Components/Tabs/interfaces";
import { axiosQuery, showRequestError } from "./axiosHelpers";
import { getSQLData } from "./queries";
import { GetPolygonCoordinatesFromStrBoundaries } from "../Components/Map/helpers";
import { IDuration } from "../Pages/TKReview/interfaces";

window.onerror = function (msg, url, line, col, error) {
  let errorString = typeof error === "object" ? JSON.stringify(error) : error;
  ModalRef.showDialog({
    title: (
      <>
        Unexpected Error:
        <Button
          style={{ marginLeft: "auto" }}
          className={styles.HeaderLinkBtn}
          fillMode="flat"
          icon="refresh"
          title="Reload"
          onClick={() => {
            window.location.reload();
          }}
        />
      </>
    ),
    text: msg + "",
    type: "error",
    width: 620,
    details: {
      title: "Details",
      content: `url: ${url},
			line: ${line},
			col: ${col},
			${errorString}`,
    },
  });

  // If you return true, then error alerts (like in older versions of
  // Internet Explorer) will be suppressed.
  return true;
};

export const sortByNameCombobox = (a: IComboboxItem, b: IComboboxItem) =>
  a.Name.localeCompare(b.Name);

export const formattedHoursToDuration = (durationString: string) => {
  let hours = +durationString.substring(0, 2);
  let minutes = +durationString.substring(3, 5);
  if (isNaN(hours) || isNaN(minutes)) return null;
  let duration = hours + minutes / 60;
  let result = duration;
  return +result;
};

export const formatHoursDuration = (duration: number) => {
  const [h, m] = Math.abs(duration).toString().split(".");
  let hours = +h;
  const minutesDuration = +`0.${m ? m : 0}`;
  let minutes = Math.round(60 * minutesDuration);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  let minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;
  let hoursString = `${hours < 10 ? "0" : ""}${hours}`;
  let minus =
    hoursString !== "00" && minutesString !== "00" && duration < 0 ? "-" : "";
  return `${minus}${hoursString}:${minutesString}`;
};

export const formatDuration = (duration: IDuration, format: "time" | "LT") => {
  if (format === "time") {
    return `${duration.h ? duration.h + "h " : ""}${duration.m}m`;
  }
  return `${duration.h < 10 ? "0" : ""}${duration.h}:${
    duration.m < 10 ? "0" : ""
  }${duration.m}`;
};

export const formatTimeDiff = (s: number) => {
  let hrs = Math.floor(s / 3600);
  let min = Math.floor((s % 3600) / 60);
  return `${hrs ? hrs + "h " : ""}${min}m`;
};

export const getActualDuration = (
  start: string,
  finish: string
): [number, string] => {
  let startMoment = moment(start);
  let finishMoment = moment(finish);
  const [, time] = finish.split("T");
  if (time === "23:59:59") {
    finishMoment.add(1, "seconds");
  }
  const diff = finishMoment.diff(startMoment, "m");
  const hours: number = diff / 60;
  return [hours, formatHoursDuration(hours)];
};

window.helpers.formatHoursDuration = function (duration: number) {
  return formatHoursDuration(duration);
};

window.helpers.livemapOpened = false;

export const formatFinancial = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "";
  var formatValue = +Math.abs(value).toFixed(2);
  var format = formatValue.toLocaleString("en");
  var float = format.split(".")[1];
  float = float ? (float.length === 2 ? float : float + "0") : "00";
  var result = format.split(".")[0] + "." + float;
  if (value < 0) {
    return "(" + result + ")";
  }
  return result;
};

export const formatFinancialRound = (value: number) => {
  var minus = value < 0 ? "-" : "";
  var kValue = kmbFormatter(Math.abs(value));
  return minus + "$" + kValue;
};

export const kmbFormatter = (n: number) => {
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "b";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const PLFormatPercentage = (value: number) => {
  if (!isNaN(value) && !isFinite(value)) return "0.00 %";
  else if (isNaN(value)) return "n/a";
  else return `${value.toFixed(2)} %`;
};

export const TYPE_TO_EXCEL_FORMAT = {
  number: "#,##0.00",
  integer: "#,##0",
  percent: "#0.00\\%;-#0.00\\%",
  currency:
    '_([$$-en-US]* #,##0.00_);_([$$-en-US]* (#,##0.00);_([$$-en-US]* ""-""??_);_(@_)',
  date: "MM/dd/yyyy",
  datetime: "MM/dd/yyyy h:mm AM/PM",
  time: "h:mm:ss AM/PM",
  // boolean TODO
};

export const getParamsFromUrl = () => {
  var strParams = window.location.search.replace("?", "").split("&");
  var params: any = {};
  strParams.forEach(function (str) {
    var t = str.split("=");
    params[t[0]] = t[1];
  });
  return params;
};

export const addParamsToUrl = (params: simpleObject, url: string) => {
  let paramsArray: Array<string> = [];
  for (let key in params) {
    if (key !== "folder")
      paramsArray.push(`${key ? key + "=" : ""}${params[key]}`);
  }
  let f = url.indexOf("?") > -1 ? "&" : "?";
  if (paramsArray.length) return url + f + paramsArray.join("&");
  return url;
};

export const GetAuthDataUrlParams = async () => {
  let tokenInfo = isConterra
    ? await RunScript("FXGetAccessToken", { GenerateRefreshToken: true })
    : await RunScriptAsync("FXGetAccessToken", { GenerateRefreshToken: true });
  let refresh_token = tokenInfo.split("|")[1];
  let expires_in = +tokenInfo.split("|")[0];
  if (isConterra)
    return `&accessToken=${AuthData.access_token}&expiresIn=${expires_in}&refreshToken=${refresh_token}&tokenType=${AuthData.token_type}`;
  return `&refreshToken=${refresh_token}`;
};

window.onload = function () {
  if (isConterraForm) {
    document.documentElement.style.fontSize =
      // @ts-ignore
      (window.external.width / window.external.parent.width) * 12 + "px";
    // @ts-ignore
    window.external.width = window.external.parent.width;
    // @ts-ignore
    window.external.height = window.external.parent.height;
  }
};

window.helpers.onInactive = function (ms: number, cb: any) {
  var newcb = function () {
    cb();
    window.helpers.onInactive(ms, cb);
  };
  var wait = setTimeout(newcb, ms);
  document.onmousemove =
    //@ts-ignore
    document.mousedown =
    //@ts-ignore
    document.mouseup =
    document.onkeydown =
    document.onkeyup =
    //@ts-ignore
    document.focus =
      function () {
        clearTimeout(wait);
        wait = setTimeout(newcb, ms);
      };
};

export const IsDesktopIntegrationOn = () => {
  return isConterra || localStorage.getItem(IntegrationWithDesktop) === "true";
};

window.helpers.IsDesktopIntegrationOn = () => {
  return IsDesktopIntegrationOn();
};

window.helpers.getRecordLink = function (
  text: string,
  refName: string,
  recordId: string | number,
  callbackName: string
) {
  let onclick = "";
  if ((refName !== "FSMBuildPlans" && IsDesktopIntegrationOn()) || isConterra) {
    let params = "'" + refName + "', '" + recordId + "'";
    onclick =
      "window.helpers.openRecord(" + params + "); event.stopPropagation();";
  } else if (refName === "FSMBuildPlans") {
    onclick =
      "window.helpers.openBPCard(" +
      recordId +
      ", " +
      callbackName +
      "); event.stopPropagation();";
  }
  let className =
    refName === "FSMBuildPlans" || IsDesktopIntegrationOn() ? "link" : "";
  return (
    '<span class="' +
    className +
    '" onclick="' +
    onclick +
    '">' +
    text +
    "</span>"
  );
};

const GetFileUrl = async (
  sasTokenType: SasTokenType,
  url: string,
  objectId: number
) => {
  let sasToken = await FileAccessToken.GetFileAccessToken(
    sasTokenType,
    objectId
  );
  if (!sasToken) throw new Error("Cannot get SAS Token");
  let fileUrl = `https://${DataServerHost}${url}`;
  return addParamsToUrl({ "": sasToken }, fileUrl);
};

export const GetDocumentUrl = async (documentId: number, inline: boolean) => {
  try {
    let result = await getSQLData({
      method: "GET",
      spName: "GetFileUrl",
      path: "documents",
      params: { documentId, inline },
      paramsToQuery: true,
    });
    return result?.url || null;
  } catch (e) {
    return null;
    // throw e;
  }
};

export const UpdateDocument = async (
  documentId: number,
  formData: any,
  onUploadProgress?: (pecent: number) => void
) => {
  try {
    let data = await AuthData.queueGetAuthToken();
    if (data && data.length && data[0] && data[1]) {
      let access_token = data[0];
      let token_type = data[1];

      let axiosConfig: AxiosRequestConfig = {
        method: "post",
        url: `https://${DataServerHost}/api/documents/UpdateFile?documentId=${documentId}`,
        data: formData,
        headers: {
          Authorization: `${token_type} ${access_token}`,
          timestamp: moment().format(),
        },
        onUploadProgress: onUploadProgress
          ? (e: any) => {
              let percent = (e.loaded / e.total) * 100;
              onUploadProgress(+percent.toFixed(0));
            }
          : undefined,
      };
      return await axiosQuery(axiosConfig);
    }
  } catch (e: any) {
    showRequestError(e, "Upload File Error:");
  }
};

window.helpers.addParameterToURL = (url: string, param: string) => {
  return url + (url.split("?")[1] ? "&" : "?") + param;
};

export const GetTKSelfieUrl = (id: number) => {
  return GetFileUrl("ViewTkPhotos", "/api/TK/photo/" + id, 0);
};

export const GetCLPMChecklistDocumentUrl = (
  fileId: number,
  filterBuildPlanId: number,
  preview: boolean,
  inline: boolean
) => {
  return GetFileUrl(
    "ViewWorkResults",
    `/api/Clm/GetChecklistResultFile?fileId=${fileId}&preview=${preview}&inline=${inline}`,
    filterBuildPlanId
  );
};

window.helpers.solicitTKRequest = function (
  date: Date,
  employeeId: number,
  afterSave: any
) {
  window.focus();
  CardManagement.OpenSolicitTKRequestCard({
    date,
    employeeId,
    afterSave,
  });
};

window.helpers.openDispatchCard = function (dsId: number, afterSave?: any) {
  window.focus();
  /* if (isConterra) OpenRecord('FSMDispatchSchedule', dsId)
            else { */
  CardManagement.OpenDispatchCard({
    newDispatch: false,
    dsId,
    afterSave,
  });
  // }
};

window.helpers.newDispatch = function (
  dsId?: number,
  bpId?: number,
  afterSave?: any
) {
  window.focus();
  CardManagement.OpenDispatchCard({
    newDispatch: true,
    buildPlanId: bpId,
    dsId,
    afterSave,
  });
};

window.helpers.openWebBPCard = function (
  buildPlanId: number,
  initialTab?: tabId | IInitialTabSettings,
  onFinish?: any
) {
  CardManagement.OpenBPCard(buildPlanId, onFinish, initialTab);
};

window.helpers.openBPCard = function (buildPlanId: number, onFinish?: any) {
  window.focus();
  if (isConterra) OpenRecord("FSMBuildPlans", buildPlanId);
  else CardManagement.OpenBPCard(buildPlanId, onFinish);
};

window.helpers.openGBPCard = function () {
  window.focus();
  CardManagement.OpenGBPCard();
};

window.helpers.openSiteCard = function (siteId: number) {
  window.focus();
  CardManagement.OpenSiteCard({ siteId });
};

window.helpers.openLocationCard = function (locationId: number) {
  window.focus();
  CardManagement.OpenLocationCard({ locationId });
};

window.helpers.openNewLocationCard = function (coordinates: {
  lat: number;
  lng: number;
}) {
  window.focus();
  CardManagement.OpenLocationCard({ coordinates });
};

window.helpers.newWO = function (bpId?: number, onFinish?: any) {
  window.focus();
  CardManagement.NewWO(bpId, onFinish);
};

window.helpers.openClmReviewCard = function (
  buildPlanId: number,
  workOrderId?: number
) {
  window.focus();
  CardManagement.OpenClmReviewInterface(buildPlanId, workOrderId);
};

window.helpers.openTCHistoryCard = function (tcId: number) {
  window.focus();
  CardManagement.OpenTCHistoryCard(tcId);
};

window.helpers.openTKLogsCard = function (tcId: number) {
  window.focus();
  CardManagement.OpenTKLogsCard(tcId);
};

window.helpers.openTCMapCard = function (tcId: number, tcRefresh?: () => void) {
  window.focus();
  CardManagement.OpenTCMapCard(tcId, false, tcRefresh);
};

window.helpers.liveMapPortalRemove = function () {
  ModalComponentInIframeRef.hideLiveMap();
};

window.helpers.liveMapPortal = function (
  CalendarMode: calendarId,
  settings: ILiveMapProps
) {
  ModalComponentInIframeRef.showLiveMap(CalendarMode, {
    ...settings,
    isActive: true,
    mode: "calendar",
    prefix: "calendar",
  });
};

window.helpers.timeCardPortalForceRefresh = function (tcId: number) {
  ModalTimeCardInIframeRef.remountTimeCard({ tcId });
};

window.helpers.timeCardPortal = function (tcId: number) {
  ModalTimeCardInIframeRef.showTimeCard({ tcId });
};

window.helpers.timeCardPortalHide = function () {
  ModalTimeCardInIframeRef.hideTimeCard();
};

window.helpers.getPolygonCoordinatesFromStrBoundaries =
    GetPolygonCoordinatesFromStrBoundaries;

export const GetComboboxYearsList = () => {
    let year = new Date().getFullYear();
    let startYear = year - 10;
    let endYear = year + 11;
    let years: Array<IComboboxItem> = [];
    while (startYear < endYear) {
        years.push({
            Id: startYear,
            Name: startYear + "",
        });
        startYear += 1;
    }
    return years;
};
