import React, { useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Popover } from "@progress/kendo-react-tooltip";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  IAdjustedTimeLineItem,
  IAdjustmentAllocation,
  IAdjustmentsInfo,
  ITCSummaryInfo,
} from "../../../Pages/TKReview/interfaces";
import styles from "./adjustmentsEdit.module.scss";
import DurationInput from "../../../Components/Common/Form/DurationInput";
import OpenCardLink from "../../../Components/Common/Buttons/OpenCardLink";
import { IListWOItem } from "../../../Components/Cards/interfaces";
import { IS_IE } from "../../../helpers/settings";
import debounce from "lodash.debounce";
import AllocationInfo from "./AllocationInfo";

interface IProps {
  tcId: number;
  ShowStateAllocation: boolean;
  tcInfo: ITCSummaryInfo;
  info: IAdjustmentsInfo;
  index: number;
  data: IAdjustedTimeLineItem;
  availableStates: string[];
  updateStateCode: (oldData: IAdjustedTimeLineItem, stateCode: string) => void;
  workOrders: IListWOItem[];
  onCloseAllocationCard: (workOrders: IListWOItem[]) => void;

  delete(data: IAdjustedTimeLineItem, index: number): void;

  updateAllocation(
    oldData: IAdjustedTimeLineItem,
    allocation: Array<IAdjustmentAllocation>,
    stateCode: string | null
  ): void;

  selectCostType(e: any, data: IAdjustedTimeLineItem): void;

  updateApprovedHours(
    hours: number,
    valueString: string,
    data: IAdjustedTimeLineItem
  ): void;
}

const AdjustmentEditRow = (props: IProps) => {
  const [isShownPopover, setIsShownPopover] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { data, tcInfo, tcId, workOrders, ShowStateAllocation } = props;
  const {
    Start,
    Finish,
    ActualDurationString,
    ApprovedDurationString,
    ApprovedDuration,
    CostTypeCode,
    StateCode,
    Color,
    ManualAllocation,
  } = data;
  const isLunch = data.CostTypeCode === "LUNCH";

  const renderPopoverContent = () => {
    return (
      <div className={styles.TEDetailsContent}>
        <div>
          {data.TimeStart}&nbsp;-&nbsp;{data.TimeFinish}
        </div>
        <div>Cost Type: {data.CostTypeName || ""}</div>
        <div>Duration: {data.ActualDurationString}</div>
        <div>Approved: {data.ApprovedDurationString}</div>

        {!!ManualAllocation.length && (
          <>
            <div>Allocation:</div>
            <div className={styles.TEDetails}>
              {ManualAllocation.map((item) => {
                return (
                  <div
                    key={`${item.RowNumber}_${item.WorkOrderId}`}
                    className={styles.TEDetailsRow}
                  >
                    <span>{item.Percentage} % -&nbsp;</span>
                    <OpenCardLink
                      text={item.WorkOrderName}
                      dataAttr={item.WorkOrderId}
                      refName="FSMWorkOrders"
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderStateCodeDropDown = (StateCode: string | null) => {
    return (
      <DropDownList
        className={[
          styles.AdjustmentDropDown,
          IS_IE ? styles.AdjustmentDropDownIe : "",
        ].join(" ")}
        data={props.availableStates}
        value={StateCode}
        onChange={onChangeStateCode}
      />
    );
  };

  const changeCostType = (e: any) => {
    props.selectCostType(e, data);
  };

  const deleteTE = () => {
    props.delete(data, 0);
  };

  const onChangeStateCode = (event: DropDownListChangeEvent) => {
    props.updateStateCode(data, event.value);
  };

  const onTimeChange = (hours: number | null, value: string) => {
    props.updateApprovedHours(
      hours !== null ? hours : 0.0,
      value || "00:00",
      data
    );
  };

  const onMouseEvent = debounce((e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.type === "mouseover";
    if (isShownPopover === value) return;
    setIsShownPopover(value);
  }, 200);

  return (
    <div className={styles.TE}>
      <span
        className={`${styles.TECostType} ${
          !isLunch && !ApprovedDuration ? styles.thin : ""
        }`}
        style={{ color: Color }}
        onClick={changeCostType}
      ></span>
      <Button
        icon="trash"
        fillMode="flat"
        className={styles.DeleteBtn}
        onClick={deleteTE}
        title="Delete Time Entry"
      ></Button>
      <div
        ref={elementRef}
        className={styles.TEContentBox}
        onMouseOut={onMouseEvent}
        onMouseOver={onMouseEvent}
      >
        <div className={styles.TEContent}>
          {isLunch && (
            <div className={styles.TEText}>
              <span className={styles.LunchDuration}>
                {ApprovedDurationString}
              </span>
              {ShowStateAllocation && renderStateCodeDropDown(StateCode)}
              {CostTypeCode} ({ActualDurationString})
            </div>
          )}
          {!isLunch && (
            <div className={styles.TEText}>
              <span className={styles.TEEditDuration}>
                <DurationInput
                  start={Start}
                  finish={Finish}
                  duration={ApprovedDuration}
                  onChange={onTimeChange}
                />
              </span>
              {ShowStateAllocation && renderStateCodeDropDown(StateCode)}
              <AllocationInfo
                tcInfo={tcInfo}
                updateAllocation={props.updateAllocation}
                data={data}
                tcId={tcId}
                workOrders={workOrders}
                onCloseAllocationCard={props.onCloseAllocationCard}
              />
            </div>
          )}
        </div>
        <Popover
          animate={false}
          show={isShownPopover}
          position={"left"}
          anchor={elementRef && elementRef.current}
          className={styles.TEDetailsPopover}
        >
          {renderPopoverContent()}
        </Popover>
      </div>
    </div>
  );
};

export default AdjustmentEditRow;
