import { Component } from "react";
import DashboardGrid from "../Components/Dashboard/Grid";
import BPTabs from "../Components/Tabs/BPTabs";
import { IComboboxItem, simpleObject } from "../helpers/interfaces";
import {
  gridChangeDataAction,
  IColumnSetting,
  IFilterSetting,
} from "../Components/Dashboard/interfaces";
import OpenCardLink from "../Components/Common/Buttons/OpenCardLink";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { RunScriptAsync } from "../helpers/runscripts";
import {
  CREW_LEAD_SUMMARY_FILTERS_SS,
  CREW_LEAD_SUMMARY_LS_PREFIX,
  FILTER_BY_CLASS_ID,
} from "../Pages/CrewLeadSummary/helpers";

interface state {}

interface props {
  isActive: boolean;
  switcher: any;
  classesList: Array<IComboboxItem>;
}

class LaborDashboard extends Component<props, state> {
  FILTERS: Array<IFilterSetting> = [
    {
      id: FILTER_BY_CLASS_ID,
      placeholder: "Filter by Class",
      idColumn: "ClassID",
      labelColumn: "ClassName",
      type: "combobox",
      toolbarRow: 1,
      values: this.props.classesList,
      localStoragePrefix: CREW_LEAD_SUMMARY_LS_PREFIX,
    },
    {
      id: "filterCustomer",
      placeholder: "Filter by Customer",
      idColumn: "CustomerID",
      labelColumn: "CustomerName",
      type: "combobox",
      toolbarRow: 1,
    },
    {
      id: "filterProfitCenter",
      placeholder: "Filter by Profit Center",
      idColumn: "ProfitCenterID",
      labelColumn: "ProfitCenterName",
      type: "combobox",
      toolbarRow: 1,
    },
    {
      id: "filterBPState",
      placeholder: "BP State",
      idColumn: "BPState",
      width: "105px",
      type: "combobox",
      values: [
        { Name: "All", Id: "" },
        { Name: "Active", Id: "A" },
        { Name: "Closed", Id: "C" },
      ],
      defValue: "",
      toolbarRow: 1,
    },
    {
      id: "hasBudget",
      idColumn: "PlannedRevenueBudget",
      label: "Has Budget",
      type: "checkbox",
      defValue: true,
      filterByCustom: function (item: simpleObject, checkBoxValue: boolean) {
        if (!checkBoxValue) return true;
        return (
          item.BudgetHrsConsumed !== null ||
          item.BudgetHrs !== null ||
          item.BugetHrsUponComplection !== null
        );
      },
      toolbarRow: 1,
    },
  ];

  render() {
    let values: Array<number> = [];
    let crewLeadsSaved = window.sessionStorage.getItem(
      CREW_LEAD_SUMMARY_FILTERS_SS
    );
    if (crewLeadsSaved !== null) {
      values = JSON.parse(crewLeadsSaved);
    }
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={this.COLUMNS}
        filters={this.FILTERS}
        getSqlParams={{ spName: "DB_WIPLabor" }}
        saveSettings={{ prefix: "labor", saveFilters: true }}
        excelFileName="Labor.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.BPID}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{ switcher: this.props.switcher }}
        initRefresh={this.InitRefresh}
        selectSettings={{
          pageId: "LaborDashboard",
          Component: BPTabs,
          componentProps: {
            doNotSaveFilters: true,
          },
          onSelectRow: this.OnSelectRow,
        }}
        defaultSort={[
          {
            dir: "desc",
            field: "IsExcludeFromCrewLeadBDStr",
          },
        ]}
        defaultColumnValuesFilter={
          values.length
            ? [
                {
                  field: "CrewLeadId",
                  values,
                },
              ]
            : undefined
        }
        onChangeGridData={this.OnChangeGridData}
        prepareData={this.PrepareData}
      />
    );
  }

  OnChangeExcludeFromCrewLeadDashboard = async (e: CheckboxChangeEvent) => {
    let bpId = e.target.name;
    if (bpId) {
      try {
        await RunScriptAsync("FSMBuildPlans_UpdateExcludeFromCrewLeadDB", {
          BPID: +bpId,
          ExcludeFromCrewLeadDB: !e.value,
        });
      } catch (e: any) {
      } finally {
        this.Refresh();
      }
    }
  };

  OnChangeGridData = (data: simpleObject[], action: gridChangeDataAction) => {
    let filtersByValues = window.localStorage.getItem("labor_ValuesFilter");
    if (filtersByValues !== null) {
      let filters = JSON.parse(filtersByValues);
      if (filters.CrewLeadId) {
        let values: Array<number> = [];
        filters.CrewLeadId.forEach((item: simpleObject) => {
          values.push(+item.value);
        });
        window.sessionStorage.setItem(
          CREW_LEAD_SUMMARY_FILTERS_SS,
          JSON.stringify(values)
        );
      }
    }
  };

  PrepareData = (result: [Array<simpleObject>]) => {
    result[0].forEach((row) => {
      row.IsExcludeFromCrewLeadBDStr = row.IsExcludeFromCrewLeadBD
        ? "No"
        : "Yes";
    });
    return result[0];
  };

  OnSelectRow = (row: any) => {
    this.setState({ buildPlanId: row && row.BPID });
  };

  Refresh = () => {
    this.GridRefresh();
  };

  COLUMNS: Array<IColumnSetting> = [
    {
      field: "RowNum",
      title: "#",
      type: "number",
      filterable: false,
      columnMenu: false,
      gridWidth: 40,
      locked: true,
      aggregate: "count",
    },
    {
      field: "IsExcludeFromCrewLeadBDStr",
      title: (
        <span>
          Show in <br /> Summary
        </span>
      ),
      type: "string", // todo boolean
      gridWidth: 120,
      locked: true,
      filterable: false,
      format: {
        type: "string", // todo boolean
        fieldId: "IsExcludeFromCrewLeadBD",
        customFormat: true,
        getCustomFormat: (dataItem: simpleObject) => {
          return (
            <div style={{ pointerEvents: "all", textAlign: "center" }}>
              <Checkbox
                key={dataItem.BPID + dataItem.IsExcludeFromCrewLeadBD}
                id={"exclFromCrDashb" + dataItem.BPID}
                label={" "}
                name={dataItem.BPID + ""}
                defaultChecked={!dataItem.IsExcludeFromCrewLeadBD}
                onChange={this.OnChangeExcludeFromCrewLeadDashboard}
              />
            </div>
          );
        },
      },
    },
    {
      field: "ProjectName",
      title: "Project",
      type: "string",
      gridWidth: 120,
      locked: true,
      format: {
        type: "string",
        fieldId: "ProjectID",
      },
    },
    {
      field: "BPNum",
      title: "Build Plan",
      type: "string",
      gridWidth: 220,
      locked: true,
      format: {
        type: "link",
        fieldId: "BPID",
        customFormat: true,
        getCustomFormat: (dataItem) => (
          <OpenCardLink
            text={dataItem.BPNum}
            dataAttr={dataItem.BPID}
            refresh={this.Refresh}
            refName={"FSMBuildPlans"}
          />
        ),
      },
    },
    {
      field: "BPOName",
      title: "BP Owner",
      type: "string",
      gridWidth: 150,
      locked: true,
      format: {
        type: "string",
        fieldId: "BPOID",
      },
    },
    {
      field: "CrewLead",
      title: "Crew Lead",
      type: "string",
      gridWidth: 150,
      locked: true,
      format: {
        type: "string",
        fieldId: "CrewLeadId",
      },
    },
    {
      field: "BudgetHrs",
      title: "Budget Hours",
      type: "number",
      format: { type: "number" },
      aggregate: "sum",
    },
    {
      field: "ActualHours",
      title: "Actual Hours",
      type: "number",
      format: { type: "number" },
      aggregate: "sum",
    },
    {
      field: "OnSiteHours",
      title: "On-site Hours",
      type: "number",
      format: { type: "number" },
      aggregate: "sum",
    },
    {
      field: "OnSiteHoursPerc",
      title: "% of On-Site Hrs",
      type: "number",
      format: { type: "percent" },
    },
    {
      field: "OvertimeHours",
      title: "Overtime Hours (OT + DT)",
      type: "number",
      format: { type: "number" },
    },
    {
      field: "OvertimeHoursPerc",
      title: "% of Overtime Hrs",
      type: "number",
      gridWidth: 105,
      format: { type: "percent" },
    },
    {
      field: "BudgetHrsConsumed",
      title: "% of Budget Hours Consumed",
      type: "number",
      gridWidth: 130,
      format: { type: "percent" },
      exportToExcel: true,
    },
    {
      field: "EstimatedCompletion",
      title: "% Completion Estimate",
      type: "number",
      gridWidth: 105,
      format: { type: "percent" },
    },
    {
      field: "BugetHrsUponComplection",
      title: "Current Baseline  based upon % Completion Estimate",
      type: "number",
      format: { type: "number" },
    },
    {
      field: "VarActualHrs",
      title: "Current Variance (hrs. +- from Current Baseline)",
      type: "number",
      gridWidth: 120,
      format: {
        type: "number",
        formatNull: "-",
        negativeColor: "green",
        positiveColor: "red",
      },
      aggregate: "sum",
    },
    {
      field: "VarBudgetPerc",
      title: "Current Variance (%. +- from Current Baseline)",
      type: "number",
      format: {
        type: "percent",
        formatNull: "-",
        negativeColor: "green",
        positiveColor: "red",
      },
    },
    {
      field: "ProjFinHrs",
      title: "Projected Final Hours",
      type: "number",
      gridWidth: 110,
      format: { type: "number" },
      aggregate: "sum",
    },
    {
      field: "EstimToCopl",
      title: "Estimated to Complete",
      type: "number",
      gridWidth: 105,
      format: { type: "number" },
      aggregate: "sum",
    },
    {
      field: "ProjFinVar",
      title: "Projected Final Variance (Actual hrs +- from Plan)",
      type: "number",
      format: {
        type: "number",
        formatNull: "-",
        negativeColor: "green",
        positiveColor: "red",
      },
      aggregate: "sum",
    },
    {
      field: "ProjFinVarPerc",
      title: "Projected Final Variance (% of Plan)",
      type: "number",
      gridWidth: 110,
      format: {
        type: "percent",
        formatNull: "-",
        negativeColor: "green",
        positiveColor: "red",
      },
    },
  ];

  GridRefresh = () => {};

  InitRefresh = (refresh: any) => {
    this.GridRefresh = refresh;
  };
}

export default LaborDashboard;
