/* import {
	withRouter
} from "react-router-dom"; */
/* import { RouteComponentProps } from "react-router"; */
import {
    Drawer,
    DrawerContent,
    DrawerItemProps,
    Menu,
    MenuItem
} from '@progress/kendo-react-layout';
import logo from '../../assets/img/Fieldclix-Logo.svg';
import {IntegrationWithDesktop, isConterra} from '../../helpers/settings';

import styles from './Home.module.scss';
import './Home.scss';
import User from './User';
// import { setNav } from '../../helpers/navigation';
import {Badge} from '@progress/kendo-react-indicators';
import BaseComponent from '../../Components/BaseComponent';
import {
    ModalComponentInIframe
} from '../../Components/Common/PortalLiveMap/PortalLiveMap';
import {simpleObject} from '../../helpers/interfaces';
import UserInfo from '../../stores/User';
import {PAGES} from './helpers';
import {
    IDrawerItemServer,
    IMenuItem,
    pageId,
    parentMenuItem
} from './interfaces';
import SwitchDesktopIntegration from './SwitchDesktopIntegration';

interface state {
    selected: pageId | null
    expanded: boolean
    menuItems: Array<IMenuItem>
    userName: string
    refreshVimKey: number
    isDesktopUser: boolean
}

interface props {
    isIntegrationWithDesktop: boolean
}

class Home extends BaseComponent</* RouteComponentProps */ props, state> {
    initedPages: Array<pageId> = []
    pagesRefs: { [key in pageId]?: any } = {}
    isVIMAvailable: boolean = false
    isTKReviewAvailable: boolean = false

    constructor(props: props /* props: RouteComponentProps */) {
        super(props)
        this.state = {
            selected: null /* this.props.history.location.pathname.slice(1) */,
            expanded: true /* window.location.pathname === '/' */,
            menuItems: [],
            userName: '',
            refreshVimKey: +new Date(),
            isDesktopUser: false
        }
        /* this.props.history.listen(this.onRouteChange);
        setNav(this.props.history); */
    }

    componentDidMount() {
        document.title = 'Dashboards'
        this.SetUserData()
        this.LoadAvailableDashboards()
    }

    componentDidUpdate(prevProps: props) {
        let tkReviewRef = this.pagesRefs['TKAuditCover']
        if (tkReviewRef && prevProps.isIntegrationWithDesktop !== this.props.isIntegrationWithDesktop) {
            let tcVM = tkReviewRef.iframeRef?.contentWindow.tcVM
            tcVM.set('isIntegrationWithDesktop', this.props.isIntegrationWithDesktop);
            tcVM.timeCards.sync();
        }
    }

    InitPageRef = (ref: any, pageId: pageId) => {
        if (ref && !this.pagesRefs[pageId] && this.pagesRefs[pageId] !== ref) {
            this.pagesRefs[pageId] = ref
        }
    }

    render() {
        return (
            <Drawer
                style={{height: '100%'}}
                expanded={this.state.expanded}
                position={'start'}
                mode={'overlay'}
                mini={true}
                miniWidth={45}
                items={[{text: ''}]}
                item={this.renderCustomItem}
                onOverlayClick={this.ToggleDrawer}
                onSelect={this.OnSelectPage}
            >
                <DrawerContent>
                    <ModalComponentInIframe/>
                    {this.renderPages()}
                </DrawerContent>
            </Drawer>
        )
    }

    renderCustomItem = () => {
        return <>
            <div className={`${styles.DrawerRow} ${styles.HeaderDrawer}`}>
				<span
                    onClick={this.ToggleDrawer}
                    className={`${styles.ToggleDrawer} ${styles.MenuIcon} mdi mdi-menu`}
                ></span>
                <span className={styles.DrawerItemText}>
					<img src={logo} className={styles.Logo} alt='Fieldclix'/>
				</span>
            </div>

            <Menu
                key={this.state.selected}
                vertical={true}
                style={{
                    width: this.state.expanded ? '240px' : '45px',
                    background: '#fff'
                }}
                onSelect={this.OnSelectPage}
                itemRender={this.renderMenuItemContent}
            >
                {this.state.menuItems.map((item) => this.renderMenuItem(item))}
            </Menu>

            <div className={styles.FooterDrawer}>
                {!isConterra && this.state.isDesktopUser && (
                    <div className={styles.DrawerRow}>
                        <SwitchDesktopIntegration/>
                    </div>
                )}
                <div className={styles.DrawerRow}>
                    <a
                        href='https://fieldclix.freshdesk.com/support/home'
                        target='_blank'
                        rel='noreferrer'
                        className={'k-icon'}
                    >
                        <span
                            className={' mdi mdi-chat-question-outline'}></span>
                    </a>
                    <User className={styles.DrawerItemText}
                          user={this.state.userName}/>
                </div>
            </div>
        </>
    }

    renderMenuItem = (item: IMenuItem) => {
        let children = item.children?.length ? item.children.map((subItem) => this.renderMenuItem(subItem)) : undefined
        let selected = item.children?.length ? item.children.find((subItem) => subItem.route === this.state.selected) : item.route === this.state.selected
        return <MenuItem
            icon={item.icon}
            text={item.text}
            data={item.route}
            key={item.route}
            cssClass={`${selected ? 'k-state-selected' : ''} main-menu-item`}
        >
            {children}
        </MenuItem>
    }

    renderMenuItemContent = (e: any) => {
        let text = e.item.text
        let icon = e.item.icon
        let route: pageId | parentMenuItem = e.item.data
        return <div style={{
            display: 'flex',
            width: this.state.expanded ? '195px' : 'auto',
            alignItems: 'center'
        }}>
            <span title={text} className={`${styles.MenuIcon} ${icon}`}></span>
            <span className={styles.DrawerItemText}>{text}</span>
            {(route === "VIM" || route === 'VIM_Review') && <Badge
                className={`vim-badge ${styles.MenuBadge}`}
                shape="circle"
                themeColor="error"
                align={{horizontal: 'start', vertical: 'top'}}
                size="small"
            >{window.vimBadgeText}</Badge>}

            {(route === "TK" || route === 'TKAuditCover') && <Badge
                className={`tkReview-badge ${styles.MenuBadge}`}
                shape="circle"
                themeColor="error"
                align={{horizontal: 'start', vertical: 'top'}}
                size="small"
            >{window.tkReviewBadgeText}</Badge>}
        </div>
    }

    renderPages = () => {
        return this.initedPages.map((pageId) => {
            let PageComponent = PAGES[pageId].component
            let isActive = this.state.selected === pageId
            let props: simpleObject = {isActive}
            if (this.IsIframe(pageId)) {
                props.folder = pageId
                if (pageId === 'TKAuditCover') props.id = 'TKAuditCoverFrame'
            }
            if (pageId === 'LiveMap') {
                props.mode = 'page'
                props.prefix = 'page'
            }
            if (pageId === 'Calendar') {
                props.id = 'page'
            }
            return (
                <div
                    id={pageId}
                    key={pageId}
                    className={`${styles.PageWrapper} ${!isActive ? styles.PageHidden : ''}`}
                >
                    <PageComponent
                        {...props}
                        ref={pageId === 'LiveMap' ? undefined : (ref: any) => {
                            this.InitPageRef(ref, pageId)
                        }}
                    />
                </div>
            )
        })
    }

    LoadAvailableDashboards = async () => {
        let dashboards = await this.GetSQLData({
            spName: 'DB_AvailableDashboards',
            modal: true,
        })
        if (!dashboards || !dashboards.length || !dashboards[0].length) return

        let availablePages: Array<IDrawerItemServer> = dashboards[0]
        let topLevelItems = availablePages.filter((page) => page.LeadingItem === null)
        let subItems = availablePages.filter((page) => !!page.LeadingItem)

        let selected = availablePages.length === 1 && this.IsPageID(availablePages[0].Item) ? availablePages[0].Item : null
        this.isVIMAvailable = !!availablePages.find((item) => item.Item === 'VIM_Review')
        this.isTKReviewAvailable = !!availablePages.find((item) => item.Item === 'TKAuditCover')
        let menuItems: Array<DrawerItemProps> = topLevelItems.map((item) => {
            let iconName = item.Icon.split('/')[1]
            let iconClassName = (item.Icon.split('/')[0] === 'Foundation' ? 'fi-' : 'mdi mdi-') + iconName
            let route = item.Item
            let subMenu = subItems.filter((subItem) => subItem.LeadingItem === route)
            let children: Array<DrawerItemProps> = subMenu.map((subItem) => {
                let iconName = subItem.Icon.split('/')[1]
                let iconClassName = (subItem.Icon.split('/')[0] === 'Foundation' ? 'fi-' : 'mdi mdi-') + iconName
                let route = subItem.Item
                return {
                    icon: iconClassName,
                    text: subItem.Title,
                    route,
                }
            })
            let page: DrawerItemProps = {
                icon: iconClassName,
                text: item.Title,
                route,
                children: children.length ? children : undefined
            }
            return page
        })

        if (selected) this.initedPages.push(selected)
        if (this.isVIMAvailable && selected !== 'VIM_Review') this.initedPages.push('VIM_Review')
        if (this.isTKReviewAvailable) {
            await window.helpers.TKReviewUpdatePendingCount()
        }
        this.setState({
            menuItems,
            selected,
            expanded: availablePages.length > 1
        })
    }

    IsPageID(foo: pageId | parentMenuItem): foo is pageId {
        return !!foo
    }

    SetUserData = async () => {
        let settings = await UserInfo.getInfo()
        if (settings) {
            let isDesktopUser = isConterra || settings.IsDesktopUser;
            let userName = settings.Name || 'unknown'
            if (!isDesktopUser) localStorage.removeItem(IntegrationWithDesktop)
            window.helpers.isDesktopUser = isDesktopUser // ??
            this.setState({userName, isDesktopUser})
        }
    }

    OnSelectPage = (e: any) => {
        let route: pageId = e.item.data
        if (!PAGES[route]) return
        let title = e.item.text
        let oldSelected = this.state.selected
        const calendarLSView = 'calendarViewName_page'
        const CLMcalendarLSView = 'calendarViewName_clm'
        if (
            (oldSelected === 'Calendar' && localStorage.getItem(calendarLSView) === 'map') ||
            (oldSelected === 'CLPM' && localStorage.getItem(CLMcalendarLSView) === 'map')
        ) {
            window.helpers.liveMapPortalRemove()
        } else if (oldSelected === 'TKAuditCover') {
            window.helpers.timeCardPortalHide()
        }
        if (route === 'Calendar' && localStorage.getItem(calendarLSView) === 'map') {
            let refWindow = this.pagesRefs['Calendar']?.calendarRef?.iframeRef?.contentWindow
            if (refWindow && refWindow.liveMapPortalRestore) {
                refWindow.liveMapPortalRestore()
            }
        } else if (route === 'TKAuditCover') {
            let TKReviewIframe = document.getElementById('TKAuditCoverFrame')
            if (TKReviewIframe) {
                // @ts-ignore
                let iframeWindow = TKReviewIframe.contentWindow
                if (iframeWindow && iframeWindow.tcVM?.selectedTCId) {
                    window.helpers.timeCardPortal(iframeWindow.tcVM.selectedTCId)
                }

            }
        }

        /* this.props.history.push(route); */
        if (this.initedPages.indexOf(route) === -1) {
            this.initedPages.push(route)
        }
        document.title = title
        this.setState({selected: route, expanded: false})

        let pageRef = this.pagesRefs[route]
        if (pageRef && pageRef.Refresh) pageRef.Refresh()
    }

    IsIframe = (folder: string) => {
        return (
            folder === 'FXApp' ||
            folder === 'TKAuditCover' ||
            folder === 'Calendar'
        )
    }

    ToggleDrawer = () => {
        this.setState((state) => ({expanded: !state.expanded}))
    }

    /* onRouteChange = (location: any, action: string) => {
      if(action === 'POP') this.setState({selected: location.pathname.slice(1)})
    } */
}

export default /* withRouter( */ Home /* ) */
