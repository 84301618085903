import React, { useRef, useState } from "react";
import styles from "./livemap.module.scss";
import { Popover } from "@progress/kendo-react-tooltip";
import { IEmployee } from "./interfaces";
import debounce from "lodash.debounce";
import PinToggle from "./PinToggle";

interface IProps {
  employee: IEmployee;
  isLoading: boolean;
  isPinned: boolean;
  pinnedColor: string | null;
  onTogglePin: any;
}

const EmployeeInfo = (props: IProps) => {
  const { employee, isLoading, onTogglePin, isPinned, pinnedColor } = props;
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const onMouseEvent = debounce((e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.type === "mouseover";
    if (showPopover === value) return;
    setShowPopover(value);
  }, 10);

  return (
    <React.Fragment>
      <div
        ref={elementRef}
        className={styles.ListItemInfo}
        onMouseOut={employee.IsOldGPS ? onMouseEvent : undefined}
        onMouseOver={employee.IsOldGPS ? onMouseEvent : undefined}
      >
        <PinToggle
          isPinned={isPinned}
          color={pinnedColor}
          isLoading={isLoading}
          onClick={onTogglePin}
        />
        <div
          className={`${
            employee.IsWorkingNow
              ? styles.EmployeeActiveStatus
              : styles.EmployeeInactiveStatus
          }`}
        >
          {employee.IsOldGPS && (
            <span
              className={"mdi mdi-exclamation-thick"}
              style={{ color: "red", marginLeft: -5 }}
            ></span>
          )}
          {employee.EmployeeName}
        </div>
        <span
          className={`${styles.ListItemDuration} ${styles.ListItemFullDuration}`}
        >
          {employee.Duration}
        </span>
      </div>
      {employee.IsOldGPS && (
        <Popover
          animate={false}
          show={showPopover}
          position={"left"}
          anchor={elementRef && elementRef.current}
          offset={{ left: 0, top: 0 }}
        >
          {"Last GPS points: " + employee.LastLogsTime}
        </Popover>
      )}
    </React.Fragment>
  );
};

export default EmployeeInfo