import React from "react";
import BaseComponent from "../../Components/BaseComponent";
import DashboardGrid from "../../Components/Dashboard/Grid";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import CLPMSettings from "../../stores/CLPMSettings";
import { ICLMSettings } from "../../stores/interfaces";

interface props {
  isActive: boolean;

  onChangeGridData?(data: Array<simpleObject>): void;
}

interface state {
  remountKey: number;
  buildPlans: Array<IComboboxItem>;
  bpLoading: boolean;
  settingsInited: boolean;
}

class CLMStatusDashboard extends BaseComponent<props, state> {
  gridRef: any = React.createRef();
  bpId: number | null = null;
  filters: Array<IFilterSetting>;
  columns: Array<IColumnSetting> = [];
  settings: ICLMSettings | null = null;

  constructor(props: props) {
    super(props);
    this.state = {
      remountKey: +new Date(),
      buildPlans: [],
      bpLoading: false,
      settingsInited: false,
    };
    this.bpId = this.GetSeavedSelectedBP();
    this.filters = [
      {
        id: "filterBuildPlanId",
        placeholder: "Filter by Build Plan",
        type: "combobox",
        width: 300,
        serverFilter: {
          param: "bpId",
          getData: CLPMSettings.getActiveBuildPlans,
        },
        toolbarRow: 1,
      },
    ];
  }

  async componentDidMount() {
    await this.InitSettings();
  }

  InitSettings = async () => {
    this.settings = await CLPMSettings.getSettings();
    this.columns = [
      {
        field: "bpName",
        title: "Build Plan",
        minWidth: 200,
        type: "string",
        format: {
          type: "string",
          fieldId: "bpId",
        },
      },
      {
        field: "woName",
        title: "Work Order",
        minWidth: 200,
        type: "string",
        format: {
          type: "string",
          fieldId: "id",
        },
      },
    ];

    if (
      !this.settings.UseCustomer ||
      this.settings.IsReviewer ||
      this.settings.IsSubmitter
    ) {
      this.columns.push({
        field: "copLead",
        title: "COP Lead",
        gridWidth: 150,
        type: "string",
      });
      this.columns.push({
        field: "assignedTo",
        title: "Assigned To",
        gridWidth: 150,
        type: "string",
      });
    } else {
      this.columns.push({
        field: "companyName",
        title: "Assigned To",
        gridWidth: 150,
        type: "string",
      });
    }

    this.columns = this.columns.concat([
      {
        field: "publishedDate",
        title: "Publish Date",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "started",
        title: "Started",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "lastActivity",
        title: "Last Activity",
        type: "date",
        format: {
          type: "date",
        },
      },
    ]);

    if (this.settings.IsReviewer || this.settings.IsSubmitter) {
      this.columns = this.columns.concat([
        {
          field: "cliCnt",
          title: "# Required Photos",
          gridWidth: 90,
          type: "number",
          format: {
            type: "number",
          },
        },
        {
          field: "completionPrc",
          title: "Completion %",
          type: "number",
          format: {
            type: "percent",
          },
        },
        {
          field: "reviewedPrc",
          title: "Reviewed %",
          type: "number",
          format: {
            type: "percent",
          },
        },
        {
          field: "approvedPrc",
          title: "Approved %",
          type: "number",
          format: {
            type: "percent",
          },
        },
        {
          field: "rejected",
          title: "# Out For Correction",
          gridWidth: 90,
          type: "number",
          format: {
            type: "number",
          },
        },
      ]);
      if (this.settings.UseCustomer) {
        this.columns = this.columns.concat([
          {
            field: "customerRejected",
            title: "# Customer Rejected",
            gridWidth: 90,
            type: "number",
            format: {
              type: "number",
            },
          },
        ]);
      }
      this.columns = this.columns.concat([
        {
          field: "status",
          title: "Status",
          gridWidth: 130,
          type: "string",
          format: {
            type: "string",
            fieldId: "status",
          },
        },
      ]);
    }
    if (this.settings.IsCustomer) {
      this.columns = this.columns.concat([
        {
          field: "customerCliCnt",
          title: "# Required Photos",
          type: "number",
          format: {
            type: "number",
          },
        },
        {
          field: "customerApprovedPrc",
          title: "Approved %",
          type: "string",
          format: {
            type: "percent",
          },
        },
        {
          field: "customerRejected",
          title: "# Out For Correction",
          type: "number",
          format: {
            type: "number",
          },
        },
        {
          field: "status",
          title: "Status",
          gridWidth: 130,
          type: "string",
          format: {
            type: "string",
            fieldId: "status",
          },
        },
      ]);
    }
    this.setState({ settingsInited: true });
  };

  render() {
    if (this.state.bpLoading || !this.state.settingsInited) return null;
    return (
      <DashboardGrid
        ref={this.gridRef}
        isActive={this.props.isActive}
        key={this.state.remountKey}
        columnsSettings={this.columns}
        filters={this.filters}
        getSqlParams={{ spName: "CLPM_GetDashboardData" }}
        saveSettings={{ prefix: "CLM", saveFilters: true }}
        initRefresh={this.InitRefresh}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.id}
        selectSettings={{
          pageId: "CLPM",
          onSelectRow: this.OnSelectWO,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  OnSelectWO = (dataItem: simpleObject) => {
    if (dataItem && CLPMSettings.RouteToReview) {
      localStorage.setItem(
        CLPMSettings.CLM_BUILDPLAN_LS,
        JSON.stringify({
          Name: dataItem.bpName,
          Id: dataItem.bpId,
        })
      );
      CLPMSettings.RouteToReview(dataItem.id, dataItem.bpId);
    }
  };

  GetSeavedSelectedBP = () => {
    let bp = localStorage.getItem(CLPMSettings.CLM_BUILDPLAN_LS);
    let selectedBp = bp ? JSON.parse(bp) : undefined;
    let newBpId = selectedBp && +selectedBp.Id;
    return newBpId;
  };

  RefreshOnActivateCLMTab = () => {
    // external function call
    let newBpId = this.GetSeavedSelectedBP();
    if (this.bpId !== newBpId) {
      this.bpId = newBpId;
      this.setState({ remountKey: +new Date() });
    }
  };
}

export default CLMStatusDashboard;
