import styles from "./livemap.module.scss";
import { HEIGHT_TIME_ENTRY } from "./helpers";
import React from "react";
import { TStateData } from "../TKReview/interfaces";

const StateCodes = ({ data }: { data: TStateData }) => {
  return (
    <div className={`${styles.SateCodes}`}>
      {data.map((interval, i) => {
        return (
          <div
            key={i}
            className={`${!interval.isClockFlag ? styles.StateCode : ""} ${
              !interval.isClockFlag && !interval.stateCode
                ? styles.StateCodeEmpty
                : ""
            }`}
            style={{ height: interval.heightMultiplier * HEIGHT_TIME_ENTRY }}
          >
            {!!interval.stateCode && (
              <div className={styles.StateCodeText}>{interval.stateCode}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default StateCodes;