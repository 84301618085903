import { Component } from "react";
import { IFilterSetting, IPeriod, periodId } from "./interfaces";
import {
  CUSTOM_PERIOD_ID,
  GetNewRemountKey,
  GetPeriodDates,
  getPeriods,
} from "./helpers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DateFilter from "../../Components/Dashboard/DateFilter";

interface props {
  defaultPeriodId: periodId;
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  filter: IFilterSetting;
  allowEmpty?: boolean;

  onChange(
    value: {
      periodId: periodId;
      from: Date | null;
      to: Date | null;
    },
    filter: IFilterSetting
  ): void;
}

interface state {
  reMountKey: number;
}

class DateRangeFilter extends Component<props, state> {
  period: IPeriod;
  dateFrom: Date | null;
  dateTo: Date | null;
  periods = getPeriods(this.props.allowEmpty);

  constructor(props: props) {
    super(props);
    this.state = {
      reMountKey: GetNewRemountKey(),
    };
    if (this.props.allowEmpty) {
      this.dateFrom = this.props.defaultDateFrom || null;
      this.dateTo = this.props.defaultDateTo || null;
    } else if (this.props.defaultDateFrom && this.props.defaultDateTo) {
      this.dateFrom = this.props.defaultDateFrom;
      this.dateTo = this.props.defaultDateTo;
    } else {
      let [dateFrom, dateTo] = GetPeriodDates(this.props.defaultPeriodId);
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
    }
    this.period = this.periods.find(
      (p) => p.Id === this.props.defaultPeriodId
    )!;
  }

  render() {
    return (
      <>
        <DropDownList
          key={"period" + this.state.reMountKey}
          data={this.periods}
          textField="Name"
          dataItemKey="Id"
          defaultValue={this.period}
          onChange={this.OnChangePeriod}
          style={{ width: "170px" }}
          popupSettings={{popupClass: 'dropdown-scroll-popup'}}
        ></DropDownList>
        <DateFilter
          key={"from" + this.state.reMountKey}
          defaultValue={this.dateFrom}
          filter={{
            id: "dateFrom",
            type: "date",
          }}
          onChange={this.OnDateChange}
          clear={this.props.allowEmpty ? this.OnClearDate : undefined}
        />
        <DateFilter
          key={"to" + this.state.reMountKey}
          defaultValue={this.dateTo}
          filter={{
            id: "dateTo",
            type: "date",
          }}
          onChange={this.OnDateChange}
          clear={this.props.allowEmpty ? this.OnClearDate : undefined}
        />
      </>
    );
  }

  OnClearDate = (filter: IFilterSetting) => {
    if (filter.id === "dateFrom") this.dateFrom = null;
    else if (filter.id === "dateTo") this.dateTo = null;
    if (this.period.Id !== CUSTOM_PERIOD_ID) {
      this.period = this.periods.find(
        (period) => period.Id === CUSTOM_PERIOD_ID
      )!;
    }
    this.setState({ reMountKey: GetNewRemountKey() });
    this.AfterChange();
  };

  OnDateChange = (value: any, filter: IFilterSetting) => {
    if (filter.id === "dateFrom") this.dateFrom = value;
    if (filter.id === "dateTo") this.dateTo = value;
    if (this.period.Id !== CUSTOM_PERIOD_ID) {
      this.period = this.periods.find(
        (period) => period.Id === CUSTOM_PERIOD_ID
      )!;
      this.setState({ reMountKey: GetNewRemountKey() });
    }
    this.AfterChange();
  };

  OnChangePeriod = (e: any) => {
    let value = e.target.value;
    let periodId = value.Id;
    if (this.period.Id === periodId) return;
    this.period = value;
    let [dateFrom, dateTo] = GetPeriodDates(periodId);
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.setState({ reMountKey: GetNewRemountKey() });
    this.AfterChange();
  };

  AfterChange = () => {
    this.props.onChange(
      {
        periodId: this.period.Id,
        from: this.dateFrom,
        to: this.dateTo,
      },
      this.props.filter
    );
  };
}

export default DateRangeFilter;
