import { AxiosRequestConfig } from "axios";
import { DataServerHost } from "./settings";
import { axiosQuery, showRequestError } from "./axiosHelpers";
import { ModalRef } from "../Components/Common/Modal/Modal";
import { Deferred } from "./deffered";

class InstanceSettings {
  initializedRef = new Deferred();
  systemCode: string = "";

  loadSystemCode = async (tryNumber = 0) => {
    let axiosConfig: AxiosRequestConfig = {
      method: "GET",
      url: `https://${DataServerHost}/api/settings/systemcode`,
    };
    try {
      ModalRef.startProcessing();
      let systemCode = await axiosQuery(axiosConfig);
      if (systemCode) this.systemCode = systemCode;
      this.initializedRef.resolve();
    } catch (e: any) {
      if (tryNumber === 0) {
        this.loadSystemCode(tryNumber + 1);
      } else {
        showRequestError(e, "Get System Code Error:");
      }
    } finally {
      ModalRef.stopProcessing();
    }
  };

  getSystemCodeAsync = async () => {
    if (this.systemCode) return this.systemCode;
    await this.initializedRef.promise.finally(() => {
      return this.systemCode;
    });
  };
}

export default new InstanceSettings()
