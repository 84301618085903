import axios, { AxiosRequestConfig } from "axios";
import { ModalRef } from "../Components/Common/Modal/Modal";
import AuthData from "./authData";

export const axiosQuery = async (
  axiosConfig: AxiosRequestConfig,
  tryNumber: number = 0
) => {
  //axiosConfig.timeout = 1000;
  try {
    let response = await axios(axiosConfig);
    return response.data;
  } catch (error: any) {
    if (
      axios.isCancel(error) ||
      error.message === "Request aborted" ||
      error.name === "AbortError"
    ) {
    } else if (
      JSON.stringify(error).indexOf("{4AFB2AB8-BDFD-4094-B27E-D105C8A89EFB}") >
      -1
    ) {
      return null;
    } else if (error.response && error.response.status === 401) {
      if (tryNumber < 2) {
        let data = await AuthData.queueGetAuthToken(true);
        let access_token = data[0];
        let token_type = data[1];
        axiosConfig.headers.Authorization = `${token_type} ${access_token}`;
        let result: any = await axiosQuery(axiosConfig, tryNumber + 1);
        return result;
      } else {
        // ??
        let errString = `${error.status}: ${error.statusText}
        ${error.responseText}
        `;
        throw errString;
      }
    } else {
      throw error;
    }
  }
};

export function getRequestErrorText(error: any) {
  if (error.response) return error.response.data.Message;
  else if (error.request)
    return `${error.message} ${JSON.stringify(error.request)}`;
  else return error.message;
}

export function showRequestError(
  error: any,
  title: string = "Error" /* , details?: string */
) {
  const errorDetails: any = error.response
    ? error.response.data
    : JSON.parse(JSON.stringify(error));
  const text = getRequestErrorText(error) || "Unknown Error";

  ModalRef.showDialog({
    title,
    text,
    type: "error",
    width: 620,
    details: {
      title: "Details",
      content: JSON.stringify(errorDetails),
    },
  });
}
