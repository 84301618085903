export default function NoSelectedBP() {
  return (
    <span
      style={{
        padding: "10px",
      }}
    >
      No Selected Build Plan
    </span>
  );
}