import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import { Input } from "@progress/kendo-react-inputs";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import Loader from "../Common/Loader";
import styles from "./card.module.scss";
import { IResource } from "../../Pages/CLM/interfaces";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { IFXCardProps } from "./interfaces";

interface props extends IFXCardProps {
  record?: IResource;
}

interface state {
  processing: boolean;
  isValidForm: boolean;
}

class CLMResourceCard extends BaseComponent<props, state> {
  resourseData: IResource;

  constructor(props: props) {
    super(props);

    this.state = {
      processing: false,
      isValidForm: !!this.props.record,
    };
    if (this.props.record) {
      this.resourseData = { ...this.props.record };
    } else {
      this.resourseData = {
        email: "",
        name: "",
        phone: null,
        pin: "",
        status: "Active",
      };
    }
  }

  render() {
    return (
      <FXCard
        title={this.props.record ? "Edit Record" : "New Record"}
        onClose={this.props.finally}
        initialWidth={450}
        initialHeight={260}
        originalPaddings={true}
      >
        <div className={styles.FormWrapper}>
          {this.state.processing && <Loader />}
          <Input
            placeholder="Name"
            name="name"
            defaultValue={this.resourseData.name}
            className={styles.FormField}
            onChange={this.OnChangeTextField}
          />
          <Input
            placeholder="PIN"
            name="pin"
            defaultValue={this.resourseData.pin}
            className={styles.FormField}
            onChange={this.OnChangeTextField}
          />
          <Input
            placeholder="Phone"
            name="phone"
            defaultValue={this.resourseData.phone || ""}
            className={styles.FormField}
            onChange={this.OnChangeTextField}
          />
          <Input
            placeholder="Email"
            name="email"
            defaultValue={this.resourseData.email}
            className={styles.FormField}
            onChange={this.OnChangeTextField}
          />
          <ComboBox
            className={styles.FormField}
            data={["Active", "Closed"]}
            defaultValue={this.resourseData.status}
            onChange={this.OnChangeStatus}
            placeholder={"Status"}
          />

          <div className={`${styles.FormFooter} k-action-buttons`}>
            <Button onClick={this.props.finally}>Cancel</Button>
            <Button
              onClick={this.AddRecord}
              themeColor="primary"
              disabled={!this.state.isValidForm}
            >
              {this.props.record ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </FXCard>
    );
  }

  OnChangeStatus = (e: any) => {
    this.resourseData.status = e.value;
    this.CheckValidForm();
  };

  OnChangeTextField = (e: any) => {
    let field = e.target.name;
    // @ts-ignore
    this.resourseData[field] = e.value;
    if (field === "name") {
      this.CheckValidForm();
    }
  };

  CheckValidForm = () => {
    let validState = this.state.isValidForm;
    let isValid = !!this.resourseData.name && !!this.resourseData.status;
    if (isValid !== validState) {
      this.setState({ isValidForm: isValid });
    }
  };

  AddRecord = async () => {
    if (!this.resourseData.name) return;
    try {
      this.setState({ processing: true });
      let params = this.resourseData;
      await RunScriptAsync("CLPM_UpsertResource", params);
      if (this.props.finally) this.props.finally();
    } finally {
      this.setState({ processing: false });
    }
  };
}

export default CLMResourceCard;
