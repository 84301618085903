import { isObjectForm } from "../helpers/settings";
import TCTabs from "../Components/Tabs/TCTabs";
import stylesDashboard from "../Components/Dashboard/dashboard.module.scss";

interface props {
  tcId: number;
}

const TCTabsPage = (props: props) => {
  return (
    <div
      className={stylesDashboard.Container}
      style={{
        boxSizing: "border-box",
        paddingBottom: isObjectForm ? 5 : 0,
      }}
    >
      <TCTabs
        rowData={{ TCId: props.tcId }}
        isActive={true}
        parentId="TCCard"
      />
    </div>
  );
};

export default TCTabsPage;
