import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { IBuildPlan } from "./interfaces";
import BaseComponent from "../../Components/BaseComponent";
import ClearableInput from "../../Components/Common/Form/ClearableInput";
import { GridRowHeight } from "../../Components/Dashboard/helpers";
import Loader from "../../Components/Common/Loader";
import commonStyles from "../../assets/styles/common.module.scss";
import styles from "./daily.module.scss";
import CardManagement from "../../Components/Cards/CardManagement";
import UserInfo from "../../stores/User";

interface props {
  onChangeBuildPlan(BP: { id: number; name: string }): void;
}

interface state {
  loading: boolean;
  filter: string;
  buildPlans: Array<IBuildPlan>;
  selectedBuildPlanId: number | null;
  disabledPrev: boolean;
  disabledNext: boolean;
  canGenerateBP: boolean;
}

class DailyBuildPlans extends BaseComponent<props, state> {
  buildPlans: Array<IBuildPlan> = [];
  visibleBuildPlans: Array<IBuildPlan> = [];
  selectedBuildPlanId: number | null;
  filter: string = "";

  constructor(props: any) {
    super(props);
    let savedBP = localStorage.getItem("DailyBuildPlans_BPId");
    this.selectedBuildPlanId = savedBP ? +savedBP : null;
    this.state = {
      loading: false,
      filter: "",
      buildPlans: [],
      selectedBuildPlanId: this.selectedBuildPlanId,
      disabledPrev: false,
      disabledNext: false,
      canGenerateBP: false,
    };
  }

  componentDidMount() {
    this.GetUserSettings();
    this.LoadBuildPlans();
  }

  render() {
    return (
      <div className={commonStyles.ScreenHeightContainerWithToolbar}>
        {this.state.loading && <Loader />}
        {this.state.selectedBuildPlanId && (
          <div className={styles.SidebarArrows}>
            <Button
              className={styles.ListArrow}
              icon="arrow-60-up"
              fillMode="flat"
              onClick={this.OnSelectPrev}
              disabled={this.state.disabledPrev}
            ></Button>
            <Button
              className={styles.ListArrow}
              icon="arrow-60-down"
              fillMode="flat"
              onClick={this.OnSelectNext}
              disabled={this.state.disabledNext}
            ></Button>
          </div>
        )}

        <Toolbar>
          <ClearableInput
            defaultValue={this.state.filter}
            onChange={this.OnFilter}
            clear={this.OnFilter}
            style={{ width: 200 }}
          />
          <ToolbarSpacer />
          {this.state.canGenerateBP && (
            <Button onClick={this.AddNewBuildPlan}>New Build Plan</Button>
          )}
          <Button
            icon="refresh"
            fillMode="flat"
            title="Refresh"
            onClick={this.RefreshBuildPlans}
          ></Button>
        </Toolbar>
        <div className={commonStyles.ScrollableContainer}>
          <Grid
            className={styles.BuildPlansDailyGrid}
            style={{ height: "100%" }}
            rowHeight={GridRowHeight}
            data={this.state.buildPlans.map((bp) => ({
              ...bp,
              selected: bp.Id === this.state.selectedBuildPlanId,
            }))}
            selectedField="selected"
            onRowClick={this.OnSelectBuildPlan}
          >
            <Column field="BuildPlanName" title="Build Plan Number" />
            <Column field="CrewLead" title="Crew Lead" width="150px" />
            <Column
              field="LastActivity"
              title="Last Activity"
              width="100px"
              format="{0:MM/dd/yyyy}"
            />
          </Grid>
        </div>
      </div>
    );
  }

  GetUserSettings = async () => {
    const canGenerateBP = await UserInfo.canGenerateBP();
    this.setState({ canGenerateBP });
  };

  LoadBuildPlans = async () => {
    try {
      this.setState({ loading: true });
      let response = await this.GetSQLData({ spName: "DB_BuildPlanDaily" });
      let buildPlans = response[0] || [];
      buildPlans.forEach((bp: any) => {
        bp.LastActivity = new Date(bp.LastActivity);
      });
      this.buildPlans = buildPlans;
      this.Filter();
      if (this.selectedBuildPlanId) {
        let buildPlan = this.visibleBuildPlans.find(
          (bp: IBuildPlan) => bp.Id === this.state.selectedBuildPlanId
        );
        if (buildPlan) this.Select(buildPlan.Id, buildPlan.BuildPlanName);
        else {
          localStorage.removeItem("DailyBuildPlans_BPId");
          this.setState({ selectedBuildPlanId: null });
          this.selectedBuildPlanId = null;
        }
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  Filter = () => {
    let filter = this.filter.toLowerCase();
    let filtered = !filter
      ? this.buildPlans
      : this.buildPlans.filter(
          (bp) =>
            bp.BuildPlanName.toLowerCase().includes(filter) ||
            (bp.CrewLead && bp.CrewLead.toLowerCase().includes(filter))
        );
    this.visibleBuildPlans = filtered;
    this.setState({ buildPlans: filtered });
    this.DisabledArrows();
  };

  RefreshBuildPlans = () => {
    this.LoadBuildPlans();
  };

  OnSelectBuildPlan = (e: { dataItem: IBuildPlan }) => {
    this.Select(e.dataItem.Id, e.dataItem.BuildPlanName);
  };

  Select = (id: number, name: string) => {
    localStorage.setItem("DailyBuildPlans_BPId", `${id}`);
    this.props.onChangeBuildPlan({ id, name });
    this.setState({ selectedBuildPlanId: id });
    this.selectedBuildPlanId = id;
    this.DisabledArrows();
  };

  OnFilter = (e?: any) => {
    let value = e ? e.value : "";
    this.setState({ filter: value || "" });
    this.filter = value || "";
    this.Filter();
  };

  OnSelectNext = () => {
    let selected = this.GetSelectedInfo();
    let nextIndex =
      selected > -1 && this.visibleBuildPlans.length - 1 > selected
        ? selected + 1
        : 0;
    let nextItem = this.visibleBuildPlans[nextIndex];
    this.Select(nextItem.Id, nextItem.BuildPlanName);
  };

  OnSelectPrev = () => {
    let selected = this.GetSelectedInfo();
    let prevIndex =
      selected > -1 && selected > 0
        ? selected - 1
        : this.visibleBuildPlans.length - 1;
    let prevItem = this.visibleBuildPlans[prevIndex];
    this.Select(prevItem.Id, prevItem.BuildPlanName);
  };

  DisabledArrows = () => {
    let selected = this.GetSelectedInfo();
    let disabledNext = selected === this.visibleBuildPlans.length - 1;
    let disabledPrev = selected === 0;
    this.setState({ disabledNext, disabledPrev });
  };

  GetSelectedInfo = () => {
    let selected = this.visibleBuildPlans.findIndex(
      (item) => item.Id === this.selectedBuildPlanId
    );
    return selected;
  };

  AddNewBuildPlan = () => {
    CardManagement.OpenGBPCard();
  };
}

export default DailyBuildPlans;
