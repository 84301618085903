import LiveMap from "../../Pages/LiveMap/LiveMap";
import React, { useEffect, useRef, useState } from "react";
import { ITCInfo } from "../../Pages/LiveMap/interfaces";
import formStyles from "../Cards/card.module.scss";
import { getSQLData } from "../../helpers/queries";
import {
  IAdjustedTimeLineItem,
  IAdjustedTimeLineItemServer,
  IAdjustmentAllocation,
  IDrawAdjustedTE,
  IOriginalTimeLineItemServer,
  ITimelinesData,
  ITotalDurationProps,
} from "../../Pages/TKReview/interfaces";
import {
  GetPreparedInfo,
  GetPreparedTimeLine,
} from "../../Pages/TKReview/helpers";
import LoaderComponent from "../Common/Loader";

import SideBar from "../SideBar/SideBar";
import { useRefresher } from "../../helpers/hooks";
import TotalDurations from "./TotalDurations";
import AdjustmentsComment from "./AdjustmentsComment";
import AdjustmentsEdit from "./AdjustmentsEdit/Index";
import Adjustments from "./Adjustments/Adjustments";

interface IProps {
  tcId: number;
  adjustmentsPanelOpened: boolean;
  tcRefresh?: () => void;
  onLoadTCMapDetails?: (info: ITCInfo) => void;
  refreshTCMapKey?: number;
  isMapHidden?: boolean;
  toggleMapBtn?: any;
  modalTCMapWindowStage?: string | null;
}

const TCMap = (props: IProps) => {
  const { tcId } = props;
  const refreshTCMapKey = useRefresher();
  const [mapResizeRefresher, refreshMapSize] = useState(0);
  const [doShowAdjustmentsPanel, setDoShowAdjustmentsPanel] = useState(false);
  const [isAdjustmentsPanelOpened, setAdjustmentsPanelOpened] = useState(
    props.adjustmentsPanelOpened
  );
  const [isPreparingAdjustmentsPanel, setIsPreparingAdjustmentsPanel] =
    useState(false);
  const [timelinesData, setTimelinesData] = useState<ITimelinesData | null>(
    null
  );
  const isLoadingAdjustmentsDataRef = useRef(false);
  const TCMapSettingsRef = useRef<{
    tcId: number;
  }>({
    tcId: tcId,
  });

  useEffect(() => {
    if (!tcId || TCMapSettingsRef.current.tcId === tcId) return;
    setDoShowAdjustmentsPanel(false);
    setTimelinesData(null);
    TCMapSettingsRef.current = {
      tcId,
    };
    setAdjustmentsPanelOpened(props.adjustmentsPanelOpened);
  }, [tcId]);

  useEffect(() => {
    if (timelinesData) showAdjustments(true);
  }, [props.refreshTCMapKey]);

  useEffect(() => {
    if (!props.isMapHidden) refreshMapSize(mapResizeRefresher + 1);
  }, [props.modalTCMapWindowStage, isAdjustmentsPanelOpened]);

  const onLoadTCMapDetails = (info: ITCInfo) => {
    if (props.onLoadTCMapDetails) props.onLoadTCMapDetails(info);
    setDoShowAdjustmentsPanel(!!info.TKAdjustmentId || info.CanChange);
    showAdjustments();
  };

  const onToggleSideBar = (state: boolean) => {
    setAdjustmentsPanelOpened(state);
  };

  const showAdjustments = (forceRefresh?: boolean) => {
    if (isLoadingAdjustmentsDataRef.current || (!forceRefresh && timelinesData))
      return;
    isLoadingAdjustmentsDataRef.current = true;
    setIsPreparingAdjustmentsPanel(true);
    loadTimelinesData(tcId);
  };

  const getDrawAdjustments = (
    adjustments: IAdjustedTimeLineItem[]
  ): IDrawAdjustedTE[] => {
    const rows: IDrawAdjustedTE[] = [];
    for (let i = 0; i < adjustments.length; i++) {
      const te = adjustments[i];
      if (i > 0 && adjustments[i - 1].TimeFinish !== te.TimeStart) {
        rows.push({
          rows: 1,
          StartTimeIndex: i,
          FinishTimeIndex: i + 1,
        });
      }
      rows.push({
        te,
        rows: 1,
        Start: te.TimeStart,
        Finish: te.TimeFinish,
        StartTimeIndex: i,
        FinishTimeIndex: i + 1,
      });
    }
    return rows;
  };

  const loadTimelinesData = (TCId: number) => {
    getSQLData({
      spName: "TK_GetTCSummary",
      params: { TCId },
    })
      .then((data: any) => {
        const tcInfo = GetPreparedInfo(data[0][0]);
        const dayLog = data[4] as IOriginalTimeLineItemServer[];
        const dayLogPopup = data[5];
        const mileages = data[6];
        const adjustmentsInfo = data[7][0];
        const rareAdjustments = data[8] as IAdjustedTimeLineItemServer[];
        const adjustmentsAllocations = data[9] as IAdjustmentAllocation[];
        const {
          waivedLunches,
          adjustments,
          totalClocked,
          totalApproved,
          totalApprovedAdjustments,
          totalClockedAdjustments,
        } = GetPreparedTimeLine(
          dayLog,
          dayLogPopup,
          rareAdjustments,
          adjustmentsAllocations,
          mileages
        );
        const originalTimeLineTotals: ITotalDurationProps = {
          clocked: totalClocked,
          approved: totalApproved,
          lunch: tcInfo?.AppliedLunchDuration || undefined,
          isOriginal: true,
        };
        const adjustedTimeLineTotals: ITotalDurationProps = {
          clocked: totalClockedAdjustments,
          approved: totalApprovedAdjustments,
          lunch: adjustmentsInfo.LunchDuration || undefined,
        };
        setTimelinesData({
          adjustmentsInfo: adjustmentsInfo,
          adjustmentsTimeline: adjustments,
          adjustmentsDrawTimeline: getDrawAdjustments(adjustments),
          tcInfo: tcInfo!,
          adjusmentsWaivedLunches: waivedLunches,
          originalTimeLineTotals,
          adjustedTimeLineTotals,
        });
      })
      .finally(() => {
        setIsPreparingAdjustmentsPanel(false);
        isLoadingAdjustmentsDataRef.current = false;
      });
  };

  const onAdjustmentsSave = () => {
    refreshTCMapKey();
    if (props.tcRefresh) props.tcRefresh();
  };

  const renderAdjustmentsPanel = () => {
    return (
      <div
        className={formStyles.FormWrapper}
        style={{
          position: "relative",
          padding: "12px 4px 0",
          boxSizing: "border-box",
        }}
      >
        {isPreparingAdjustmentsPanel && <LoaderComponent />}
        {!!timelinesData && timelinesData.adjustmentsInfo.CanChange && (
          <AdjustmentsEdit
            tcId={TCMapSettingsRef.current.tcId}
            {...timelinesData}
            onSave={onAdjustmentsSave}
          />
        )}
        {!!timelinesData && !timelinesData.adjustmentsInfo.CanChange && (
          <div>
            <TotalDurations {...timelinesData.adjustedTimeLineTotals} />
            <Adjustments
              drawAdjusted={timelinesData.adjustmentsDrawTimeline}
              refresh={() => {}}
            />
            {!!timelinesData.adjustmentsInfo.Comment && (
              <AdjustmentsComment
                comment={timelinesData.adjustmentsInfo.Comment}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      <LiveMap
        isActive={true}
        tcId={TCMapSettingsRef.current.tcId}
        originalTimeLineTotals={timelinesData?.originalTimeLineTotals}
        onLoadTCDetails={
          /*!props.adjustmentsPanelData ? */ onLoadTCMapDetails /* : undefined*/
        }
        sideBarBtnText={`GPS Timeline`}
        resizeRefresher={mapResizeRefresher}
        refreshKey={props.refreshTCMapKey || refreshTCMapKey.value}
        isMapHidden={props.isMapHidden}
      />
      {doShowAdjustmentsPanel && (
        <SideBar
          style={{
            width: isAdjustmentsPanelOpened ? 430 : 30,
            flex: "0 0 auto",
            height: "100%",
          }}
          defaultOpened={!!props.adjustmentsPanelOpened}
          mode="push"
          onToggle={onToggleSideBar}
          content={renderAdjustmentsPanel()}
          btnText={"Adjustments"}
          rtl={true}
        ></SideBar>
      )}
    </div>
  );
};

export default TCMap;
