import { Component } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import TimeEntry from "./TimeEntry";
import { IDrawOriginalTE } from "../../interfaces";
import styles from "./daylog.module.scss";
import { RunScriptLocalAsync } from "../../../../helpers/runscripts";
import { IsDesktopIntegrationOn } from "../../../../helpers/helpers";

interface props {
  drawOriginal: IDrawOriginalTE[];
  IsHideUnscheduledVisitAlert: boolean;

  refresh(): void;
}

interface state {
  contextMenu: null | { left: number; top: number };
}

class DayLog extends Component<props, state> {
  contextMenuTeId: number | null = null;

  constructor(props: props) {
    super(props);
    this.state = {
      contextMenu: null,
    };
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.CloseContextMenu);
  }

  render() {
    const daylog = this.props.drawOriginal;
    let itemsHTML: Array<any> = [];
    for (let i = 0; i < daylog.length; i++) {
      let item = daylog[i];
      let te = item.te;

      const isClockInOut = !!te && te.Event.indexOf("CLOCK") > -1;
      const nextItemStart = daylog[i + 1]?.Start;
      const nextItemEvent = daylog[i + 1]?.te?.Event;
      const nextItemIsClockInOut =
        !!nextItemEvent && nextItemEvent.indexOf("CLOCK") > -1;

      if (item.Start && !isClockInOut) {
        itemsHTML.push(
          <small key={item.Start + te?.Id} className={styles.TETime}>
            {item.Start}
          </small>
        );
      }

      if (te) {
        itemsHTML.push(
          <TimeEntry
            key={te.Id}
            data={te}
            IsHideUnscheduledVisitAlert={this.props.IsHideUnscheduledVisitAlert}
            openContextMenu={this.OpenContextMenu}
            rowsLength={item.rows}
          />
        );
      } else {
        itemsHTML.push(
          <div
            key={"noItems" + item.StartTimeIndex + item.FinishTimeIndex}
            style={{ height: 30 * item.rows }}
          ></div>
        );
      }
      if (
        item.Finish &&
        !isClockInOut &&
        (nextItemIsClockInOut || nextItemStart !== item.Finish)
      ) {
        itemsHTML.push(
          <small key={item.Finish + te?.Id} className={styles.TETime}>
            {item.Finish}
          </small>
        );
      }
    }

    return (
      <>
        {itemsHTML}
        <Popup
          offset={this.state.contextMenu || undefined}
          show={!!this.state.contextMenu}
        >
          <Menu vertical={true} onSelect={this.OnSelectContextItem}>
            <MenuItem text="Create and use Location"></MenuItem>
          </Menu>
        </Popup>
      </>
    );
  }

  OnSelectContextItem = () => {
    if (this.contextMenuTeId) this.CreateLocation(this.contextMenuTeId);
  };

  OpenContextMenu = (left: number, top: number, teId: number) => {
    if (!IsDesktopIntegrationOn()) return;
    this.contextMenuTeId = teId;
    let contextMenu = { left, top };
    this.setState({ contextMenu });
    document.addEventListener("click", this.CloseContextMenu);
  };

  CloseContextMenu = () => {
    this.contextMenuTeId = null;
    this.setState({ contextMenu: null });
    document.removeEventListener("click", this.CloseContextMenu);
  };

  CreateLocation = async (teId: number) => {
    await RunScriptLocalAsync("TK_CreateLocationForTE", { TEID: teId });
    this.props.refresh();
  };
}

export default DayLog;
