import { Card } from "@progress/kendo-react-layout";
import {
  clockOutType,
  IActivity,
  IConnector,
  IMapPeriodItem,
  IRelatedLocation,
  ISiteToMap,
  ITCInfo,
  ITE,
  ITimeCard,
  ITimecardSite,
  ITimeLineRowRenderItem,
  IWO,
  platform,
  stopType,
  teEvent,
} from "./interfaces";
import styles from "./livemap.module.scss";
import moment from "moment";
import { useBooleanState } from "../../helpers/hooks";
import React, { useEffect, useRef, useState } from "react";
import { getSQLData } from "../../helpers/queries";
import WoRow from "./WORow";
import { formatDuration, formatHoursDuration } from "../../helpers/helpers";
import TERow from "./TERow";
import { getPeriodIdForFilter, SHOW_LOGS_DIV_CLASS_NAME } from "./helpers";
import Loader from "../../Components/Common/Loader";
import {
  costTypeCode,
  ITotalDurationProps,
  TStateData,
  TStateItem,
} from "../TKReview/interfaces";
import { getColorByCostType, getDuration } from "../TKReview/helpers";
import { Button } from "@progress/kendo-react-buttons";
import debounce from "lodash.debounce";
import PinToggle from "./PinToggle";
import WOsListContainer from "./WoList";

import StateCodes from "./StateCodes";
import TotalDurations from "../../Components/TC/TotalDurations";
import EmptyRowWithPeriodToggle from "./EmptyRowWithPeriodToggle";
import { DetailsActivityControl } from "../../Components/Map/DetailsActivityControl";

const teEventToStopType: {
  [key in teEvent]: stopType | "IN" | "OUT" | "LUNCH";
} = {
  STOP: "S",
  DRIVE: "D",
  CLOCKIN: "IN",
  CLOCKOUT: "OUT",
  "FORCE CLOCKOUT": "OUT",
  "REMOTE CLOCKOUT": "OUT",
  "NO GPS": "N",
  LUNCH: "LUNCH",
  NA: "N",
};

interface IProps {
  map: any;
  isTCMap: boolean;
  originalTimeLineTotals?: ITotalDurationProps;
  onLoadDetails?: (info: ITCInfo) => void;
  refreshKey: number;
  ShowStateAllocation: boolean;
  data: ITimeCard;
  isPinned: boolean;
  pinnedColor: string | null;
  isSelected: boolean;
  selectItem: (id: number) => void;
  unSelectItem: (id: number) => void;
  draw: (
    listId: number,
    periods: IMapPeriodItem[],
    connectors: IConnector[],
    trackSites: ISiteToMap[]
  ) => void;
  onTogglePin: (listId: number) => void;
  switchItem: (listId: number) => void;
  switchPeriod: (
    filteredPeriods: Set<string> | null,
    listId: number,
    clockInId: number
  ) => void;
  onTrackItemMouseEvent: (
    e: React.MouseEvent<HTMLDivElement>,
    filteredPeriods: Set<string> | null,
    clockInId: number
  ) => void;
  onDispatchMouseEvent: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onLoadDrawTrack?: (listId: number, clockInId: number) => void;
}

interface IDetail {
  clockInId: number;
  deviceId: number;
  platform: platform;
  timeEntries: ITimeLineRowRenderItem[];
}

function TimeCard(props: IProps) {
  const [, forceUpdate] = useState(0);
  const {
    isSelected,
    data,
    refreshKey,
    isTCMap,
    isPinned,
    pinnedColor,
    ShowStateAllocation,
  } = props;
  const isLoading = useBooleanState(false);
  const [details, setDetails] = useState<IDetail[] | null>(null);
  const [stateCodes, setStateCodes] = useState<TStateData | null>(null);
  const [workOrders, setWorkOrders] = useState<IWO[]>([]);
  const tcInfoRef = useRef<ITCInfo | null>(null);
  const filteredPeriodsRef = useRef<{
    [clockInId: number]: Set<string> | null;
  }>({});
  const detailsActivityRef = useRef<IActivity[] | null>(null);
  const sliderControlRef = useRef(DetailsActivityControl({ map: props.map }));

  useEffect(() => {
    if (!isSelected && !details) return;
    if (isSelected && !details) {
      loadDetails();
    } else if (details) {
      toggleSlider(isSelected);
      props.switchItem(data.ListId);
    }
  }, [isSelected]);

  useEffect(() => {
    if (!isPinned && !details) return;
    if (isPinned && !details) {
      loadDetails();
    } else if (details) {
      toggleSlider(isPinned);
      props.switchItem(data.ListId);
    }
  }, [isPinned]);

  useEffect(() => {
    // external refresh from TK Dashboard
    if (props.isTCMap) {
      toggleSlider(false);
      loadDetails();
    }
  }, [refreshKey]);

  const toggleSlider = (show: boolean) => {
    if (show) {
      const points = detailsActivityRef.current;
      if (points)
        sliderControlRef.current.initSlider(
          points,
          tcInfoRef.current!.Employee
        );
    } else {
      sliderControlRef.current.destroySlider();
    }
  };

  const loadDetails = () => {
    const TCID = props.data.TimeCardId;
    if (!TCID) return;
    isLoading.setTrue();
    getSQLData({
      spName: "TK_GetTCMapData",
      params: { TCID },
    })
      .then((result) => {
        const info = result[0][0] as ITCInfo;
        if (props.onLoadDetails) props.onLoadDetails(info);
        tcInfoRef.current = info;
        const timeEntries = result[1] as ITE[];
        const detailsActivity = result[2] as IActivity[];
        const visitedSites = result[3] as IRelatedLocation[];
        const workOrders = result[4] as IWO[];
        const tcSites = result[5] as ITimecardSite[];

        const details: IDetail[] = [];
        const mapPeriods: IMapPeriodItem[] = [];
        const roadMapStates: TStateData = [];
        let newStateRow: TStateItem | null = null;
        let stopNumber = 1;
        let clockIns: number[] = [];
        let lastPrevTEPoint: number[] | undefined = undefined; // for connectors
        let prevTEId: number | undefined = undefined; // for connectors
        const connectors: IConnector[] = [];
        for (let i = 0; i < timeEntries.length; i++) {
          const te = timeEntries[i];
          const Type = teEventToStopType[te.Event];
          const isClockOut = Type === "OUT";
          const isClockIn = Type === "IN";
          const isClockInOut = isClockIn || isClockOut;
          if (ShowStateAllocation) {
            if (
              newStateRow &&
              (isClockInOut || te.StateCode !== newStateRow.stateCode)
            ) {
              roadMapStates.push(newStateRow);
              newStateRow = null;
            }
            if (isClockInOut) {
              roadMapStates.push({
                heightMultiplier: 1,
                isClockFlag: true,
              });
            } else {
              if (!newStateRow) {
                newStateRow = {
                  heightMultiplier: 0,
                  stateCode: te.StateCode,
                };
              }
              newStateRow.heightMultiplier += 1;
            }
          }
          if (isClockIn || i === 0) {
            clockIns.push(clockIns.length + 1);
            const ClockInId = clockIns[clockIns.length - 1];
            details.push({
              clockInId: ClockInId,
              deviceId: te.DeviceId!,
              platform: te.Platform,
              timeEntries: [],
            });
            filteredPeriodsRef.current[ClockInId] = new Set([]);
          }
          const clockInIndex = clockIns.length - 1;
          const ClockInId = clockIns[clockInIndex];
          const detail: IDetail = details[clockInIndex];

          const SiteName = !isClockInOut
            ? te.SiteTitle || te.LocationTitle || "Unknown"
            : undefined;
          const Number = Type === "S" ? stopNumber++ : undefined;
          const momentStart = moment(te.Start);
          let Start: string | undefined = momentStart.format("LT");

          let Duration: string | undefined = undefined;
          let Finish: string | undefined = undefined;
          if (!isClockInOut) {
            const momentFinish = moment(te.Finish);
            Finish = momentFinish.format("LT");
            Duration = formatDuration(getDuration(te.Start, te.Finish), "time");
          }

          const isLunch = te.Event === "LUNCH";
          const isWaivedLunch = isLunch && te.Finish === te.Start;
          const CostTypeCode: costTypeCode | null | undefined = isWaivedLunch
            ? "WLUNCH"
            : te.CostType;
          const next = timeEntries[i + 1];
          const nextType = next ? teEventToStopType[next.Event] : "";
          const isNextClockOut = nextType === "OUT";
          const isLast =
            timeEntries.length - 1 === i || (!isClockIn && isNextClockOut);
          const CostTypeColor = isClockInOut
            ? ""
            : te.CostTypeColor ||
              getColorByCostType(
                CostTypeCode,
                te.IsKnownLocation,
                te.IsWarehouse
              );
          detail.timeEntries.push({
            Id: te.Id,
            ClockInId,
            Type,
            Start: !isClockInOut || isLast ? Start : undefined,
            Finish: !isClockInOut && isLast ? Finish : undefined,
            SiteName,
            Duration,
            IsUnscheduledSite:
              !info.IsHideUnscheduledVisitAlert && te.IsUnscheduledVisit,
            Number,
            ClockOutType: isClockOut ? (te.Event as clockOutType) : undefined,
            CostTypeColor,
            ClockInPlatform: isClockIn ? te.Platform : undefined,
            IsApprovedHours: !!te.ApprovedDuration,
            ApprovedDurationString:
              te.ApprovedDuration !== null
                ? formatHoursDuration(te.ApprovedDuration)
                : "",
            ActualDurationString:
              te.ActualDuration !== null
                ? formatHoursDuration(te.ActualDuration)
                : "",
            MainObjectId: te.LocationId || te.SiteId,
            TCTE: te,
          });

          const CenterCoords =
            isClockInOut || Type === "S" ? [te.Lat, te.Lng] : null;
          const Track = detailsActivity
            .filter((d) => d.TEId === te.Id)
            .map((d) => [d.Lat, d.Lng]);
          mapPeriods.push({
            Id: te.Id,
            Type,
            Time: isClockInOut ? Start : `${Start} - ${Finish}`,
            ClockOutType: isClockOut ? (te.Event as clockOutType) : undefined,
            CenterCoords,
            Number,
            Track,
            ClockInId,
            CostTypeColor,
            MainObjectId: te.LocationId || te.SiteId,
          });

          // for connectors
          if (lastPrevTEPoint && prevTEId) {
            const firtsTEPoint = isClockInOut
              ? undefined
              : CenterCoords || Track[0];
            if (firtsTEPoint) {
              connectors.push({
                coords: [lastPrevTEPoint, firtsTEPoint],
                startId: prevTEId,
                finishId: te.Id,
                clockInId: ClockInId,
              });
            }
          }
          lastPrevTEPoint = isClockInOut
            ? undefined
            : CenterCoords || Track[Track.length - 1];
          prevTEId = te.Id;
        }

        if (ShowStateAllocation && newStateRow) {
          roadMapStates.push(newStateRow);
        }
        if (ShowStateAllocation) setStateCodes(roadMapStates);

        const uniqueSites: { [key: number]: ISiteToMap } = {};
        const sitesToMap: ISiteToMap[] = [];
        for (let site of visitedSites) {
          const IsMainStopObject =
            mapPeriods.findIndex((period) => period.MainObjectId === site.Id) >
            -1;
          const uniqueSite = uniqueSites[site.Id];
          if (uniqueSite) {
            if (!uniqueSite.PeriodsInfo.IsMainStopObject && IsMainStopObject)
              uniqueSite.PeriodsInfo.IsMainStopObject = IsMainStopObject;
            continue;
          }

          const {
            Address,
            Lat,
            Lng,
            Boundaries,
            Radius,
            Id,
            Name,
            Type,
            LocationAbbr,
            LocationColor,
            IsScheduledSite,
            IsUnscheduledSite,
            TEId,
          } = site;
          const newUniqueSite: ISiteToMap = {
            Address,
            Boundaries,
            Lat,
            Lng,
            LocationAbbr,
            LocationColor,
            ObjectId: Id,
            ObjectName: Name,
            ObjectType: Type === "Location" ? "Location" : "Site",
            Radius,
            PeriodsInfo: {
              IsMainStopObject,
              PeriodId: TEId,
            },
            ScheduledInfo: {
              IsScheduledSite,
              IsUnscheduledSite,
              IsRelatedScheduledSite: null,
              DispatchIds: [],
            },
          };
          uniqueSites[Id] = newUniqueSite;
          sitesToMap.push(newUniqueSite);
        }

        for (let workOrder of workOrders) {
          const sites = tcSites.filter(
            ({ MainSiteId }) => MainSiteId === workOrder.SiteId
          );
          for (let site of sites) {
            const {
              MainSiteId,
              Name,
              Lat,
              Lng,
              Radius,
              Boundaries,
              SiteId,
              Address,
            } = site;
            const uniqueSite = uniqueSites[SiteId];
            const IsRelatedScheduledSite = MainSiteId !== SiteId;
            if (uniqueSite) {
              uniqueSite.ScheduledInfo.IsScheduledSite = true;
              if (
                uniqueSite.ScheduledInfo.IsRelatedScheduledSite === true &&
                !IsRelatedScheduledSite
              ) {
                uniqueSite.ScheduledInfo.IsRelatedScheduledSite =
                  IsRelatedScheduledSite;
              }
              uniqueSite.ScheduledInfo.DispatchIds.push(workOrder.DispatchId);
              continue;
            }

            const newUniqueSite: ISiteToMap = {
              Address,
              Boundaries,
              Lat,
              Lng,
              LocationAbbr: null,
              LocationColor: null,
              ObjectId: SiteId,
              ObjectName: Name,
              ObjectType: "Site",
              Radius,
              PeriodsInfo: {
                IsMainStopObject: false,
              },
              ScheduledInfo: {
                IsScheduledSite: true,
                IsUnscheduledSite: false,
                IsRelatedScheduledSite,
                DispatchIds: [workOrder.DispatchId], // or workOrder.WorkOrderId ??
              },
            };
            uniqueSites[SiteId] = newUniqueSite;
            sitesToMap.push(newUniqueSite);
          }
        }
        // const mileageEntries = result[6] as IMileage[];
        detailsActivityRef.current = detailsActivity;
        setDetails(details);
        setWorkOrders(workOrders);

        toggleSlider(true);
        props.draw(data.ListId, mapPeriods, connectors, sitesToMap);
        if (!false && props.onLoadDrawTrack)
          props.onLoadDrawTrack(data.ListId, 0);
      })
      .finally(() => {
        isLoading.setFalse();
      });
  };

  const onClick = () => {
    if (isSelected) props.unSelectItem(data.ListId);
    else props.selectItem(data.ListId);
  };

  const onTogglePin = debounce(() => {
    props.onTogglePin(data.ListId);
  }, 100);

  const onToggleClockIn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { clockinid } = e.currentTarget.dataset;
    if (!clockinid) return;
    if (filteredPeriodsRef.current[+clockinid]) {
      filteredPeriodsRef.current[+clockinid] = null;
    } else {
      filteredPeriodsRef.current[+clockinid] = new Set([]);
    }
    forceUpdate(+new Date());
    props.switchPeriod(
      filteredPeriodsRef.current[+clockinid],
      data.ListId,
      +clockinid
    );
  };

  const onToggleTE = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { clockinid, periodid } = e.currentTarget.dataset;
    if (!clockinid || !periodid) return;
    const filteredPeriods = filteredPeriodsRef.current[+clockinid];
    if (filteredPeriods === null) return;
    const str = getPeriodIdForFilter(periodid, +clockinid);
    const newValue = filteredPeriods.size === 0 || !filteredPeriods.has(str);
    if (newValue) {
      filteredPeriods.add(str);
    } else {
      filteredPeriods.delete(str);
    }
    forceUpdate(+new Date());
    props.switchPeriod(filteredPeriods, data.ListId, +clockinid);
  };

  const onTEMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const dataSet = e.currentTarget?.dataset || {};
    const { clockinid } = dataSet;
    if (!clockinid) return;
    props.onTrackItemMouseEvent(
      e,
      filteredPeriodsRef.current[+clockinid],
      +clockinid
    );
  };

  const doShowDetails = () => isTCMap || isSelected || isPinned;

  const renderDetails = () => {
    if (!doShowDetails() || !details) return null;
    return (
      <div
        className={`${styles.TimeCardDetails} ${
          isTCMap ? styles.TCMapTimeCardDetails : ""
        }`}
      >
        {isTCMap && !!props.originalTimeLineTotals && (
          <>
            <div style={{ height: 16 }}></div>
            <TotalDurations {...props.originalTimeLineTotals} />
          </>
        )}
        <div
          className={styles.TimeCardAllPeriods}
          style={ShowStateAllocation ? { paddingLeft: 20 } : undefined}
        >
          {ShowStateAllocation && !!stateCodes && (
            <StateCodes data={stateCodes} />
          )}
          {details.map((detail) => {
            return (
              <div
                key={detail.clockInId}
                className={`${styles.DevicePeriods} ${SHOW_LOGS_DIV_CLASS_NAME}`}
                data-deviceid={detail.deviceId}
              >
                {!!detail.timeEntries.length &&
                  detail.timeEntries.map((te, index) => {
                    const filteredPeriods =
                      filteredPeriodsRef.current[te.ClockInId];
                    let action: any = "";
                    const isClockIn = te.Type === "IN";
                    if (isClockIn) {
                      action = (
                        <Button
                          iconClass={
                            filteredPeriods
                              ? "mdi mdi-toggle-switch"
                              : "mdi mdi-toggle-switch-off"
                          }
                          onClick={onToggleClockIn}
                          rounded={"full"}
                          fillMode={"flat"}
                          themeColor={"light"}
                          size={"large"}
                          className={`${styles.DevicePeriodSwitcher} ${styles.On}`}
                          data-clockinid={te.ClockInId}
                        ></Button>
                      );
                    } else if (te.Type !== "OUT" && filteredPeriods) {
                      const str = getPeriodIdForFilter(te.Id, te.ClockInId);
                      const hasFiltered = filteredPeriods.size > 0;
                      const isOn = !hasFiltered || filteredPeriods.has(str);
                      action = (
                        <Button
                          iconClass={
                            isOn
                              ? "mdi mdi-toggle-switch-outline"
                              : "mdi mdi-toggle-switch-off-outline"
                          }
                          onClick={onToggleTE}
                          rounded={"full"}
                          fillMode={"flat"}
                          themeColor={hasFiltered && isOn ? "primary" : "light"}
                          size={"large"}
                          className={`${styles.DevicePeriodSwitcher} ${
                            hasFiltered && isOn ? styles.On : ""
                          }`}
                          data-clockinid={te.ClockInId}
                          data-periodid={te.Id}
                        ></Button>
                      );
                    }
                    const drawEmptyLineWithToggle = index === 0 && !isClockIn;
                    return (
                      <>
                        {drawEmptyLineWithToggle && (
                          <EmptyRowWithPeriodToggle
                            key={`${te.ClockInId}toggle`}
                            action={
                              <Button
                                iconClass={
                                  filteredPeriods
                                    ? "mdi mdi-toggle-switch"
                                    : "mdi mdi-toggle-switch-off"
                                }
                                onClick={onToggleClockIn}
                                rounded={"full"}
                                fillMode={"flat"}
                                themeColor={"light"}
                                size={"large"}
                                className={`${styles.DevicePeriodSwitcher} ${styles.On}`}
                                data-clockinid={te.ClockInId}
                              ></Button>
                            }
                            listId={data.ListId}
                            clockInId={te.ClockInId}
                          />
                        )}
                        <TERow
                          key={te.Id}
                          item={te}
                          listId={data.ListId}
                          clockInId={te.ClockInId}
                          onTrackItemMouseEvent={onTEMouseEvent}
                          action={action}
                          pinnedColor={pinnedColor}
                          tcTE={
                            !isClockIn && te.Type !== "OUT"
                              ? te.TCTE
                              : undefined
                          }
                          isShownStateAllocation={ShowStateAllocation}
                        ></TERow>
                      </>
                    );
                  })}
              </div>
            );
          })}
        </div>
        {!!workOrders.length && (
          <WOsListContainer>
            {workOrders.map((wo) => (
              <WoRow
                key={wo.DispatchId || wo.WorkOrderId}
                listId={data.ListId}
                id={wo.DispatchId || wo.WorkOrderId}
                name={wo.WorkOrderName}
                wasOnSite={wo.WasOnSite}
                refName={
                  wo.DispatchId ? "FSMDispatchSchedule" : "FSMWorkOrders"
                }
                onDispatchMouseEvent={props.onDispatchMouseEvent}
              />
            ))}
          </WOsListContainer>
        )}
      </div>
    );
  };

  if (props.isTCMap && isLoading.value) return <Loader />;
  if (props.isTCMap) return renderDetails();

  return (
    <div
      id={"item-" + data.ListId} // for scrollTo
      className={styles.DeviceBox}
      data-deviceid={data.DeviceId}
      onClick={onClick}
    >
      <Card className={styles.DeviceCard}>
        <div className={styles.ListItemInfo}>
          <PinToggle
            isPinned={isPinned}
            color={pinnedColor}
            isLoading={isLoading.value}
            onClick={onTogglePin}
          />
          <div>{data.EmployeeName}</div>
          <span
            className={`${styles.ListItemDuration} ${styles.ListItemFullDuration}`}
          >
            {data.Duration}
          </span>
        </div>

        {renderDetails()}
      </Card>
    </div>
  );
}

export default TimeCard;
