import { useBooleanState } from "../../helpers/hooks";
import { RunScriptAsync } from "../../helpers/runscripts";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import styles from "../Common/Form/form.module.scss";
import { ModalRef } from "../Common/Modal/Modal";

interface IProps {
  tcId: number;
  refreshActiveTab: () => void;
}

const ReprocessingTCButton = (props: IProps) => {
  const isProcessing = useBooleanState(false);

  const reprocessTc = async () => {
    ModalRef.startProcessing();
    isProcessing.setTrue();
    RunScriptAsync("ADM_ReprocessTimeCard", { TCID: props.tcId }).finally(
      () => {
        isProcessing.setFalse();
        ModalRef.stopProcessing();
        props.refreshActiveTab();
      }
    );
  };

  return (
    <div style={{ minWidth: 30, position: "relative" }}>
      {isProcessing.value ? (
        <Loader
          className={styles.ProcessingButtonLoader}
          themeColor={"dark"}
          type="converging-spinner"
          size="small"
        />
      ) : (
        <Button onClick={reprocessTc}>Reprocess Time Card</Button>
      )}
    </div>
  );
};

export default ReprocessingTCButton