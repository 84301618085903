import {
  Chart,
  ChartLegend,
  ChartLegendTitle,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { simpleObject } from "../../helpers/interfaces";
import Loader from "../../Components/Common/Loader";
import {
  formatFinancial,
  PLFormatPercentage,
  TYPE_TO_EXCEL_FORMAT,
} from "../../helpers/helpers";
import { IDetailsRow, IPieItem } from "./interfaces";
import styles from "./pl.module.scss";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import React from "react";

interface props {
  loading: boolean;
  tableData: Array<IDetailsRow>;
  totalRevenue: number;
  pieChartData: Array<IPieItem>;
  toolbarBefore?: any;
  className?: string;
}

interface state {}

class PLSummary extends React.Component<props, state> {
  excelRef: any = React.createRef();

  render() {
    return (
      <div className={`${styles.Summary} ${this.props.className}`}>
        {this.props.loading && <Loader />}
        <Grid
          data={this.props.tableData}
          headerCellRender={() => null}
          className={styles.SummaryKendoGrid}
          style={{ width: "49%" }}
          rowRender={(row, props) => {
            return (
              <tr
                className={`${row.props.className} ${
                  props.dataItem.type === "Balance" ||
                  props.dataItem.type === "FirstRow" ||
                  props.dataItem.type === "WIPAdjustedProfitMargin"
                    ? styles.ColoredRow
                    : ""
                }`}
              >
                {row.props.children}
              </tr>
            );
          }}
          scrollable="none"
        >
          <GridToolbar>
            {this.props.toolbarBefore}
            <ToolbarSpacer />
            <Button
              icon="file-excel"
              title="Export to Excel"
              onClick={this.ExportToExcel}
            ></Button>
          </GridToolbar>
          <Column field="number" width="40" />
          <Column
            field="title"
            cell={(props) => {
              let style: simpleObject = {
                paddingLeft: (props.dataItem.level - 1) * 15,
              };
              return (
                <td className={`${props.className}`} style={props.style}>
                  <div style={style}>{props.dataItem.title}</div>
                </td>
              );
            }}
          />
          <Column
            width={120}
            field="amount"
            cell={(props) => {
              let row = props.dataItem;
              return (
                <td className={`${props.className}`} style={props.style}>
                  <div style={{ textAlign: "right" }}>
                    {row.amount !== null && "$ "}
                    {formatFinancial(row.amount)}
                  </div>
                </td>
              );
            }}
          />
          <Column
            width={120}
            field="total"
            cell={(props) => {
              let row = props.dataItem;
              let style: simpleObject = { textAlign: "right" };
              if (
                (row.type === "Balance" ||
                  row.type === "WipDelta" ||
                  row.type === "WIPAdjustedProfitMargin" ||
                  row.type === "NetMargin") &&
                row.total !== null &&
                row.total !== undefined
              ) {
                style.color = row.total < 0 ? "red" : "green";
              }
              return (
                <td className={`${props.className}`} style={props.style}>
                  <div style={style}>
                    {row.total !== null && "$ "}
                    {formatFinancial(row.total)}
                  </div>
                </td>
              );
            }}
          />
          <Column
            width={80}
            field="percent"
            cell={(props) => {
              let row = props.dataItem;
              return (
                <td className={`${props.className}`} style={props.style}>
                  <div style={{ textAlign: "right" }}>
                    {row.percent !== null && PLFormatPercentage(row.percent)}
                  </div>
                </td>
              );
            }}
          />
          <Column
            width={150}
            field="percentageof"
            cell={(props) => {
              let row = props.dataItem;
              return (
                <td className={`${props.className}`} style={props.style}>
                  <div>{row.percentageof && `of ${row.percentageof}`}</div>
                </td>
              );
            }}
          />
        </Grid>
        <ExcelExport
          data={this.props.tableData}
          ref={this.excelRef}
          fileName={"PL Statement.xlsx"}
          filterable={true}
        >
          <ExcelExportColumn field="number" width={40} title="#" />
          <ExcelExportColumn field="title" title=" " width={150} />
          <ExcelExportColumn
            field="amount"
            title="amount"
            width={150}
            cellOptions={{
              format: TYPE_TO_EXCEL_FORMAT.currency,
              textAlign: "right",
            }}
          />
          <ExcelExportColumn
            field="total"
            title="total"
            cellOptions={{
              format: TYPE_TO_EXCEL_FORMAT.currency,
              textAlign: "right",
            }}
          />
          <ExcelExportColumn
            field="percent"
            title="percent"
            width={150}
            cellOptions={{
              format: TYPE_TO_EXCEL_FORMAT.percent,
              textAlign: "right",
            }}
          />
          <ExcelExportColumn field="percentageof" title="percentageof" />
        </ExcelExport>
        {!this.props.loading && (
          <>
            <Chart
              style={{
                margin: "0 auto",
                width: "50%",
                height: 500,
                paddingTop: 0,
                overflow: "hidden",
              }}
            >
              <ChartLegend
                position="bottom"
                orientation="vertical"
                labels={{
                  content: (props) => {
                    // @ts-ignore
                    let value = !props.dataItem.isProfitMargin
                      ? props.value * -1
                      : props.value;
                    // @ts-ignore
                    return `${props.dataItem.title}: $${formatFinancial(
                      value
                    )}`;
                  },
                }}
              >
                {this.props.totalRevenue && (
                  <ChartLegendTitle
                    text={`Total Revenue: $${formatFinancial(
                      this.props.totalRevenue
                    )}`}
                    align="left"
                    margin={{ bottom: 5, left: 8 }}
                    color="rgb(101, 101, 101)"
                  />
                )}
              </ChartLegend>
              <ChartSeries>
                <ChartSeriesItem
                  type="pie"
                  data={this.props.pieChartData}
                  field="amount"
                  categoryField="title"
                  labels={{
                    visible: true,
                    content: (props) =>
                      `${props.dataItem.title} - ${(
                        props.percentage * 100
                      ).toFixed(0)}%`,
                  }}
                  colorField="color"
                />
              </ChartSeries>
            </Chart>
          </>
        )}
      </div>
    );
  }

  ExportToExcel = () => {
    if (this.excelRef.current?.save) this.excelRef.current.save();
  };
}

export default PLSummary
