import { simpleObject } from "../../../helpers/interfaces";

interface props {
  text: string;
  dataAttr?: any; // string | number | null
  style?: simpleObject;
  className?: string;

  onClick?(e?: React.BaseSyntheticEvent, dataAttr?: any, refName?: any): any;
}

export default function ButtonLink(props: props) {
  let style = props.style || {};
  const onClick = (event: React.BaseSyntheticEvent) => {
    let id = props.dataAttr;
    if (props.onClick) props.onClick(event, id);
  };

  return (
    <span
      className="link"
      onClick={onClick}
      style={{ position: "relative", pointerEvents: "all", ...style }}
    >
      {props.text}
    </span>
  );
}
