import React from "react";
import { Deferred } from "../../../helpers/deffered";
import { TimeCardProps } from "../../../Pages/TKReview/interfaces";
import TCTabs from "../../Tabs/TCTabs";

export class ModalTimeCardInIframeRef {
  static ref: any;
  static initializedRef = new Deferred();

  static setRef(ref: any) {
    this.ref = ref;
    this.initializedRef.resolve();
  }

  static async checkRefInit() {
    if (this.ref === undefined) await this.initializedRef;
  }

  static async showTimeCard(props: TimeCardProps) {
    await this.checkRefInit();
    this.ref.ShowTimeCard(props);
  }

  static async hideTimeCard() {
    await this.checkRefInit();
    this.ref.HideTimeCard();
  }

  static async remountTimeCard(props: TimeCardProps) {
    await this.checkRefInit();
    this.ref.RemountTimeCard(props);
  }
}

interface state {
  props: TimeCardProps | undefined;
  show: boolean;
  remountKey: number;
}

export class ModalTimeCardInIframe extends React.Component<{}, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      props: undefined,
      show: true,
      remountKey: +new Date(),
    };
  }

  componentDidMount() {
    ModalTimeCardInIframeRef.setRef(this);
  }

  render() {
    let props = this.state.props;
    if (!props) return null;
    const styles: React.CSSProperties = !this.state.show
      ? {
          visibility: "hidden",
          zIndex: "-1 !important",
        }
      : {};

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          ...styles,
        }}
      >
        <TCTabs
          key={this.state.remountKey}
          rowData={{ TCId: props.tcId }}
          isActive={!!props.isActive}
          parentId="TKAuditCover"
        />
      </div>
    );
  }

  ShowTimeCard = (props: TimeCardProps) => {
    this.setState({ props: { ...props, isActive: true }, show: true });
  };

  HideTimeCard = () => {
    this.setState((state) => ({
      show: false,
      props: state.props
        ? {
            ...state.props,
            isActive: false,
          }
        : state.props,
    }));
  };

  RemountTimeCard = (props: any) => {
    this.setState({ remountKey: +new Date() });
  };
}
