import React from "react";
import styles from "./tc.module.scss";
import { TStateData } from "../../Pages/TKReview/interfaces";
import { HEIGHT_TIME_ENTRY } from "../../Pages/LiveMap/helpers";

type TGapsIslandsContainerProps = {
  stateData: TStateData;
  isLeftSide: boolean;
};

const { GapsIslandsWrapper, GapsIslandsStateCode, GapsIslands } = styles;

export function GapsIslandsContainer(props: TGapsIslandsContainerProps) {
  const { stateData, isLeftSide } = props;

  return (
    <div
      className={GapsIslandsWrapper}
      style={{ position: "relative", left: isLeftSide ? 0 : 12 }}
    >
      {stateData.map(({ heightMultiplier, stateCode, isClockFlag }, index) => (
        <div
          key={index}
          className={GapsIslandsStateCode}
          style={{
            height: heightMultiplier * HEIGHT_TIME_ENTRY,
          }}
        >
          {!isClockFlag && (
            <div
              className={GapsIslands}
              style={{
                height: heightMultiplier * HEIGHT_TIME_ENTRY - 8,
                border: stateCode ? "3px solid #d3cfcf" : "3px dotted #d3cfcf",
                ...(isLeftSide ? { borderLeft: 0 } : { borderRight: 0 }),
              }}
            >
              {!!stateCode && (
                <div>
                  <p
                    style={{
                      right: isLeftSide ? "calc(12% - 5px)" : "60%",
                      position: "relative",
                      background: "white",
                    }}
                  >
                    {stateCode}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
