import { useCallback, useRef, useState } from "react";
import { refresherAction } from "./interfaces";

export function useBooleanState(initialState = false): {
  setFalse: () => void;
  setTrue: () => void;
  toggle: () => void;
  value: boolean;
} {
  const [value, setValue] = useState<boolean>(initialState);
  const setTrue = useCallback((): void => setValue(true), []);
  const setFalse = useCallback((): void => setValue(false), []);
  const toggle = useCallback((): void => setValue((v) => !v), [setValue]);
  const values = useRef({
    setFalse,
    setTrue,
    toggle,
    value,
  });
  values.current.value = value;

  return values.current;
}

export function useRefresher(): refresherAction {
  const [value, setValue] = useState(0);
  const refresh = useCallback(
    () => setValue((value) => value + 1),
    [setValue]
  ) as refresherAction;
  refresh.value = value;

  return refresh;
}