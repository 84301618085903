import React, { Component } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesDefaultsItemTooltip,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  SeriesClickEvent,
} from "@progress/kendo-react-charts";
import { Button } from "@progress/kendo-react-buttons";
import FXChartTitle from "../../Components/Common/FXChartTitle";
import DashboardGrid from "../../Components/Dashboard/Grid";
import {
  gridChangeDataAction,
  IColumnSetting,
  IGridFilterItem,
} from "../../Components/Dashboard/interfaces";
import { formatFinancialRound } from "../../helpers/helpers";
import {
  GetLabelSettings,
  GetMinMaxAxixValues,
  GetTitleSettings,
} from "./helpers";
import { ILastDay } from "./interfaces";
import styles from "./budgetDashboard.module.scss";
import { simpleObject } from "../../helpers/interfaces";

interface IPointItem {
  value: number | null;
  color: seriesColor;
}

type seriesColor = "red" | "green" | null;

interface props {
  year: number | null;
  months: Array<string>;
  variance: Array<number | null>;
  variancePercentage: Array<number | null>;
  totalProjectedSpend: Array<number | null>;
  totalActualSpend: Array<number | null>;
  numberOfBPs: number;
  minChartValue: number;
  totalActualRevenue: Array<number | null>;
  detailsChartData: Array<ILastDay>;

  onChangeGridData(): void;
}

interface state {
  categoryFilters: Array<string>;
  expandedChart: boolean;
  remountGridKey: number;
}

const numberColumnWidth = 150;
const columns: Array<IColumnSetting> = [
  {
    field: "BPName",
    title: "Build Plan",
    type: "string",
    minWidth: 250,
    format: {
      type: "link",
      refName: "FSMBuildPlans",
      fieldId: "BPId",
    },
    aggregate: "count",
  },
  {
    field: "BPOName",
    title: "BP Owner",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "BPOId",
    },
  },
  {
    field: "ScenarioName",
    title: "Scenario",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "ScenarioId",
    },
  },
  {
    field: "LastDay",
    title: "Month Closed",
    gridWidth: 130,
    type: "date",
    format: {
      type: "date",
      fieldId: "LastDayDate",
      customFormat: true,
      getCustomFormat: (dataItem: simpleObject) =>
        dataItem.LastDayMoment.format("MMMM YYYY"),
    },
  },
  {
    field: "ProjectedRevenue",
    title: "Projected Revenue",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
    },
    aggregate: "sum",
  },
  {
    field: "ActualRevenue",
    title: "Actual Revenue",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
    },
    aggregate: "sum",
  },
  {
    field: "VarianceRevenue",
    title: "Variance Revenue",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
      positiveColor: "green",
      negativeColor: "red",
    },
    aggregate: "sum",
  },
  {
    field: "ProjectedExpenses",
    title: "Projected Spend",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
    },
    aggregate: "sum",
  },
  {
    field: "ActualExpenses",
    title: "Actual Spend",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
    },
    aggregate: "sum",
  },
  {
    field: "VarianceExpenses",
    title: "Variance Spend",
    gridWidth: numberColumnWidth,
    type: "number",
    format: {
      type: "currency",
      positiveColor: "red",
      negativeColor: "green",
    },
    aggregate: "sum",
  },
];

class BudgetSpendChart extends Component<props, state> {
  gridRef: any = React.createRef();

  constructor(props: props) {
    super(props);
    this.state = {
      categoryFilters: [],
      expandedChart: true,
      remountGridKey: +new Date(),
    };
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.detailsChartData !== this.props.detailsChartData) {
      this.setState({ remountGridKey: +new Date() });
    }
    if (
      prevProps.year !== this.props.year &&
      this.state.categoryFilters.length
    ) {
      this.setState({ categoryFilters: [] });
    }
  }

  render() {
    let minValue = this.props.minChartValue;
    let minMaxAxisValues = GetMinMaxAxixValues(minValue);
    let chartSeries = this.GetChartSeries();
    return (
      <>
        <Button
          style={{
            position: "absolute",
            top: 47,
            left: 8,
            zIndex: 1,
            width: 138,
          }}
          icon={
            this.state.expandedChart ? "arrow-chevron-up" : "arrow-chevron-down"
          }
          onClick={this.ToggleChart}
        >
          {this.state.expandedChart ? "Collapse Chart" : "Expand Chart"}
        </Button>
        {this.state.expandedChart && (
          <Chart
            style={{
              height: 450,
              marginBottom: 15,
              flex: "0 0 auto",
            }}
            onSeriesClick={this.OnMarkerClick}
          >
            <FXChartTitle
              text={`Variance Spend for the ${this.props.numberOfBPs} closed BPs`}
            />
            <ChartSeriesDefaults type="line" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={this.props.months}
                axisCrossingValue={[0, 13]}
                line={{ width: 3, color: "#656565" }}
                labels={{ visible: false }}
                majorTicks={{ visible: false }}
              />
              <ChartCategoryAxisItem
                name="month"
                categories={this.props.months}
                axisCrossingValue={[0, 13]}
                labels={{ margin: { top: -18, bottom: -10 } }}
                majorTicks={{ size: 20 }}
              />
              <ChartCategoryAxisItem
                name="Variance ($)"
                categories={this.props.variance}
                title={GetTitleSettings("Variance ($)", 1, true)}
                labels={GetLabelSettings(undefined, (value: any) =>
                  value === null ? "black" : value > 0 ? "red" : "green"
                )}
                majorTicks={{ size: 30 }}
              />
              <ChartCategoryAxisItem
                name="Variance (%)"
                categories={this.props.variancePercentage}
                title={GetTitleSettings("Variance (%)", 1)}
                labels={GetLabelSettings("percent")}
                majorTicks={{ size: 30 }}
              />
              <ChartCategoryAxisItem
                name="Total Proj. Spend ($)"
                categories={this.props.totalProjectedSpend}
                title={GetTitleSettings("Total Proj. \n Spend ($)", 2)}
                labels={GetLabelSettings()}
                majorTicks={{ size: 30 }}
              />
              <ChartCategoryAxisItem
                name="Total Actual Spend ($)"
                categories={this.props.totalActualSpend}
                title={GetTitleSettings("Total Actual \n Spend ($)", 2)}
                labels={GetLabelSettings()}
                majorTicks={{ size: 30 }}
              />
            </ChartCategoryAxis>
            <ChartSeries>
              {chartSeries.map((item, idx) => {
                return (
                  <ChartSeriesItem
                    key={idx}
                    name={item.isAverage ? "Average" : ""}
                    visibleInLegend={false}
                    type="line"
                    style="smooth"
                    data={item.data}
                    color={item.color}
                    field="value"
                    colorField="color"
                    missingValues="interpolate"
                    highlight={{
                      visible: false,
                    }}
                    markers={
                      item.isAverage
                        ? {
                            visible: true,
                            background: item.color,
                            size: 20,
                            type: "triangle",
                            rotation: 90,
                            border: {
                              width: 0,
                            },
                            visual: this.renderAverageMarker,
                          }
                        : {
                            visual: this.renderMarker,
                          }
                    }
                  >
                    <ChartSeriesDefaultsItemTooltip
                      visible={true}
                      render={(context: any) =>
                        context.point.category +
                        ": " +
                        formatFinancialRound(context.point.value)
                      }
                    />
                    {item.isAverage && (
                      <ChartSeriesItemTooltip
                        visible={true}
                        render={(context: any) =>
                          "Average: " +
                          formatFinancialRound(context.point.value)
                        }
                      />
                    )}
                  </ChartSeriesItem>
                );
              })}
            </ChartSeries>
            <ChartValueAxis>
              <ChartValueAxisItem
                name="left"
                axisCrossingValue={[
                  0,
                  minValue,
                  minValue,
                  minValue,
                  minValue,
                  minValue,
                  minValue,
                  minValue,
                  minValue,
                ]}
                labels={{ format: "n2" }}
                {...minMaxAxisValues}
              />
              <ChartValueAxisItem
                labels={{
                  mirror: false,
                  format: "n2",
                }}
                {...minMaxAxisValues}
              />
            </ChartValueAxis>
          </Chart>
        )}
        <DashboardGrid
          isActive={true}
          ref={this.gridRef}
          key={this.state.remountGridKey}
          data={this.props.detailsChartData}
          toolbar={{ hide: true }}
          columnsSettings={columns}
          getRowKey={(dataItem: simpleObject) => dataItem.BPId}
          columnMenu={{ filters: true, columns: false }}
          containerClassName={styles.ChartDetailsGrid}
          onChangeGridData={this.OnChangeGridData}
          excelFileName="Budget Dashboard Summary.xlsx"
        />
      </>
    );
  }

  renderMarker = (e: any) => {
    var el = e.createVisual();
    let month = e.category;
    let borderColor = el.options.stroke.color;
    let selectedMonth =
      this.state.categoryFilters.findIndex((m) => m === month) > -1;
    if (selectedMonth) el.options.fill.color = borderColor;
    return el;
  };

  renderAverageMarker = (e: any) => {
    var el = e.createVisual();
    let shiftSize = 35;
    //@ts-ignore
    el.options.transform._matrix.e = el.options.transform._matrix.e + shiftSize;
    return el;
  };

  ToggleChart = () => {
    this.setState((state) => ({ expandedChart: !state.expandedChart }));
  };

  OnChangeGridData = (data: Array<any>, action: gridChangeDataAction) => {
    let categoryFilters = [];
    let values = this.gridRef.current?.columnValues.LastDayDate;
    if (
      action === "filterChanged" ||
      action === "forceRestoreSettings" ||
      action === "dataLoaded"
    )
      this.props.onChangeGridData();
    if (!values || !values.length) return;
    for (let item of values) {
      let selected = item.Selected;
      if (selected) categoryFilters.push(item.Name);
    }
    this.setState({ categoryFilters });
  };

  GetChartSeries = () => {
    let data = this.props.variance;
    let allBPsSeries: Array<any> = [];
    let total = 0;
    if (data.length) {
      let chartData: Array<IPointItem> = [];
      let lastPoint: IPointItem | null = null;
      let prevLastPoint: IPointItem | null = null;
      let lastValueIndex: number = 0;
      let prevValueIndex: number = 0;
      let seriesPointsWithValueLength = 0;

      data.forEach((value: number | null, i: number) => {
        let isLastPoint = i === 11;
        let color: seriesColor = (lastPoint && lastPoint.color) || null;
        if (value !== null) color = value > 0 ? "red" : "green";
        let point: IPointItem = { value, color };

        if (
          ((lastPoint && lastPoint.color != color) || isLastPoint) &&
          chartData.length
        ) {
          allBPsSeries.push({
            color: lastPoint?.color,
            data: chartData,
          });
          seriesPointsWithValueLength = 0;
        }
        if (lastPoint && lastPoint.color != color) {
          chartData = [];
          for (let j = 0; j < i; j++) {
            chartData.push(
              lastValueIndex === j
                ? lastPoint
                : {
                    value: null,
                    color,
                  }
            );
          }
        }

        chartData.push(point);
        if (value !== null) {
          prevLastPoint = lastPoint;
          prevValueIndex = lastValueIndex;

          lastPoint = point;
          lastValueIndex = i;
          seriesPointsWithValueLength += 1;
          total += value;
        }

        if (isLastPoint) {
          if (seriesPointsWithValueLength === 1 && prevLastPoint) {
            chartData[prevValueIndex] = prevLastPoint;
          }
          allBPsSeries.push({ color: color, data: chartData });
        }
      });

      let average = total / 12;
      let averageChartData: Array<any> = [];
      for (let j = 0; j < 11; j++) {
        averageChartData.push(null);
      }
      averageChartData.push(average);
      allBPsSeries.push({
        color: average > 0 ? "red" : "green",
        data: averageChartData,
        isAverage: true,
      });
    }

    if (!allBPsSeries.length) {
      allBPsSeries = [
        {
          data: [],
        },
      ];
    }
    return allBPsSeries;
  };

  ExportToExcel = () => {
    this.gridRef.current.ExportToExcel();
  };

  OnMarkerClick = (e: SeriesClickEvent) => {
    if (e.series.name === "Average") return;

    let month = e.category;
    let categoryFilters = this.state.categoryFilters;
    let indexCategory = categoryFilters.findIndex((m) => m === month);
    if (indexCategory === -1) categoryFilters.push(month);
    else categoryFilters.splice(indexCategory, 1);
    let monthGridColumnValues = this.gridRef.current.GetColumnValues(
      "LastDayFormat",
      "LastDayDate"
    );
    let filters: Array<IGridFilterItem> = [];
    for (let item of monthGridColumnValues) {
      item.Selected = categoryFilters.findIndex((m) => m === item.Name) > -1;
      if (item.Selected)
        filters.push({
          value: item.Id,
          field: item.FieldId,
          operator: "eq",
        });
    }
    this.gridRef.current.SubmitFilterByColumnValues(
      filters,
      monthGridColumnValues,
      "LastDayDate",
      "LastDayFormat"
    );
  };
}

export default BudgetSpendChart;
