import { BaseSyntheticEvent, Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { simpleObject } from "../../helpers/interfaces";
import OpenCardLink from "../../Components/Common/Buttons/OpenCardLink";
import { IBuildPlansGridData, IRowItem, sectionName } from "./interfaces";
import styles from "./budgetDashboard.module.scss";
import { OpenJob } from "../../helpers/runscripts";
import commonStyles from "../../assets/styles/common.module.scss";
import {
  getValues,
  gridColumns,
  IsGroupWithTotal,
  IsRevenueGroup,
  OpenBudget,
  sectionKeys,
} from "./helpers";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import ButonLink from "../../Components/Common/Buttons/ButtonLink";
import UserInfo from "../../stores/User";
import CardManagement from "../../Components/Cards/CardManagement";

interface props {
  data: Array<IRowItem>;
  groupedData: IBuildPlansGridData;

  refresh(): void;
}

interface state {
  skip: number;
  pageSize: number;
  settingsInited: boolean;
}

const GridRowHeight = 25;

class BudgetDetailsGrid extends Component<props, state> {
  gridRef: any;
  UseEasyBudget: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      skip: 0,
      pageSize: 20,
      settingsInited: false,
    };
  }

  async componentDidMount() {
    let settings = await UserInfo.getInfo();
    this.UseEasyBudget = !!settings?.UseEasyBudget;
    this.setState({ settingsInited: true });
  }

  componentWillUnmount() {
    window.document.removeEventListener("resize", this.RefreshPageSize);
  }

  initGridRef = (ref: any) => {
    if (!this.gridRef) {
      this.gridRef = ref;
      this.RefreshPageSize();
      window.addEventListener("resize", this.RefreshPageSize);
    }
  };

  render() {
    if (!this.state.settingsInited) return null;

    return (
      <Grid
        className={`${styles.Grid}`}
        style={{ height: "100%" }}
        data={this.props.data.slice(
          this.state.skip,
          this.state.skip + this.state.pageSize + 2
        )}
        scrollable={"virtual"}
        rowHeight={GridRowHeight}
        pageSize={this.state.pageSize}
        skip={this.state.skip}
        total={this.props.data.length}
        onPageChange={this.OnChangePage}
        ref={this.initGridRef}
        rowRender={this.renderRow}
        cellRender={this.renderCell}
      >
        {gridColumns.map((column) => {
          return (
            <Column
              key={column.field}
              field={column.field}
              title={column.title}
              width={column.gridWidth}
              cell={
                column.field === "UsedofPlanned" ||
                column.field === "UsedofTotalPlanned"
                  ? this.renderCellWithChart
                  : undefined
              }
            />
          );
        })}
      </Grid>
    );
  }

  // todo common render functions for print and for grid
  renderRow = (el: any, props: GridRowProps) => {
    // let ispdfGrid = !!this.props.pdfGrid
    let data: IRowItem = props.dataItem;
    if (data.Type !== "numbers") {
      el.props.style.background = /* ispdfGrid ? '#fff' :  */ "transparent";
      let isBPRow = data.Type === "buildPlan";
      let BPId = data.BPId;
      let content = isBPRow ? (
        <div className={`${styles.GroupRow}`}>
          {!this.UseEasyBudget ? (
            <OpenCardLink
              text="Open Budget"
              dataAttr={BPId}
              onClick={this.OpenBudget}
              showOnlyAsLink={true}
            />
          ) : (
            <ButonLink
              text="Open Budget"
              dataAttr={BPId}
              onClick={this.OpenEasyBudget}
            />
          )}
          {this.renderBPLink(data)}
          {data.ApprovalJobId && (
            <OpenCardLink
              text="Pending Approval Job"
              dataAttr={data.ApprovalJobId}
              onClick={this.OpenApprovalJob}
              style={{ marginLeft: 8 }}
              showOnlyAsLink={true}
            />
          )}
          <span className={styles.GroupRowCell}>
            <span className={styles.GroupRowCellBold}>BP Owner: </span>
            {data.BPOName}
          </span>
          {data.EstimatedCompletion !== null && (
            <span className={styles.GroupRowCell}>
              <span className={styles.GroupRowCellBold}>
                Estimated Completion:{" "}
              </span>
              {data.EstimatedCompletion.toFixed(2)}%
            </span>
          )}
          {data.CXStatusName && (
            <span className={styles.GroupRowCell}>
              <span className={styles.GroupRowCellBold}>CX Status: </span>
              {data.CXStatusName}
            </span>
          )}
        </div>
      ) : (
        <div className={`${styles.GroupRow}`}>{data.Name}</div>
      );
      return (
        <tr {...el.props} key={data.rowType + BPId}>
          <td colSpan={9} className={isBPRow ? styles.BPTd : styles.SectionTd}>
            {" "}
            {content}{" "}
          </td>
        </tr>
      );
    } else {
      return el;
    }
  };

  renderBPLink = (rowData: any) => {
    return (
      <OpenCardLink
        text={rowData.Name}
        dataAttr={rowData.BPId}
        refName="FSMBuildPlans"
        className={styles.BPLink}
      />
    );
  };

  renderCell = (el: any, props: simpleObject) => {
    let field = props.field;
    let value = props.dataItem[field];
    let valueFormatted = props.dataItem[`${field}Formatted`];
    let style: simpleObject = {};
    if (props.dataItem.IsBoldRow) style.fontWeight = "700";
    let groupName: sectionName =
      props.rowType === "groupHeader"
        ? props.dataItem.value
        : props.dataItem.Section;
    if (props.rowType === "groupHeader") {
      if (el) {
        let colspan = IsRevenueGroup(groupName) ? 2 : el.props.colSpan;
        return <td colSpan={colspan}>{el.props.children}</td>;
      } else if (
        field === "Title" ||
        (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName))
      ) {
        return null;
      } else {
        let row;
        if (IsRevenueGroup(groupName)) {
          row = props.dataItem.items[0];
          value = row[field];
          valueFormatted = row[`${field}Formatted`];
        } else if (IsGroupWithTotal(groupName)) {
          row = props.dataItem.items[props.dataItem.items.length - 1];
          if (row && row.IsTotalRow) {
            value = row[field];
            valueFormatted = row[`${field}Formatted`];
          }
        }
      }
    } else {
      let section: sectionName = props.dataItem.Section;
      if (field === "Title" && !IsRevenueGroup(section)) style.paddingLeft = 24;
    }

    let defProps = el ? el.props : {};

    if (field === "Remaining") {
      if (IsRevenueGroup(groupName)) style.color = value > 0 ? "red" : "green";
      else style.color = value > 0 ? "green" : "red";
    }
    return (
      <td
        {...defProps}
        className={`${field !== "Title" && commonStyles.TextRight} ${
          (field === "Revised" || field === "Actual") && styles.MarkedColumn
        }`}
        style={style}
      >
        {field === "Title" ? value : valueFormatted}
      </td>
    );
  };

  renderCellWithChart = (props: simpleObject) => {
    let columnField = props.field;
    let groupName: sectionName =
      props.rowType === "groupHeader"
        ? props.dataItem.value
        : props.dataItem.Section;
    if (props.rowType === "groupHeader") {
      if (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName)) {
        return null;
      }
    }
    if (
      props.rowType !== "groupHeader" &&
      !IsGroupWithTotal(groupName) &&
      !IsRevenueGroup(groupName)
    ) {
      return <td></td>;
    }

    let percentage = props.dataItem[columnField];
    let isRed = !!props.dataItem[`${columnField}IsRed`];

    if (props.rowType === "groupHeader") {
      let values: { total: number | null; value: number | null } = {
        total: null,
        value: null,
      };
      let rowData = null;

      if (IsRevenueGroup(groupName)) {
        rowData = props.dataItem.items[0];
      } else if (IsGroupWithTotal(groupName)) {
        let totalRow = props.dataItem.items[props.dataItem.items.length - 1];
        if (totalRow && totalRow.IsTotalRow) {
          rowData = totalRow;
        }
      }
      if (rowData) {
        let groupData = this.props.groupedData[rowData.BPId];
        let sectionName: sectionName = rowData.Section;
        let sectionKey = sectionKeys[sectionName];
        let sectionData = groupData[sectionKey];
        values = getValues(rowData, columnField, sectionData) || values;
      }

      percentage =
        values.value !== null && values.total
          ? (values.value / values.total) * 100
          : null;
      if (percentage !== null) {
        percentage = +percentage.toFixed(0);
        isRed = IsRevenueGroup(groupName) ? percentage < 100 : percentage > 100;
      }
    }

    if (percentage === null) return <td></td>;
    return (
      <td className={commonStyles.TextRight} style={{ padding: 0 }}>
        <ProgressBar
          min={0}
          max={100}
          value={percentage}
          label={(props) => (
            <span>
              {percentage !== null &&
                (isNaN(percentage) ? "n/a" : percentage + "%")}
            </span>
          )} // ??
          className={`${styles.ProgressBar} ${
            isRed ? styles.ProgressBarOver : ""
          }`}
        />
      </td>
    );
  };

  GetGridPageSize = () => {
    let gridRef = this.gridRef;
    if (gridRef && gridRef.element) {
      let container = gridRef.element;
      let contentDOMEl = container.querySelector(".k-grid-content");
      let htmlRows = contentDOMEl.querySelectorAll("tr");
      if (!htmlRows.length) return 0;
      return Math.ceil(contentDOMEl.clientHeight / GridRowHeight);
    }
    return 0;
  };

  RefreshPageSize = () => {
    this.setState({ pageSize: this.GetGridPageSize() });
  };

  OnChangePage = (event: any) => {
    let skip = event.page.skip;
    this.setState({ skip: isNaN(skip) ? 0 : skip });
  };

  OpenBudget = (
    e: BaseSyntheticEvent<object, any, any> | undefined,
    BuildPlanId: number
  ) => {
    OpenBudget(BuildPlanId, this.props.refresh);
  };

  OpenEasyBudget = (
    e: BaseSyntheticEvent<object, any, any> | undefined,
    BPId: number
  ) => {
    CardManagement.EasyBudgetCard(BPId, this.props.refresh);
  };

  OpenApprovalJob = (e: any, jobId: number) => {
    OpenJob(jobId);
  };
}

export default BudgetDetailsGrid
