import { ISortGridItem, simpleObject } from "../../helpers/interfaces";
import {
  dimension,
  IColumn,
  IGroupedFDAmounts,
  IGroupedFDDataItem,
  IStatement,
  IStatementColumn,
  IStatementRow,
} from "./interfaces";

export const STATEMENT_COLUMNS: Array<IStatementColumn> = [
  {
    id: "Region",
    title: "Region",
    field: "RegionName",
    fieldId: "RegionID",
    locked: true,
    visible: true,
    isStatementColumn: true,
    width: 100,
  },
  {
    id: "ProfitCenter",
    title: "Profit Center",
    field: "ProfitCenterName",
    fieldId: "ProfitCenterID",
    locked: true,
    visible: true,
    isStatementColumn: true,
  },
  {
    id: "Project",
    title: "Project",
    field: "ProjectName",
    fieldId: "ProjectID",
    locked: true,
    visible: true,
    isStatementColumn: true,
    width: 150,
  },
  {
    id: "Customer",
    title: "Customer",
    field: "CustomerName",
    fieldId: "CustomerID",
    locked: true,
    visible: true,
    isStatementColumn: true,
  },
  {
    id: "Bp",
    title: "BP #",
    field: "BPName",
    fieldId: "BPID",
    locked: true,
    visible: true,
    isStatementColumn: true,
    width: 200,
    linkFormat: {
      refName: "FSMBuildPlans",
      fieldId: "BPID",
    },
  },
  {
    id: "Site",
    title: "Site",
    field: "SiteName",
    fieldId: "SiteID",
    locked: true,
    visible: true,
    isStatementColumn: true,
    linkFormat: {
      refName: "FSMSites",
      fieldId: "SiteID",
    },
  },
  {
    id: "Class",
    title: "Class",
    field: "ClassName",
    fieldId: "ClassID",
    locked: true,
    visible: true,
    isStatementColumn: true,
  },
];

export const PrepareStatement = (statement: IStatement) => {
  let rows = statement.rows.filter(
    (row: IStatementRow) => row.type !== "Delimiter"
  );
  statement.hasServiceRevenueRow =
    statement.rows.findIndex((row) => row.title === "Service Revenue") > -1;
  let columns = [];
  let sort: Array<ISortGridItem> = [];
  statement.defaultDimensions.forEach((dimension) => {
    let column = STATEMENT_COLUMNS.find((column) => column.id === dimension);
    if (column) sort.push({ field: column.field, dir: "asc" });
  });
  rows.forEach((row: IStatementRow) => {
    let subColumns = [];
    if (row.subRows && row.subRows.length) {
      row.subRows.forEach((subRow: IStatementRow) => {
        subColumns.push({
          ...subRow,
          field: subRow.title,
        });
      });
      subColumns.push({
        type: "Total",
        title: "Total",
        field: `${row.title}`,
      });
    }
    columns.push({
      ...row,
      field: row.title,
      subColumns: subColumns.length ? subColumns : undefined,
    });

    if (
      row.type === "Balance" &&
      row.percentageOf !== undefined &&
      row.percentageOf !== null
    ) {
      columns.push({
        type: "Percent",
        title: `${row.title} %`,
        field: `${row.title}Percent`,
        valueField: row.title,
        percentageOf: row.percentageOf,
      });
    }
  });

  columns.push(
    {
      field: "WipDelta",
      title: "WIP",
      type: "WipDelta",
    },
    {
      field: "WIPAdjustedProfitMargin",
      title: "WIP Adjusted Profit Margin",
      type: "WIPAdjustedProfitMargin",
    },
    {
      type: "Percent",
      title: `WIP Adjusted Profit Margin %`,
      field: `WIPAdjustedProfitMarginPercent`,
      valueField: "WIPAdjustedProfitMargin",
    }
  );
  statement.columns = columns;
  statement.defaultSort = sort;
};

export const groupFDAmounts = (amounts: Array<any>) => {
  let groupedFDAmounts: IGroupedFDAmounts = {};
  for (let amountItem of amounts) {
    if (!groupedFDAmounts[amountItem.BPID])
      groupedFDAmounts[amountItem.BPID] = {};
    groupedFDAmounts[amountItem.BPID][amountItem.FAID] = amountItem.Amount;
  }
  return groupedFDAmounts;
};

export const GetStatementColumns = (dimensions: Array<dimension>) => {
  let statementColumns: Array<IStatementColumn> = [];
  STATEMENT_COLUMNS.forEach((column) => {
    statementColumns.push({
      ...column,
      visible: !!~dimensions.indexOf(column.id),
    });
  });
  return statementColumns;
};

export const GetGroupedData = (
  groupedFDData: Array<IGroupedFDDataItem>,
  dimensions: Array<dimension>
) => {
  let statementColumns = GetStatementColumns(dimensions);
  let groupBy = statementColumns.filter((stCol) => stCol.visible);
  let data: Array<simpleObject> = groupedFDData;
  groupBy.push({
    id: "Company",
    title: "Company",
    field: "CompanyName",
    fieldId: "CompanyID",
    visible: false,
    locked: false,
    isStatementColumn: false,
  });
  let groupedData: { [key: string]: simpleObject } = {};
  const getKey = (item: simpleObject, groupBy: simpleObject) => {
    return groupBy.reduce((sum: string, group: simpleObject) => {
      return sum + "-" + item[group.fieldId];
    }, "");
  };
  const getGroupData = (item: simpleObject, groupBy: simpleObject) => {
    let data: simpleObject = {};
    for (let i = 0; i < groupBy.length - 1; i++) {
      let group = groupBy[i];
      data[group.field] = item[group.field];
      if (group.fieldId) data[group.fieldId] = item[group.fieldId];
    }
    return data;
  };
  data.forEach((item) => {
    let key = getKey(item, groupBy);
    if (!groupedData[key]) {
      groupedData[key] = {
        data: [],
        ...getGroupData(item, groupBy),
      };
    }
    groupedData[key].data.push(item);
  });
  return groupedData;
};

export const CalculateGroupedData = (
  groupedData: simpleObject,
  columns: Array<IColumn>,
  amountsList: IGroupedFDAmounts,
  hasServiceRevenueRow: boolean
) => {
  const GetBPID = (item: simpleObject) => item.BPID;

  let gridData: Array<simpleObject> = [];
  for (let groupKey in groupedData) {
    const item = groupedData[groupKey];
    const BPIds = item.data.map(GetBPID);
    item.Key = groupKey;
    let columnTotals: simpleObject = {
      totalRevenue: 0,
      total: 0,
      balance: 0,
      WipDelta: 0,
    };
    for (let column of columns) {
      SetRowColumnData(
        column,
        BPIds,
        item,
        columnTotals,
        columns,
        amountsList,
        hasServiceRevenueRow
      );
    }
    gridData.push(item);
  }
  return gridData;
};

export const SetRowColumnData = (
  column: IColumn,
  BPIds: Array<number>,
  rowData: simpleObject,
  columnTotals: simpleObject,
  columns: Array<IColumn>,
  amountsList: IGroupedFDAmounts,
  hasServiceRevenueRow: boolean
) => {
  let FAIDs = column.accountIds;
  if (column.type === "FirstRow") {
    columnTotals.totalRevenue = CalculateColumnAmount(
      BPIds,
      FAIDs,
      amountsList
    );
    rowData[column.field] = columnTotals.totalRevenue;
  } else if (column.type === "TopAccount") {
    let columnSum = -1 * CalculateColumnAmount(BPIds, FAIDs, amountsList);
    rowData[column.field] = columnSum;
    columnTotals.total += columnSum;
    if (column.subColumns?.length) {
      let subTotal2: simpleObject = { childTotal: 0 };
      for (let subColumn of column.subColumns) {
        SetRowColumnData(
          subColumn,
          BPIds,
          rowData,
          subTotal2,
          columns,
          amountsList,
          hasServiceRevenueRow
        );
      }
    }
  } else if (column.type === "Balance") {
    columnTotals.balance = columnTotals.totalRevenue - columnTotals.total;
    rowData[column.field] = columnTotals.balance;
  } else if (column.type === "Percent") {
    if (column.field === "WIPAdjustedProfitMarginPercent") {
      let revenue = hasServiceRevenueRow
        ? rowData["Service Revenue"]
        : rowData["Total Revenue"];
      let percentWIPAdjustedProfitMargin =
        revenue !== null
          ? (rowData.WIPAdjustedProfitMargin / (revenue + rowData.WipDelta)) *
            100
          : null;
      if (
        rowData.WIPAdjustedProfitMargin < 0 &&
        percentWIPAdjustedProfitMargin !== null
      )
        percentWIPAdjustedProfitMargin *= -1;
      rowData[column.field] = percentWIPAdjustedProfitMargin;
    } else {
      let columnOf = columns.find(
        (col) =>
          col.number !== undefined &&
          col.number !== null &&
          +col.number === column.percentageOf
      );
      if (column.valueField && columnOf) {
        let columnOfVal = rowData[columnOf.field];
        let val = rowData[column.valueField];
        let percent = (Math.abs(val) / Math.abs(columnOfVal)) * 100;
        if (val < 0) percent *= -1;
        rowData[column.field] =
          percent === Infinity || percent === -Infinity ? 0 : percent;
      }
    }
  } else if (column.type === "WipDelta") {
    columnTotals.WipDelta = rowData.data.reduce(
      (sum: number, current: simpleObject) => sum + (current.WipDelta || 0),
      0
    );
    rowData[column.field] = columnTotals.WipDelta;
  } else if (column.type === "WIPAdjustedProfitMargin") {
    rowData[column.field] = columnTotals.WipDelta + columnTotals.balance;
  } else if (column.type === "Total") {
    // childcolumn
    //rowData[column.field] = columnTotals.childTotal
  } else if (column.type === "ChildAccount") {
    // childcolumn
    let subColumnSum =
      -1 * CalculateColumnAmount(BPIds, column.accountIds, amountsList);
    rowData[column.field] = subColumnSum;
    columnTotals.childTotal += subColumnSum;
  }
  return columnTotals;
};

export const CalculateColumnAmount = (
    BPIds: Array<number>,
    FAIDs: Array<number>,
    amountsList: IGroupedFDAmounts
) => {
    let sum = 0;
    for (let BPID of BPIds) {
        for (let FAID of FAIDs) {
            sum += (amountsList[BPID] && amountsList[BPID][FAID]) || 0;
        }
    }
    return sum;
};
