import React, { Component } from "react";
import styles from "./switch.module.scss";

type size = "s" | "m" | "l";

interface props {
  checked: boolean;
  id: string;
  size?: size;
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  rtl?: boolean;

  onChange(checked: boolean): any;
}

interface state {
  checked: boolean;
}

class Switch extends Component<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: this.props.checked,
    };
  }

  render() {
    return (
      <div
        className={`${styles.Box}  ${this.props.rtl ? styles.rtlSwitch : ""}`}
      >
        <div
          className={`${styles.switch} ${this.props.className}`}
          style={this.props.style}
        >
          <input
            className={styles.switchCheckbox}
            type="checkbox"
            id={this.props.id}
            checked={this.state.checked}
            onChange={this.onChange}
          />
          <label
            className={styles.switchSlider}
            htmlFor={this.props.id}
          ></label>
        </div>
        {this.props.label && (
          <label className={styles.switchLabel} htmlFor={this.props.id}>
            {this.props.label}
          </label>
        )}
      </div>
    );
  }

  onChange = (e: any) => {
    let checked = e.target.checked;
    this.setState({ checked });
    this.props.onChange(checked);
  };
}

export default Switch;
