import React, { Component } from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting, IFilterSetting } from "../Dashboard/interfaces";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import TransactionJournal from "./TransactionJournal";
import { simpleObject } from "../../helpers/interfaces";
import { tabId } from "../Tabs/interfaces";

interface state {}

interface props {
  isActive: boolean;

  switchTab(tabId: tabId): void;

  onChangeGridData?(data: Array<simpleObject>): void;
}

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterMaterial",
    placeholder: "Filter by Material",
    type: "combobox",
    width: 300,
    serverFilter: {
      param: "materialId",
    },
    virtual: {
      refName: "FSMMaterials",
    },
    toolbarRow: 1,
  },
  {
    id: "filterCategory",
    placeholder: "Filter by Category",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "categoryId",
      getData: async () => {
        let result = await ReferenceRecordsDataSource("MaterialCategories");
        return result[0];
      },
    },
    toolbarRow: 1,
  },
  {
    id: "filterDate",
    placeholder: "Show data on",
    type: "date",
    width: 110,
    serverFilter: {
      param: "date",
    },
    toolbarRow: 1,
  },
];

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    aggregate: "count",
    columnMenu: false,
  },
  {
    field: "LocationName",
    title: "Location",
    type: "string",
    minWidth: 170,
    format: {
      type: "link",
      fieldId: "LocationId",
    },
  },
  {
    field: "PartNumber",
    title: "Part Number",
    type: "string",
    gridWidth: 120,
  },
  {
    field: "MAName",
    title: "Material",
    type: "string",
    minWidth: 200,
    format: {
      type: "link",
      fieldId: "MAId",
      refName: "FSMMaterials",
    },
  },
  {
    field: "CategoryName",
    title: "Category",
    type: "string",
    minWidth: 150,
    format: {
      type: "string",
      fieldId: "CategoryId",
    },
  },
  {
    field: "QuantityActual",
    title: "QTY Actual",
    type: "number",
    gridWidth: 120,
    format: {
      type: "number",
    },
  },
  {
    field: "AverageDailyBalance",
    title: "Average Daily Balance last 12 Months",
    type: "number",
    gridWidth: 150,
    format: {
      type: "number",
    },
  },
  {
    field: "AverageQTYKitted",
    title: "Average QTY Kitted",
    type: "number",
    gridWidth: 150,
    format: {
      type: "number",
    },
  },
  {
    field: "BomCount",
    title: " # of Kitted BOMs",
    type: "number",
    gridWidth: 150,
    format: {
      type: "integer",
    },
  },
  {
    field: "AverageQTYPurchased",
    title: "Average QTY Purchased",
    type: "number",
    gridWidth: 150,
    format: {
      type: "number",
    },
  },
  {
    field: "PurchasCount",
    title: "# of Purchases",
    type: "number",
    gridWidth: 150,
    format: {
      type: "integer",
    },
  },
  {
    field: "",
    title: "QTY Used",
    gridWidth: 300,
    type: "parent",
    columns: [
      {
        field: "QuantityUsed30",
        title: "Last 30 Days",
        type: "number",
        gridWidth: 100,
        format: {
          type: "number",
        },
      },
      {
        field: "QuantityUsed180",
        title: "Last 6 Months",
        type: "number",
        gridWidth: 100,
        format: {
          type: "number",
        },
      },
      {
        field: "QuantityUsed1Year",
        title: "Last 1 Year",
        type: "number",
        gridWidth: 100,
        format: {
          type: "number",
        },
      },
    ],
  },
];

class Utilization extends Component<props, state> {
  gridRef: any = React.createRef();

  render() {
    return (
      <DashboardGrid
        ref={this.gridRef}
        isActive={this.props.isActive}
        getSqlParams={{ spName: "DB_Utilization" }}
        saveSettings={{ prefix: "Utilization", saveFilters: true }}
        excelFileName="Utilization.xlsx"
        initRefresh={this.InitRefresh}
        columnsSettings={COLUMNS}
        filters={FILTERS}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) =>
          `${dataItem.LocationId}-${dataItem.MAId}`
        }
        selectSettings={{
          Component: TransactionJournal,
          pageId: "InventoryDashboard",
        }}
        defaultSort={[
          {
            dir: "desc",
            field: "QuantityActual",
          },
        ]}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default Utilization;
